<template>
  <div class="member-evalutation-test">
    <div class="total">
      <el-tabs @tab-click="onClickTotalTab" value="today">
        <el-tab-pane label="今日" name="today"></el-tab-pane>
        <el-tab-pane label="7天" name="week"></el-tab-pane>
        <el-tab-pane label="累计" name="all"></el-tab-pane>
      </el-tabs>
      <div class="part1">
        <div class="card">
          <span class="label">测评总数：</span>
          <span class="number">{{count.total}}</span>
        </div>
        <div class="card">
          <span class="label">测评正确率：</span>
          <span class="number">{{count.correct_rate}}%</span>
        </div>
      </div>
      <div class="part2">
        <div ref="pie" class="pie"></div>
        <div class="pie-center">
          <p>{{count.kaodian + count.taojuan + count.fangzhen}}</p>
          <p>做题数</p>
        </div>
        <div class="count">
          <div class="count-item">
            <p class="count-item-number color-kaodian">{{count.kaodian}}</p>
            <p class="count-item-name">{{label.kaodian}}</p>
          </div>
          <div class="count-item">
            <p class="count-item-number color-taojuan">{{count.taojuan}}</p>
            <p class="count-item-name">{{label.taojuan}}</p>
          </div>
          <div class="count-item">
            <p class="count-item-number color-fangzhen">{{count.fangzhen}}</p>
            <p class="count-item-name">{{label.fangzhen}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="detail">
      <el-tabs @tab-click="onClickDetailTab" :value="searchCondition.type">
        <el-tab-pane :label="label.kaodian" name="point">
          <el-form inline>
            <el-form-item>
              <el-date-picker
                v-model="datetimeRange"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              ></el-date-picker>
            </el-form-item>
            <el-form-item style="float:right;">
              <el-input
                v-model="searchCondition.name"
                placeholder="请输入考点名称关键字"
                @keydown.native.prevent.enter="onSearch"
                clearable
              >
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="label.taojuan" name="practice">
          <el-form inline>
            <el-form-item>
              <el-select v-model="operateType" placeholder="套卷操作状态" clearable>
                <el-option
                  v-for="(item, index) in operateTypes"
                  :key="index"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="datetimeRange"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                style="width:390px;"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchCondition.name"
                placeholder="请输入套卷名称关键字"
                @keydown.native.prevent.enter="onSearch"
                clearable
              >
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="label.fangzhen" name="simulation">
          <el-form inline>
            <el-form-item>
              <el-select v-model="examType" placeholder="考试类型" clearable>
                <el-option
                  v-for="(item, index) in examTypes"
                  :key="index"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="datetimeRange"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                style="width:390px;"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchCondition.name"
                placeholder="请输入考试名称关键字"
                @keydown.native.prevent.enter="onSearch"
                clearable
              >
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <Grid
        ref="grid"
        url="/api/app/examStatistics/detail"
        method="get"
        :search="searchCondition"
        :columns="tableColumns"
        :formatter="onFormatter"
      >
        <template slot-scope="{row}" slot="done">{{row.right_count}}/{{row.ques_count}}</template>
        <template slot-scope="{row}" slot="count">{{row.have_done_count}}/{{row.repeat_times}}</template>
        <template slot-scope="{row}" slot="correct_rate">{{Number(row.correct_rate).toFixed(2)}}%</template>
        <template
          slot-scope="{row}"
          slot="time"
        >{{row.created_at | tsFormat('yyyy/MM/dd hh:mm:ss')}}</template>
        <template slot-scope="{row}" slot="action">
          <div v-if="searchCondition.type == 'point'">
            <el-button type="text" @click="onWatchReport(row)">查看解析</el-button>
            <span class="action-split">|</span>
            <el-button type="text" @click="onRePractice(row)">重新练习</el-button>
          </div>
          <div v-else-if="searchCondition.type == 'practice'">
            <el-button type="text" @click="onWatchReport(row)">查看成绩</el-button>
            <span class="action-split">|</span>
            <el-button v-if="row.is_release == 1" type="text" @click="onRePractice(row)">重新练习</el-button>
            <span v-else>已结束</span>
          </div>
          <div v-else>
            <el-button type="text" @click="onWatchReport(row)">查看成绩</el-button>
            <span v-if="row.examing" class="action-split">|</span>
            <el-button v-if="row.examing" type="text" @click="onRePractice(row)">重新练习</el-button>
          </div>
        </template>
        <div class="empty" slot="empty">
          <img src="~@/assets/images/empty@3x.png" alt />
          <div>
            <span>暂无测评数据，</span>
            <el-button type="text" @click="$router.push({name: 'ExamIndex'})">快去测评吧</el-button>
          </div>
        </div>
      </Grid>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import Grid from "@/components/Grid";
import { ExamType } from "@/utils/enums";
export default {
  name: "member-evalutation-test",
  components: {
    Grid
  },
  data() {
    return {
      myEchart: null,
      datetimeRange: null,
      label: {
        kaodian: "考点专练",
        taojuan: "套卷练习",
        fangzhen: "仿真模考"
      },
      count: {
        kaodian: 0,
        taojuan: 0,
        fangzhen: 0,
        total: 0,
        correct_rate: 0
      },
      tableColumns: [],
      searchCondition: {
        search_type: "today",
        type: "point",
        is_release: "",
        name: "",
        start_time: 0,
        end_time: 0,
        simulation_type: "",
        keywords: "",
        init() {
          this.is_release = "";
          this.name = "";
          this.start_time = 0;
          this.end_time = 0;
          this.simulation_type = "";
        }
      },
      examTypes: ExamType.getOptions(),
      examType: "",
      operateTypes: [
        { key: "1", value: "未结束" },
        { key: "2", value: "已结束" }
      ],
      operateType: ""
    };
  },
  created() {
    this.requestBaseinfo();
    this.initTableColumns(this.searchCondition.type);
  },
  mounted() {
    this.initEchart();
  },
  watch: {
    datetimeRange(val) {
      if (val) {
        this.searchCondition.start_time = parseInt(val[0].getTime() / 1000);
        this.searchCondition.end_time = parseInt(val[1].getTime() / 1000);
      } else {
        this.searchCondition.start_time = 0;
        this.searchCondition.end_time = 0;
      }
    },
    operateType(val) {
      this.searchCondition.is_release = val;
    }
  },
  methods: {
    onClickTotalTab(tab) {
      this.requestBaseinfo(tab.name);
      this.searchCondition.search_type = tab.name;
      this.onSearch();
    },
    onClickDetailTab(tab) {
      this.initTableColumns(tab.name);
      this.initDetailSearchCondition();
      this.searchCondition.type = tab.name;
      this.onSearch();
    },
    onSearch() {
      this.$refs.grid.searchRefresh();
    },
    onFormatter(data) {
      switch (this.searchCondition.type) {
        case "point":
          break;
        case "practice":
          break;
        case "simulation":
          data.forEach(i => {
            // 考试类型
            i.exam_type = ExamType.getValue(i.report_type);
            // 考试状态
            const nowTs = parseInt(new Date().getTime() / 1000);
            if (nowTs > i.package_start_time && nowTs < i.package_end_time) {
              i.exam_status = "进行中";
              i.examing = true;
            } else {
              i.exam_status = "已逾期";
              i.examing = false;
            }
          });
          break;
      }
    },
    onWatchReport(row) {
      if (this.searchCondition.type == "point") {
        this.$router.push({
          name: "ExamPointAnalysis",
          query: {
            id: row.report_id,
            route_name: "MemberEvaluationTest",
            route_label: "测评记录"
          }
        });
      } else {
        this.$router.push({
          name: "ExamPackagePracticeScore",
          query: {
            id: row.report_id,
            type: this.searchCondition.type == "practice" ? "" : "s",
            route_name: "MemberEvaluationTest",
            route_label: "测评记录"
          }
        });
      }
    },
    onRePractice(row) {
      if (this.searchCondition.type == "point") {
        this.$router.push({
          name: "ExamPointDo",
          query: {
            id: row.package_id,
            number: row.ques_count,
            report_id: row.report_id,
            route_name: "MemberEvaluationTest",
            route_label: "测评记录",
            retry: 1
          }
        });
      } else {
        if (
          this.searchCondition.type == "simulation" &&
          row.can_practice == 2 // 1：可以  2：不可以
        ) {
          this.$warning("考试次数已达上限", {
            duration: 1000
          });
          return;
        }
        this.$router.push({
          name: "ExamPackagePracticeDo",
          query: {
            id: row.package_id,
            type: this.searchCondition.type == "practice" ? "" : "s",
            action: "start",
            route_name: "MemberEvaluationTest",
            route_label: "测评记录"
          }
        });
      }
    },
    // other
    initEchart() {
      this.myEchart = echarts.init(this.$refs.pie);
    },
    setEchart() {
      if (!this.myEchart) return;
      this.myEchart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          right: 30,
          top: "middle",
          data: [this.label.kaodian, this.label.taojuan, this.label.fangzhen]
        },
        series: [
          {
            name: "占比",
            type: "pie",
            center: [120, "50%"],
            radius: ["30%", "70%"],
            label: {
              show: false,
              position: "inside",
              formatter: "{d}%",
              color: "#fff",
              fontSize: 16
            },
            data: [
              {
                value: this.count.kaodian,
                name: this.label.kaodian,
                itemStyle: { color: "#FFB05E" }
              },
              {
                value: this.count.taojuan,
                name: this.label.taojuan,
                itemStyle: { color: "#07BEAB" }
              },
              {
                value: this.count.fangzhen,
                name: this.label.fangzhen,
                itemStyle: { color: "#8CEC9E" }
              }
            ]
          }
        ]
      });
    },
    initTableColumns(type = "point") {
      switch (type) {
        case "point":
          this.tableColumns = [
            { label: "考点名称", prop: "package_name" },
            { label: "正确数/答题总数", slot: "done", align: "center" },
            { label: "正确率", slot: "correct_rate", align: "center" },
            { label: "练习时间", slot: "time", align: "center" },
            { label: "操作", slot: "action", align: "center" }
          ];
          break;
        case "practice":
          this.tableColumns = [
            { label: "套卷名称", prop: "package_name" },
            { label: "答题情况", slot: "done", align: "center" },
            { label: "提交时间", slot: "time", align: "center" },
            { label: "操作", slot: "action", align: "center" }
          ];
          break;
        case "simulation":
          this.tableColumns = [
            { label: "考试类型", prop: "exam_type" },
            { label: "考试名称", prop: "package_name", align: "center" },
            { label: "考试状态", prop: "exam_status", align: "center" },
            { label: "考试次数", slot: "count", align: "center" },
            { label: "答题情况", slot: "done", align: "center", width: 80 },
            { label: "提交时间", slot: "time", align: "center", width: 160 },
            { label: "操作", slot: "action", align: "center", width: 150 }
          ];
          break;
      }
    },
    initDetailSearchCondition() {
      this.datetimeRange = null;
      this.operateType = "";
      this.examType = "";
      this.searchCondition.init();
    },
    // request
    requestBaseinfo(type = "today") {
      this.$http
        .get("/api/app/examStatistics/indexBase", {
          search_type: type
        })
        .then(data => {
          this.count.total = data.total_count;
          this.count.correct_rate = data.correct_rate;
          this.count.kaodian = data.point_count;
          this.count.taojuan = data.practice_count;
          this.count.fangzhen = data.simulation_count;
          this.setEchart();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.member-evalutation-test {
  background-color: #f0f2f5;
  margin-bottom: 50px;
  .color-kaodian {
    color: #ffb05e;
  }
  .color-taojuan {
    color: #07beab;
  }
  .color-fangzhen {
    color: #8cec9e;
  }
  .action-split {
    margin: 0 5px;
  }
  .total {
    height: 508px;
    background-color: #fff;
    padding: 20px;
    .part1 {
      display: flex;
      justify-content: space-around;
      padding: 30px 0;
      .card {
        width: 296px;
        height: 118px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(229, 229, 229, 1);
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-size: 18px;
        line-height: 118px;
        text-align: center;
        .label {
          color: #666;
        }
        .number {
          color: #333;
          font-weight: 500;
        }
      }
    }
    .part2 {
      position: relative;
      .pie {
        width: 360px;
        height: 290px;
        float: left;
      }
      .pie-center {
        position: absolute;
        top: 103px;
        left: 78px;
        width: 84px;
        height: 84px;
        border-radius: 50%;
        background-color: #e4e5e7;
        color: #666666;
        font-size: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .count {
        position: absolute;
        right: 10px;
        top: 80px;
        width: 520px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .count-item {
          width: 142px;
          height: 134px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(238, 238, 238, 1);
          box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.25);
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .count-item-number {
            font-size: 24px;
            text-align: center;
            margin-bottom: 10px;
          }
          .count-item-name {
            font-size: 14px;
            text-align: center;
            color: #666666;
          }
        }
      }
    }
  }
  .detail {
    background-color: #fff;
    margin-top: 30px;
    padding: 10px 20px 50px 20px;
    position: relative;
  }
  /deep/ .el-input-group__append {
    background-color: $primary_color;
    border: 1px solid $primary_color;
    border-left: 0;
    .el-icon-search {
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>