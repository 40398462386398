<template>
  <div>
    <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{'tab-item-current':current==item.type}"
        @click="changeTab(item.type)"
        :key="item.type"
        v-for="item in types"
      >
        <span>{{item.name}}({{item.num}})</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <div class="tab-content">
      <div class="vip-tip" v-if="vipCourseCount>0&&$store.state.user_info.is_vip">
        <span>课程中有会员课，会员将于{{vipEndDate}}过期。</span>
        <router-link :to="{name:'MemberVip'}" class="lnk-vip" v-if="vipEndDateCount<=3">去续费</router-link>
      </div>
      <div class="courses" v-if="courses.length">
        <div class="nav nav-left" @click="navLeft"></div>
        <div class="nav nav-right" @click="navRight"></div>
        <div style="overflow:hidden;">
          <div
            class="item-container clear"
            :style="{width:courses.length*400+'px',marginLeft:-navLeftIndex*348+'px'}"
          >
            <div
              class="item"
              :class="{'selected':item.course_id==currentCourse.course_id,'invalid':item.is_vip_course==1&&!isVipUser&&!item.is_buy_order}"
              v-for="(item,index) in courses"
              :key="item.course_id"
              @click="changeCourse(index)"
            >
              <div class="content">
                <img
                  class="vip-invalid"
                  v-if="item.is_vip_course==1&&!isVipUser&&!item.is_buy_order"
                  src="../../images/invalid@3x.png"
                  style="width:79px;"
                />
                <div class="title" :class="{'has-vip':item.is_vip_course}">
                  <img
                    class="vip"
                    v-if="item.is_vip_course"
                    src="@/assets/images/course/vip-index@3x.png"
                  />
                  <span>{{item.title}}</span>
                </div>
                <div class="time">
                  <span v-if="item.start_play_date>0">
                    <img class="clock" src="../../images/clock.png" />
                    <span>{{new Date(item.start_play_date*1000).format("MM月dd日")}}-{{new Date(item.end_play_date*1000).format("MM月dd日")}}</span>
                  </span>
                  <span v-if="item.course_type!=4&&item.start_play_date>0" class="line">|</span>
                  <span v-if="item.course_type!=4">共{{item.section_num}}课时</span>
                </div>
                <div class="bottom clear" v-if="item.course_type!=10">
                  <div class="f-left progress">
                    <div class="progress-current" :style="{width:item.progress_rate/100*120+'px'}"></div>
                  </div>
                  <span class="f-left">已学习{{item.progress_rate}}%</span>
                  <div class="f-right right">
                    <span v-if="item.course_type==2||item.course_type==3||item.course_type==4">
                      <span v-if="item.now_course_num>0">正在直播</span>
                      <span v-else-if="item.today_course_num>0">今日有直播</span>
                    </span>
                    <span v-else>
                      <span v-if="item.now_course_num>0">正在上课</span>
                      <span v-else-if="item.today_course_num>0">今日有课</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>
      </div>
      <div v-else class="center" style="padding:40px 0;">暂无课程</div>
      <div class="detail" v-if="detail.course&&detail.course.course_id">
        <div class="clear header">
          <span
            class="f-left teacher"
            :title="detail.course.teacher_name"
          >讲师：{{detail.course.teacher_name}}</span>
          <span class="f-right">
            <a class="comment" @click="beginComment">{{detail.course.is_comment==1?'查看评价':'评价课程'}}</a>
            <span class="line">|</span>
            <router-link :to="{name:'CourseShow',params:{id:detail.course.course_id}}">查看课程详情</router-link>
            <span class="line">|</span>
            <a @click="deleteCourse">移除学习列表</a>
            <span class="line" v-if="detail.course.has_homework&&$store.state.commonSetting.homework_module.is_open=='1'">|</span>
            <router-link :to="{name:'MemberCourseHomework',query:{course:detail.course.course_id,title:detail.course.title}}" v-if="detail.course.has_homework&&$store.state.commonSetting.homework_module.is_open=='1'">课程作业</router-link>
          </span>
        </div>
        <div class="chapters" v-if="detail.chapter">
          <div
            class="chapter"
            :class="{'expand':chapterExpand[chapter.id]==1}"
            v-for="chapter in detail.chapter"
            :key="chapter.id"
          >
            <div class="clear chapter-header" @click="toggleExpand(chapter.id)">
              <div class="flag f-left"></div>
              <div class="title f-left">{{chapter.title}}</div>
            </div>
            <div class="periods" v-if="chapter.child&&chapter.child.length">
              <MemberStudyPeriod
                v-for="period in chapter.child"
                :key="period.id"
                :period="period"
                :course_id="currentCourse.course_id"
                :course_type="currentCourse.course_type"
              ></MemberStudyPeriod>
            </div>
          </div>
        </div>
        <div v-else class="period-list-only">
          <MemberStudyPeriod
            v-for="period in detail.periods"
            :key="period.id"
            :period="period"
            :course_id="currentCourse.course_id"
            :course_type="currentCourse.course_type"
          ></MemberStudyPeriod>
        </div>
      </div>
    </div>
    <el-dialog class="comment-dialog" title="评价" :visible.sync="showCommentDialog" width="784px">
      <div v-if="detail.course">
        <div class="name item">课程名称：{{detail.course.title}}</div>
        <div class="item">
          <label>星级：</label>
          <el-rate v-model="commentInfo.grade" :disabled="detail.course.is_comment == 1"></el-rate>
        </div>
        <div class="item">
          <label>评价内容：</label>
          <br />
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入对课程的评价内容，最多500字"
            v-model="commentInfo.content"
            :maxlength="500"
            :readonly="detail.course.is_comment == 1"
          ></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitComment">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="vip-dialog" title="提示" :visible.sync="showVipDialog" width="500px">
      <div>
        <i class="el-icon-warning" style="color:#e6a23c;"></i>
        {{vipDialogInfo}}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deleteCourse();showVipDialog=false">移除课程</el-button>
        <el-button type="primary" @click="$router.push({name:'MemberVip'})">续费会员</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MemberStudyPeriod from "../../components/MemberStudyPeriod.vue";

export default {
  name: "MemberStudy",
  components: {
    MemberStudyPeriod
  },
  data() {
    return {
      user: {},
      current: 2,
      vipCourseCount: 0,
      vipEndDateCount: 0,
      vipEndDate: 0,
      types: [],
      courses: [],
      currentCourse: {},
      currentIndex: 0,
      navLeftIndex: 0,
      detail: {},
      showCommentDialog: false,
      commentInfo: {
        grade: 0,
        content: ""
      },
      chapterExpand: {},
      showVipDialog: false,
      vipDialogInfo: "",
      isVipUser: false
    };
  },
  async created() {
    let type = this.$route.query.type;
    if (type == 3 || type == 9) {
      type = 2;
    }
    this.current = type || 2;
    await this.setVipTip();
    await this.getData();
    if (
      this.types.length > 0 &&
      this.types.filter(t => t.type == this.current).length == 0
    ) {
      this.current = this.types[0].type;
      await this.getData();
    }
  },
  methods: {
    async setVipTip() {
      let user = await this.$http.get("/api/app/userInfo");
      this.user = user;
      let vip = user.vip;
      if (vip && vip.vip_end_at) {
        let end = new Date(vip.vip_end_at * 1000);
        this.vipEndDate = end.format("yyyy年MM月dd日");
        this.vipEndDateCount = parseInt(
          (end.getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000)
        );
        if (this.vipEndDate >= new Date().format("yyyy-MM-dd")) {
          this.isVipUser = true;
        }
      }
    },
    async getData() {
      let data = await this.$http.get("/api/app/myStudy/" + this.current);
      this.types = data.typeNum;
      if (this.$store.state.commonSetting.switch_vip_module !== 1) {
        for (let i = 0; i < this.types.length; i++) {
          if (this.types[i].type == 11) {
            this.types.splice(i, 1);
            break;
          }
        }
      }
      this.vipCourseCount = data.courseList.filter(
        c => c.is_vip_course == 1
      ).length;
      data.courseList = data.courseList.filter(c => c.course_type != 4);
      this.courses = data.courseList;
      if (data.courseList.length > 0) {
        let id = this.$route.query.id;
        let i = 0;
        if (id) {
          for (; i < data.courseList.length; i++) {
            if (data.courseList[i].course_id == id) {
              this.currentCourse = data.courseList[i];
              this.navLeftIndex = i;
              this.currentIndex = i;
              break;
            }
          }
        }
        if (!id || i == data.courseList.length) {
          this.currentCourse = data.courseList[0];
          this.navLeftIndex = 0;
          this.currentIndex = 0;
        }
      } else {
        this.currentCourse = {};
        this.navLeftIndex = 0;
        this.currentIndex = 0;
      }
    },
    async changeCourse(index) {
      this.currentCourse = this.courses[index];
      this.currentIndex = index;
      if (
        this.currentIndex >= this.navLeftIndex + 2 &&
        this.currentIndex < this.courses.length
      ) {
        this.navLeftIndex++;
      }
      if (this.currentIndex == this.navLeftIndex && this.currentIndex > 0) {
        this.navLeftIndex--;
      }
    },
    async changeTab(tab) {
      this.current = tab;
      await this.getData();
    },
    navLeft() {
      if (this.navLeftIndex == 0) {
        return;
      }
      this.navLeftIndex--;
    },
    navRight() {
      if (this.navLeftIndex >= this.courses.length - 1) {
        return;
      }
      this.navLeftIndex++;
    },
    formatPerid(perid) {
      let teacherNames = [];
      perid.teachers.forEach(t => {
        teacherNames.push(t.teacher_name);
      });
      perid.teacher_name = teacherNames.join(",");
      let files = [];
      if (perid.datum && perid.datum.length > 0) {
        let fileNames = perid.datum[0].file_name.split(";");
        let datum_id = perid.datum[0].datum_id;
        for (let i = 0; i < fileNames.length; i++) {
          let fileName = fileNames[i];
          files.push({ file_name: fileName, datum_id: datum_id });
        }
      }
      perid.files = files;
    },
    async getCourseDetail() {
      if (!this.currentCourse.course_id) {
        this.detail = {};
        return;
      }
      if (
        this.currentCourse.is_vip_course == 1 &&
        !this.isVipUser &&
        this.currentCourse.is_buy_order == 0
      ) {
        this.detail = {};
        this.vipDialogInfo = "您的会员已失效";
        this.showVipDialog = true;
        return;
      }
      this.detail = await this.$http.get(
        "/api/app/myStudy/course/" + this.currentCourse.course_id
      );
      let chapterExpand = {};
      if (this.detail.chapter) {
        this.detail.chapter.forEach(c => {
          chapterExpand[c.id] = 0;
          if (!c.child) {
            return;
          }
          c.child.forEach(child => {
            this.formatPerid(child);
          });
        });
      } else if (this.detail.periods) {
        this.detail.periods = this.detail.periods.filter(p => p.parent_id != 0);
        this.detail.periods.forEach(p => {
          this.formatPerid(p);
        });
      }
      this.chapterExpand = chapterExpand;
    },
    async deleteCourse() {
      await this.$confirm(
        "移除的课程在“我的学习”列表中将不再显示。您可以在订单中重新将其加入“我的学习”。",
        "提示",
        { type: "warning" }
      );
      await this.$http.delete(
        "/api/app/myStudy/course/" + this.currentCourse.course_id
      );
      await this.$success("移除成功");
      await this.getData();
    },
    async beginComment() {
      this.commentInfo.grade = 0;
      this.commentInfo.content = "";
      if (this.detail.course.is_comment == 1) {
        let data = await this.$http.get(
          "/api/app/myStudy/comment/" + this.currentCourse.course_id
        );
        this.commentInfo.grade = data.grade;
        this.commentInfo.content = data.content;
      }
      this.showCommentDialog = true;
    },
    async submitComment() {
      if (this.detail.course.is_comment == 1) {
        this.showCommentDialog = false;
        return;
      }
      if (this.commentInfo.grade == 0) {
        await this.$info("请选择星级");
        return;
      }
      if (this.commentInfo.content == "") {
        await this.$info("请输入评价内容");
        return;
      }
      await this.$http.post("/api/app/myStudy/comment", {
        grade: this.commentInfo.grade,
        content: this.commentInfo.content,
        course_id: this.currentCourse.course_id,
        type: 1
      });
      this.showCommentDialog = false;
      this.detail.course.is_comment = 1;
      await this.$success("评价成功");
    },
    toggleExpand(id) {
      if (this.chapterExpand[id] == 0) {
        this.chapterExpand[id] = 1;
      } else {
        this.chapterExpand[id] = 0;
      }
    }
  },
  watch: {
    async currentCourse() {
      await this.getCourseDetail();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/member-study.scss";
</style>
