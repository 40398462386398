<template>
  <div>
    <div class="detail">
      <div class="detail_status">
        <div class="status_title">
          <span>当前订单状态: </span>
          <!-- order_status 1.交易中 2:交易完成 3.交易取消 -->
          <!-- pay_status 支付状态:1:待支付 2:已支付 3:退款申请中 4:退款中 5:已退款 6: 退款失败 -->
          <!-- ship_status  发货状态 1待发货 2无需发货 3已发货 4已收货 -->
          <!-- 待支付 -->
          <span class="red"
                v-if="detail.pay_status == 1 && detail.order_status==1">{{detail.status_name|orderStatus}}
            <el-button type="primary"
                       style="margin-left:10px;"
                       size="small"
                       @click="goToPay(detail)">立即付款</el-button>
          </span>
          <!-- 已发货 -->
          <span v-else-if="detail.pay_status==2">{{detail.status_name}}
            <!-- <el-button v-if="detail.shop_type==12 && detail.ship_status==3" type="primary"
                       style="margin-left:10px;"
                       size="small">查看物流</el-button> -->
            <el-button v-if="detail.shop_type==12 && detail.ship_status==3"
                       type="primary"
                       style="margin-left:10px;"
                       size="small"
                       @click="haveGoods(false)">确认收货</el-button>
            <!-- (detail.ship_status == 4 || detail.ship_status == 2) -->
            <!-- 待评价 -->
            <span v-if="detail.shop_type!=9 && (detail.ship_status == 4 || detail.ship_status == 2) && detail.is_comment==2">
              <el-button type="primary"
                         style="margin-left:10px;"
                         size="small"
                         @click="comment(detail)">立即评价</el-button>
            </span>
          </span>
          <span v-else>{{detail.status_name}}</span>
        </div>
        <p class="detail_tips"><span class="el-icon-warning"></span> 如果没有收到商品，或收到商品后出现问题，您可以联系我们协商解决</p>
      </div>
      <div v-if="detail.shop_type==12 && detail.logistics_info && detail.logistics_info.logistics_name">
        <div class="detail_title">
          物流信息
        </div>
        <div class="detail-item">
          <label>收货方式：</label>
          <span>快递</span>
        </div>
        <div class="detail-item">
          <label>物流公司：</label>
          <span>{{detail.logistics_info.logistics_name}}</span>
        </div>
        <div class="detail-item">
          <label>运单号码：</label>
          <span>{{detail.logistics_info.logistics_no}}</span>
        </div>
        <div class="detail-item logistics_content">
          <label class="f-left">物流跟踪：</label>
          <div v-if="!detail.logistics_record || !detail.logistics_record.content">
            暂无物流信息
          </div>
          <div v-else v-for="(item,index) in detail.logistics_record.content"
               :key="index">
            <span>{{item.AcceptTime}}</span>
            <span>{{item.AcceptStation}}</span>
          </div>
        </div>
      </div>
      <div v-if="detail.shop_type==12 && !detail.logistics_info ">
        <div class="detail_title">
          物流信息
        </div>
        <div class="detail-item"
             v-if="!detail.logistics_record">
          暂无物流信息
        </div>
      </div>
      <!-- 订单信息 -->
      <div>
        <div class="detail_title">
          订单信息
          <span class="red f-right"
                v-if="detail.pay_status==1&&detail.order_status==1"
                style="margin-right:20px;">30分钟内未付款，订单将自动取消</span>
        </div>
        <div class="detail-item">
          <label>订单编号：</label>
          <span>{{detail.order_number}}</span>
        </div>
        <div class="detail-item">
          <label>下单用户：</label>
          <span>{{detail.nickname}}</span>
        </div>
        <div class="detail-item">
          <label>手机号码：</label>
          <span>{{detail.mobile}}</span>
        </div>
        <div class="detail-item">
          <label>商品名称：</label>
          <span>{{detail.shop_name}}</span>
        </div>
        <div class="detail-item">
          <label>下单时间：</label>
          <span>{{new Date(parseInt(detail.created_at)*1000).format("yyyy-MM-dd hh:mm:ss")}}</span>
        </div>
        <div class="detail-item">
          <label>支付渠道：</label>
          <span>{{detail.pay_type|payType}}</span>
        </div>
        <div class="detail-item">
          <label>订单状态：</label>
          <span>{{detail.status_name}}</span>
        </div>
        <!-- 课程显示QQ和留言，图书只显示留言 -->
        <div class="detail-item" v-if="detail.shop_type<11">
          <label>QQ：</label>
          <span>{{detail.qq}}</span>
        </div>
        <div class="detail-item" v-if="detail.shop_type<11||detail.shop_type==12">
          <label>留言：</label>
          <span>{{detail.user_remark || '--'}}</span>
        </div>
        <!-- <div class="detail-item"
             v-if="detail.shop_type==12&&detail.logistics_info">
          <label>物流信息：</label>
          <span>{{detail.logistics_info.logistics_name+' '+detail.logistics_info.logistics_no}}</span>
        </div> -->
        <div class="detail-item detail-item-line"></div>
        <div class="clear">
          <div class="f-right detail-item-right">
            <div class="detail-item">
              <label>商品原价：</label>
              <span>
                <span class="jianhao"></span>
                <Currency class="currency"></Currency>
                <span>{{detail.original_price|price}}</span>
              </span>
            </div>
            <!-- <div class="detail-item">
              <label>运费：</label>
              <span>
                <span class="jianhao"></span>
                <Currency class="currency"></Currency>
                <span>{{detail.ship_price|price}}</span>
              </span>
            </div> -->
            <div class="detail-item"
                 v-if="detail.shop_type==12">
              <label>运费：</label>
              <span>
                <span class="jianhao"></span>
                <Currency class="currency"></Currency>
                <span>{{detail.ship_price|price}}</span>
              </span>
            </div>
            <div class="detail-item sale">
              <label>优惠券：</label>
              <span>
                <span class="jianhao">-</span>
                <Currency class="currency"></Currency>
                <span>{{detail.coupon_discount|price}}</span>
              </span>
            </div>
            <div class="detail-item">
              <label>订单金额：</label>
              <span>
                <span class="jianhao"></span>
                <Currency class="currency"></Currency>
                <span>{{detail.order_price|price}}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="gifts"
             v-if="detail.child&& detail.child.length>0">
          <div class="detail_title">赠送商品</div>
          <div class="header">
            <div class="name">商品名称</div>
            <div class="price">类型</div>
            <div class="status">订单状态</div>
            <div class="operate">操作</div>
          </div>
          <div class="gifts_content">
            <div class="item"
                 v-for="(item,index) in detail.child"
                 :key="index">
              <div class="clear content">
                <div class="name f-left">{{item.course_name}}</div>
                <div class="price f-left">{{getGiftType(item.type)}}</div>
                <div class="status f-left">{{getGiftShipStatus(item.status)}}</div>
                <div class="operate f-left">
                  <!--状态 0：已完成 1待发货 2部分发货 3已发货 4待评价 5:取消发货 -->
                  <span v-if="item.status<=3&&item.status!=0"
                        @click="lookAddress(item)">查看收货地址</span>
                  <span v-if="item.status==3"
                        @click="logistics(item)">查看物流</span>
                  <span v-if="item.status==4"
                        @click.stop="comment(item)">立即评价</span>
                  <span v-if="item.status==0">已评价</span>
                  <span v-if="item.status==3"
                        @click="haveGoods(item)">确认收货</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail_title" style="height:30px;background-color: #F0F2F5;"></div>
    <!-- 查看收货地址 -->
    <el-dialog title="收货地址"
               center
               :visible.sync="showAddress"
               width="30%">
      <div class="address_item">
        <label>收货地址：</label>
        <span>{{address.province_name+address.city_name+address.district_name+address.address}}</span>
      </div>
      <div class="address_item">
        <label>收货人：</label>
        <span>{{address.consignee}}</span>
      </div>
      <div class="address_item">
        <label>手机号：</label>
        <span>{{address.mobile}}</span>
      </div>
      <span slot="footer"
            v-if="editAddress"
            class="dialog-footer">
        <el-button type="primary"
                   @click="edit_address_fun()">修改收货地址</el-button>
      </span>
    </el-dialog>
    <!-- 评价弹框 -->
    <el-dialog class="comment-dialog"
               title="评价"
               :visible.sync="showCommentDialog"
               width="784px">
      <div class="name item">商品名称：{{commentDetail.title || commentDetail.shop_name}}</div>
      <div class="item">
        <label>星级：</label>
        <el-rate v-model="commentInfo.grade"
                 :disabled="commentDetail.is_comment == 1"></el-rate>
      </div>
      <div class="item">
        <label>评价内容：</label>
        <br />
        <el-input type="textarea"
                  :rows="6"
                  placeholder="请输入评价内容，最多500字"
                  v-model="commentInfo.content"
                  :maxlength="500"
                  :readonly="commentDetail.is_comment == 1"></el-input>
      </div>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="submitComment">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 物流信息 -->
    <el-dialog title="物流信息"
               :visible.sync="showLogisticsDialog"
               width="656px">
      <div class="logistics_item">
        <label>收货方式：</label>
        <span>快递</span>
      </div>
      <div class="logistics_item">
        <label>物流公司：</label>
        <span>{{logistics_detail.logistics_name}}</span>
      </div>
      <div class="logistics_item">
        <label>运单号码：</label>
        <span>{{logistics_detail.logistics_no}}</span>
      </div>
      <div class="logistics_item logistics_content">
        <label class="f-left">物流跟踪：</label>
        <div v-for="(item,index) in logistics_detail.content"
             :key="index">
          <span>{{item.AcceptTime}}</span>
          <span>{{item.AcceptStation}}</span>
        </div>
        <div v-if="!logistics_detail.content || logistics_detail.content.length==0">
          暂无物流信息
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderFuc from "@/utils/order.js";
import { ShopType, GiftShipStatus } from '@/utils/enums'

export default {
  name: "MemberOrderDetail",
  data () {
    return {
      order_id: '',
      detail: {},
      address: {},
      address_id: '',
      showAddress: false,
      editAddress: false,
      showCommentDialog: false,
      showLogisticsDialog: false,
      logistics_detail: {},
      commentDetail: {},
      commentInfo: {
        grade: 0,
        content: ""
      }
    };
  },
  async created () {
    this.order_id = this.$route.query.order_id;
    this.showDetail();
  },
  methods: {
    async showDetail () {
      let data = await this.$http.post("/api/app/myOrder/detail", {
        order_id: this.order_id
      });
      let desc = orderFuc.getListDescData(data);
      let text = desc.text;
      data.status_name = text ? text.list : "";
      data.status_id = desc.id;
      this.detail = data;
    },
    goToPay (order) {
      this.$router.push({
        name: "OrderPay",
        params: { id: order.id },
        query: { type: order.shop_type }
      });
    },
    /**
     * 赠品类型
     */
    getGiftType (val) {
      return ShopType.getValue(val);
    },
    /**
     * 赠品状态
     */
    getGiftShipStatus (type) {
      return GiftShipStatus.getValue(type)
    },
    comment (item) {
      this.showCommentDialog = true;
      this.commentDetail = item;
    },
    async submitComment () {
      if (this.commentDetail.is_comment == 1) {
        this.showCommentDialog = false;
        return;
      }
      if (this.commentInfo.grade == 0) {
        await this.$info("请选择星级");
        return;
      }
      if (this.commentInfo.content == "") {
        await this.$info("请输入评价内容");
        return;
      }
      if (this.detail.shop_type == 15 || this.detail.shop_type == 16 || !this.commentDetail.id) {
        await this.$http.post("/api/app/commentOrderItem", {
          grade: this.commentInfo.grade,
          content: this.commentInfo.content,
          shop_id: this.commentDetail.shop_ids,
          order_item_id: this.commentDetail.id ? this.detail.id : this.commentDetail.order_item_id,
          order_num: this.detail.order_number,
          type: this.commentDetail.shop_type
        });
      } else if (this.detail.shop_type == 12) {
        await this.$http.post("/api/app/book/comment", {
          grade: this.commentInfo.grade,
          content: this.commentInfo.content,
          book_id: this.commentDetail.shop_ids,
          order_num: this.detail.order_number,
        });
      } else {
        await this.$http.post("/api/app/myStudy/comment", {
          grade: this.commentInfo.grade,
          content: this.commentInfo.content,
          course_id: this.commentDetail.shop_ids,
          type: 1,
        });
      }
      this.showDetail();
      this.showCommentDialog = false;
      await this.$success("评价成功");
    },
    getAddress (id) {
      let param = { order_id: id }
      this.$http.get(`/api/app/myOrder/childOrderAddress`, param).then((res) => {
        this.address = res;
        this.address_id = res.address_id;
      });
    },
    edit_address_fun () {
      this.$router.push({ name: 'MemberAddress' });
    },
    // 查看收货地址
    lookAddress (item) {
      if (item.status == 1) {//只有未发货的才可以修改收货地址
        this.editAddress = true;
      } else {
        this.editAddress = false;
      }
      this.getAddress(item.order_item_id);
      this.order_address_id = item.order_address_id;
      this.showAddress = true;
    },
    /**
     * 查看物流
     */
    logistics (item) {
      this.$http.get(`/api/app/comment/getLogistics`, { order_id: item.order_item_id }).then(res => {
        this.logistics_detail = res;
        this.showLogisticsDialog = true;
      });
    },
    /**
     * 确认收货
     */
    haveGoods (item) {
      this.$confirm('请收到货物后再点击确认收货，确认收货吗？?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let id = this.detail.order_item_id;
        if (item) {
          id = item.order_item_id;
        }
        this.$http.put(`/api/app/myOrder/confirmReceive`, { order_id: id }).then(() => {
          this.showDetail();
        });
      })
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/member-order.scss";
</style>
