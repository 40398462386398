<template>
  <div>
    <div class="main-content clear" v-if="list.length>0">
      <ExamQuestion v-for="item in list" :ques="item" :answer="answer" :key="item.ques_id" :readonly="true"></ExamQuestion>
    </div>
  </div>
</template>
<script>
import { ExamTopicType } from "@/utils/enums";
import ExamQuestion from "../../components/ExamQuestion.vue";
export default {
  name: "ExamPointDo",
  components: {
    ExamQuestion
  },
  data() {
    return {
      list: [],
      index: 0,
      answer: {},
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      seconds: 0,
      time: "00:00:00",
      timer: null,
      answerCount: 0,
      answerState: {},
      isPause: false,
      isSubmit: false
    };
  },
  async created() {
    let data = await this.$http.get(
      "/api/app/homework/question/" + this.$route.query.id
    );
    let index = 1;
    data.forEach(ques => {
      ques.id = ques.ques_id;
      if (typeof ques.ques_option === "string") {
        ques.ques_option = JSON.parse(ques.ques_option);
      }
      this.$set(this.answerState, index, 0);
      ques.index = index;
      ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
      if (ques.ques_type == 1 || ques.ques_type == 3 || ques.ques_type == 5) {
        this.$set(this.answer, ques.id, "");
      } else if (ques.ques_type == 2) {
        this.$set(this.answer, ques.id, []);
      } else if (ques.ques_type == 4) {
        let answer = {};
        ques.ques_option.left.forEach(op => {
          answer[op.id] = "";
        });
        this.$set(this.answer, ques.id, answer);
      }
      index++;
    });
    this.list = data;

    this.timer = setInterval(() => {
      if (this.isPause || this.isSubmit) {
        return;
      }
      this.seconds++;
      let seconds = this.seconds;
      let time = "";
      let hours = parseInt(seconds / 60 / 60);
      time += hours < 10 ? "0" + hours.toString() : hours.toString();
      let minutes = parseInt((seconds / 60) % 60);
      time +=
        ":" + (minutes < 10 ? "0" + minutes.toString() : minutes.toString());
      let surplus_seconds = seconds % 60;
      time +=
        ":" +
        (surplus_seconds < 10
          ? "0" + surplus_seconds.toString()
          : surplus_seconds.toString());
      this.time = time;
    }, 1000);
  },
  methods: {
    onChange(ques) {
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        if (this.answer[ques.id] != 0 && this.answerState[ques.index] != 1) {
          this.$set(this.answerState, ques.index, 1);
          this.answerCount++;
        }
      } else if (ques.ques_type == 2) {
        if (this.answer[ques.id].length > 0) {
          if (this.answerState[ques.index] != 1) {
            this.$set(this.answerState, ques.index, 1);
            this.answerCount++;
          }
        } else {
          if (this.answerState[ques.index] == 1) {
            this.$set(this.answerState, ques.index, 0);
            this.answerCount--;
          }
        }
      } else if (ques.ques_type == 4) {
        let state = 1;
        let lineAnswer = this.answer[ques.id];
        for (let key in lineAnswer) {
          if (lineAnswer[key] == 0) {
            state = 0;
            break;
          }
        }
        if (state == 1 && this.answerState[ques.index] == 0) {
          this.$set(this.answerState, ques.index, state);
          this.answerCount++;
        }
      } else if (ques.ques_type == 5) {
        if (this.answer[ques.id].length > 0) {
          if (this.answerState[ques.index] != 1) {
            this.$set(this.answerState, ques.index, 1);
            this.answerCount++;
          }
        } else {
          if (this.answerState[ques.index] == 1) {
            this.$set(this.answerState, ques.index, 0);
            this.answerCount--;
          }
        }
      }
    },
    navPrev() {
      if (this.index == 0) {
        this.$info("已至第一题");
        return;
      }
      this.index--;
    },
    navNext() {
      if (this.index == this.list.length - 1) {
        this.$info("已至最后一题");
        return;
      }
      this.index++;
    },
    async submit() {
      this.isSubmit = true;
      let count = 0;
      let quesCount = 0;
      for (let key in this.answerState) {
        quesCount++;
        if (this.answerState[key] == 0) {
          count++;
        }
      }
      // 如果没有做任何题
      if (count == quesCount) {
        await this.$promiseConfirm(
          '<span class="dialog-info">您已做<span class="primary-color">0</span>道题，是否退出答题？</span>',
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "继续答题",
            cancelButtonText: "退出答题",
            distinguishCancelAndClose: true,
            callback: action => {
              if (action == "cancel") {
                this.$router.go(-1);
              }
            }
          }
        );
        return;
      }
      if (count > 0) {
        await this.$promiseConfirm(
          '<span class="dialog-info">您还有<span class="primary-color">' +
            count +
            "</span>道题未做，确定要提交吗？</span>",
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "确认交卷",
            cancelButtonText: "继续答题",
            callback: () => {
              this.isSubmit = false;
            }
          }
        );
      } else {
        await this.$promiseConfirm(
          '<span class="dialog-info">确定要提交吗？</span>',
          {
            type: "warning",
            customClass: "dialog-point",
            confirmButtonText: "确认交卷",
            cancelButtonText: "继续答题",
            callback: () => {
              this.isSubmit = false;
            }
          }
        );
      }
      let result = {};
      for (let key in this.answer) {
        let ques = this.list.filter(q => q.id == key)[0];
        let state = this.answerState[ques.index];
        result[ques.id] = { report_type: ques.ques_type };
        if (state == 1) {
          if (
            ques.ques_type == 1 ||
            ques.ques_type == 3 ||
            ques.ques_type == 5
          ) {
            result[ques.id].user_answer = [this.answer[key]];
          } else if (ques.ques_type == 2) {
            result[ques.id].user_answer = this.answer[key];
          } else if (ques.ques_type == 4) {
            result[ques.id].user_answer = this.answer[key];
          }
        } else {
          result[ques.id].user_answer = [];
        }
      }
      const params = {
        question: JSON.stringify(result),
        report_id: this.$route.query.id,
        report_time_long: this.seconds
      };
      await this.$http.post("/api/app/homework/question/storeReport", params);
      this.$router.push({
        name: "MemberHomework"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.main-content {
  margin-top: 30px;
  margin-bottom: 94px;
  background-color: white;
  > .f-left {
    width: 604px;
    background-color: white;
    /deep/ .options {
      padding-left: 25px;
    }
  }
  > .f-right {
    width: 290px;
  }
}
.dialog-info {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 63px;
    margin-right: 27px;
  }
}
.time {
  color: #333333;
  margin-bottom: 15px;
  .f-left,
  .f-right {
    padding: 12px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .f-left {
    background-color: white;
    width: 177px;
  }
  .f-right {
    background-color: white;
    width: 96px;
    cursor: pointer;
    font-size: 16px;
    > span {
      display: flex;
      align-items: center;
    }
    .icon {
      margin-right: 10px;
    }
    span {
      margin: 0 !important;
      color: #595959 !important;
    }
    .el-icon-caret-right {
      font-size: 20px;
      margin-right: 6px;
    }
  }
  span:nth-child(2) {
    margin: 0 15px;
  }
  span:last-child {
    color: $primary-color;
  }
}
.answer-view {
  box-sizing: border-box;
  border-radius: 5px;
  .progress {
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    font-size: 16px;
    .primary-color {
      font-size: 18px;
    }
  }
  .nums {
    background-color: white;
    padding: 0 12px 12px 12px;
    margin-bottom: 15px;
  }
  .num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 3px;
    color: #b8b8b8;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
    &.done {
      background-color: $primary-color;
      color: white;
    }
    &:nth-child(7) {
      margin-right: 0;
    }
  }
  .legend {
    background-color: white;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .num {
      margin-top: 0;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      cursor: default;
    }
    span:nth-child(2) {
      margin-right: 35px;
    }
  }
  .submit {
    background-color: $primary-color;
    color: white;
    border-radius: 5px;
    text-align: center;
    font-size: 16px;
    padding: 12px 0;
    cursor: default;
  }
}
.topic-nav a {
  width: 137px;
  height: 45px;
  display: inline-block;
  border: 1px solid rgba(233, 236, 236, 1);
  border-radius: 4px;
  background-color: white;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    border: 1px solid $primary-color;
  }
}
</style>