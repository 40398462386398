<template>
  <div class="member-integral-record clear">
    <div class="mtab-wrap">
      <p
        class="mtab"
        :class="{ active: curTab == item.id }"
        v-for="(item, index) in tabs"
        :key="index"
        @click="onChangeTab(item)"
      >{{ item.label }}</p>
    </div>
    <div class="table">
      <el-row class="head">
        <el-col
          v-for="(item, index) in tableColumns"
          :key="index"
          :span="item.span"
        >{{ item.label }}</el-col>
      </el-row>
      <empty v-if="!list.length" :img-width="130" :tb-padding="30"></empty>
      <el-row class="item" type="flex" v-for="(item, index) in list" :key="index">
        <el-col :span="tableColumns[0].span" class="name">
          <img
            class="cover"
            :class="item.shop_type == 12 ? 'book' : 'course'"
            :src="item.cover_img"
            alt
          />
          <span>{{ item.product_name }}</span>
        </el-col>
        <el-col :span="tableColumns[1].span" class="point">
          {{
          item.product_price
          }}
        </el-col>
        <el-col :span="tableColumns[2].span">{{ getStatusName(item) }}</el-col>
        <el-col :span="tableColumns[3].span">
          {{
          item.created_at | tsFormat("yyyy/MM/dd hh:mm:ss")
          }}
        </el-col>
        <el-col :span="tableColumns[4].span" class="action">
          <div v-if="item.shop_type == 12">
            <div class="btn-box" v-if="item.order_status != 2 && item.ship_status == 3">
              <p class="btn" @click="onLogisticsDetail(item)">物流信息</p>
              <p class="btn primary" @click="onConfirmShip(item)">确认收货</p>
            </div>
            <p v-else>--</p>
          </div>
          <div v-else>
            <div class="btn-box" v-if="!item.is_join_study">
              <p class="btn" @click="onJoinStudy(item)">加入学习</p>
            </div>
            <p v-else>--</p>
          </div>
        </el-col>
      </el-row>
      <el-pagination
        background
        @current-change="onCurrentChange"
        :current-page="page"
        :page-size="limit"
        layout="prev, pager, next, total, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      class="logistice-dlg"
      title="物流信息"
      :visible.sync="dlgLogistics.show"
      width="400px"
      center
    >
      <div class="dlg-content">
        <p>
          <span class="dlg-content-label">快递公司：</span>
          <span>{{ dlgLogistics.logistics_name }}</span>
        </p>
        <p>
          <span class="dlg-content-label">快递单号：</span>
          <span>{{ dlgLogistics.logistics_number }}</span>
        </p>
      </div>
      <div slot="footer" class="dlg-btn-wrap">
        <el-button @click="dlgLogistics.show = false" size="small" round>取消</el-button>
        <el-button
          class="btn-comfirm"
          type="primary"
          @click="onCopyLogistics"
          size="small"
          round
        >复制单号</el-button>
      </div>
    </el-dialog>
    <el-dialog title="确认收货" :visible.sync="dlgComfirmShip.show" width="400px" center>
      <div class="dlg-content">
        <p>确认收货后，订单将会完成，是否确认？</p>
      </div>
      <div slot="footer" class="dlg-btn-wrap">
        <el-button @click="dlgComfirmShip.show = false" size="small" round>取消</el-button>
        <el-button type="primary" @click="onOkConfirmShip" size="small" round>确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty";
import ClipboardJS from "clipboard";
export default {
  name: "member-integral-record",
  components: {
    Empty
  },
  data() {
    return {
      tabs: [
        { id: 0, label: "全部" },
        { id: 1, label: "待发货" },
        { id: 2, label: "已发货" },
        { id: 3, label: "已完成" }
      ],
      curTab: 0,
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      dlgLogistics: {
        show: false,
        logistics_name: "",
        logistics_number: ""
      },
      dlgComfirmShip: {
        show: false,
        order_id: 0
      }
    };
  },
  computed: {
    tableColumns() {
      return [
        { label: "商品名称", span: 9 },
        {
          label: `单价（${this.$store.state.commonSetting.point_info.point_name}）`,
          span: 3
        },
        { label: "订单状态", span: 3 },
        { label: "兑换时间", span: 5 },
        { label: "操作", span: 4 }
      ];
    }
  },
  created() {
    this.requestList();
  },
  mounted() {
    this.initClipbord();
  },
  methods: {
    // on event
    onChangeTab(item) {
      this.curTab = item.id;
      this.page = 1;
      this.requestList();
    },
    onLogisticsDetail(item) {
      this.dlgLogistics.show = true;
      this.dlgLogistics.logistics_name = item.logistics_name;
      this.dlgLogistics.logistics_number = item.logistics_number;
    },
    onConfirmShip(item) {
      this.dlgComfirmShip.show = true;
      this.dlgComfirmShip.order_id = item.order_id;
    },
    onCopyLogistics() {
      this.$success("复制成功");
      this.dlgLogistics.show = false;
    },
    onOkConfirmShip() {
      this.$http
        .put(`/api/app/orderShip/confirm/${this.dlgComfirmShip.order_id}`)
        .then(() => {
          this.dlgComfirmShip.show = false;
          this.requestList();
        });
    },
    onJoinStudy(item) {
      this.$http
        .post("/api/app/joinStudy", {
          order_id: item.order_id,
          join_from: 2,
          course_type: item.shop_type
        })
        .then(() => {
          this.$success("课程成功加入学习列表");
        });
    },
    onCurrentChange(val) {
      this.page = val;
      this.requestList();
    },
    // other
    initClipbord() {
      const that = this;
      new ClipboardJS(".logistice-dlg .btn-comfirm", {
        text: function() {
          return that.dlgLogistics.logistics_number || " ";
        }
      });
      // clipboard.on("success", function(e) {
      //   console.log(e);
      // });
      // clipboard.on("error", function(e) {
      //   console.log(e);
      // });
    },
    getStatusName(item) {
      if (item.order_status == 2) {
        return "已完成";
      }
      if (item.shop_type == 12) {
        switch (item.ship_status) {
          case 1:
            return "待发货";
          case 3:
            return "已发货";
        }
      }
      return "";
    },
    // request
    requestList() {
      this.$http
        .get("/api/app/user/integral/order", {
          status: this.curTab,
          page: this.page,
          limit: this.limit
        })
        .then(data => {
          this.list = data.list;
          this.total = data.total;
          this.list.forEach(i => {
            i.product_price = (i.product_price / 100).toFixed(0);
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.member-integral-record {
  .mtab-wrap {
    font-size: 14px;
    margin-bottom: 20px;
    .mtab {
      display: inline-block;
      margin-right: 40px;
      height: 35px;
      line-height: 35px;
      position: relative;
      cursor: pointer;
      &.active {
        color: $primary-color;
        &::after {
          content: "";
          display: block;
          width: 28px;
          height: 1px;
          background-color: $primary-color;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  .table {
    .el-row .el-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .head {
      height: 54px;
      background-color: #fafafa;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #262626;
      line-height: 54px;
      margin-bottom: 15px;
    }
    .item {
      font-size: 14px;
      color: #595959;
      border: 1px solid #f5f5f5;
      margin-bottom: 15px;
      border-radius: 10px;
      &:hover {
        background-color: #fafafa;
      }
      .el-col {
        min-height: 120px;
        border-right: 1px solid #f5f5f5;
        padding: 20px;
        &:last-child {
          border-right: none;
        }
      }
      .name {
        justify-content: flex-start;
        font-weight: 500;
        text-align: left;
        .cover {
          margin-right: 15px;
          border-radius: 10px;
          box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
          &.book {
            width: 71px;
            height: 86px;
          }
          &.course {
            width: 114px;
            height: 67px;
          }
        }
      }
      .point {
        color: #e02020;
      }
      .action {
        flex-direction: column;
        .btn {
          color: #595959;
          font-size: 15px;
          display: inline-block;
          border: 1px solid #d9d9d9;
          border-radius: 14px;
          height: 28px;
          line-height: 28px;
          padding: 0 10px;
          margin-bottom: 15px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          &.primary {
            color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
      }
    }
  }
  .dlg-btn-wrap {
    .el-button {
      min-width: 130px;
      margin: 0 15px;
    }
  }
  .dlg-content {
    text-align: center;
    font-size: 14px;
    .dlg-content-label {
      color: #b7b7b7;
    }
  }
}
/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
</style>
