<template>
  <div class="member-integral-detail">
    <el-form inline>
      <el-form-item>
        <el-input
          v-model="searchCondition.event"
          :placeholder="`请输入${$store.state.commonSetting.point_info.point_name}行为关键字`"
          size="small"
          @keydown.native.prevent.enter="onSearch(1)"
          round
          clearable
          style="width:210px;"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          size="small"
          round
          v-model="datetimeRange"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          prefix-icon="none"
          style="width:368px;"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <p class="search-btn btn" @click="onSearch(1)">搜索</p>
        <p class="btn" @click="onSearch(0)">重置</p>
      </el-form-item>
      <el-form-item class="rule" @click.native="open">
        <i class="notice"></i>
        <span>{{$store.state.commonSetting.point_info.point_name}}规则</span>
      </el-form-item>
    </el-form>
    <Grid
      ref="grid"
      url="/api/app/user/integral/detail"
      method="get"
      :search="searchCondition"
      :columns="tableColumns"
      :formatter="onFormatter"
    ></Grid>
    <el-dialog
      class="dialogs"
      :title="info.point_name+'规则'"
      :visible.sync="dialogVisible"
      width="35%"
    >
      <div v-html="info.point_rule_info"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Grid from "@/components/Grid.vue";
export default {
  name: "MemberIntegralDetail",
  components: {
    Grid
  },
  data() {
    return {
      searchCondition: {
        event: "",
        start_at: "",
        end_at: "",
        init() {
          this.event = "";
          this.start_at = 0;
          this.end_at = 0;
        }
      },
      info: {},
      datetimeRange: null,
      dialogVisible: false
    };
  },
  computed: {
    tableColumns() {
      return [
        { label: "获取时间", prop: "created_at", align: "center", width: 180 },
        {
          label: `${this.$store.state.commonSetting.point_info.point_name}行为`,
          prop: "remark",
          align: "center"
        },
        {
          label: `${this.$store.state.commonSetting.point_info.point_name}积分值`,
          prop: "change_price",
          align: "center",
          width: 120
        }
      ];
    }
  },
  watch: {
    datetimeRange(val) {
      if (val) {
        this.searchCondition.start_at = val[0].format("yyyy-MM-dd hh:mm:ss");
        this.searchCondition.end_at = val[1].format("yyyy-MM-dd hh:mm:ss");
      } else {
        this.searchCondition.start_at = 0;
        this.searchCondition.end_at = 0;
      }
    }
  },
  methods: {
    onFormatter(data) {
      data.forEach(item => {
        item.created_at = this.$pub.tsToTime(item.created_at);
        if (item.change_type == 1) {
          item.change_price = "+" + item.change_price;
        } else {
          item.change_price = "-" + item.change_price;
        }
      });
    },
    onSearch(flag) {
      if (!flag) {
        this.datetimeRange=null;
        this.searchCondition.init();
      }
      this.$refs.grid.searchRefresh();
    },
    async open() {
      this.info = await this.$http.get("/api/app/integral/rule");
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.member-integral-detail {
  /deep/ .el-form-item {
    margin-right: 30px;
    &.rule {
      cursor: pointer;
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(183, 183, 183, 1);
      .notice {
        background: url("~@/assets/images/integral/notice.png") no-repeat;
        width: 14px;
        height: 14px;
        background-size: 100% 100%;
        display: inline-block;
        margin-right: 2px;
        vertical-align: -2px;
      }
      &:hover {
        color: #4169ff;
        .notice {
          background: url("~@/assets/images/integral/notice-hover.png")
            no-repeat;
          width: 14px;
          height: 14px;
          background-size: 100% 100%;
          display: inline-block;
          margin-right: 2px;
          vertical-align: -2px;
        }
      }
    }
    .btn {
      cursor: pointer;
      display: inline-block;
      width: 65px;
      height: 32px;
      border: 1px solid rgba(217, 217, 217, 1);
      background: rgba(255, 255, 255, 1);
      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8c8c8c;
      line-height: 32px;
      letter-spacing: 3px;
      text-align: center;
      padding-left: 3px;
      margin-right: 8px;
    }
    .search-btn {
      background: rgba(65, 105, 255, 1);
      border: none;
      color: #fff;
    }
  }
  .dialogs {
    /deep/ .el-dialog__header {
      text-align: center;
      color: #595959;
      font-size: 20px;
    }
    /deep/ .el-dialog__body {
      padding: 30px 50px;
    }
    /deep/ .el-dialog__footer {
      text-align: center;
      padding-bottom: 30px;
      .el-button {
        width: 150px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        padding: 0;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(140, 140, 140, 1);
      }
    }
  }
}
</style>
