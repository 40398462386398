<template>
  <div class="member-address">
    <div class="add-wrapper">
      <el-button type="primary" round icon="el-icon-plus" @click="onShowAddDlg">添加地址</el-button>
    </div>
    <div class="list-wrapper">
      <el-row class="title">
        <el-col :span="3">收货人</el-col>
        <el-col :span="4">所在地区</el-col>
        <el-col :span="4">详细地址</el-col>
        <el-col :span="3">邮编</el-col>
        <el-col :span="4">电话/手机</el-col>
        <el-col :span="3">操作</el-col>
        <el-col :span="3"></el-col>
      </el-row>
      <empty v-if="!list.length" :img-width="130" :tb-padding="30"></empty>
      <el-row class="item" type="flex" v-for="(item, index) in list" :key="index">
        <el-col :span="3">{{item.user_name}}</el-col>
        <el-col :span="4">{{`${item.province_name} ${item.city_name} ${item.district_name}`}}</el-col>
        <el-col :span="4">{{item.address}}</el-col>
        <el-col :span="3">{{item.zip_code}}</el-col>
        <el-col :span="4">{{item.mobile}}</el-col>
        <el-col :span="3" class="action">
          <p class="btn" @click="onModify(item)">修改</p>
          <p class="btn" @click="onDelete(item)">删除</p>
        </el-col>
        <el-col :span="3" class="default">
          <p v-if="item.is_default == 1" class="tag-default">默认地址</p>
          <p v-else class="btn" @click="onDefault(item)">设为默认</p>
        </el-col>
      </el-row>
    </div>
    <el-dialog class="dlg" :title="dlg.title" :visible.sync="dlg.show" center>
      <el-form ref="form" :model="form" :rules="formRules" label-width="110px">
        <el-form-item label="地址信息：" prop="area">
          <AreaSelect
            :provinceId="form.province_id"
            :cityId="form.city_id"
            :districtId="form.district_id"
            @change="onAreaChange"
          ></AreaSelect>
        </el-form-item>
        <el-form-item label="详细地址：" prop="address">
          <el-input
            type="textarea"
            v-model="form.address"
            :rows="5"
            :maxlength="100"
            placeholder="请输入详细地址，如道路，门牌号，校区，楼栋数，单元等"
            style="width: 450px;"
          ></el-input>
          <char-count :text="form.address" :max="100"></char-count>
        </el-form-item>
        <el-form-item label="邮政编码：" prop="zip_code">
          <el-input
            type="number"
            v-model="form.zip_code"
            placeholder="请输入邮政编码"
            style="width:200px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人姓名：" prop="user_name">
          <el-input v-model="form.user_name" placeholder="请输入收货人姓名" maxlength="25" style="width:200px;"></el-input>
          <char-count :text="form.user_name" :max="25"></char-count>
        </el-form-item>
        <el-form-item label="手机号码：" prop="mobile">
          <el-input type="number" v-model="form.mobile" placeholder="请输入手机号码" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox label="设为默认地址" v-model="form.is_default" true-label="1" false-label="2"></el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dlg-footer">
        <el-button type="primary" @click="onDlgOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CharCount from "@/components/CharCount";
import Empty from "@/components/Empty";
import AreaSelect from "@/components/AreaSelect";
export default {
  name: "member-address",
  components: {
    CharCount,
    Empty,
    AreaSelect
  },
  data() {
    const validateArea = (rule, value, callback) => {
      if (
        !this.form.province_id ||
        !this.form.city_id ||
        !this.form.district_id
      ) {
        return callback(new Error("请选择完整的地址信息"));
      }
      callback();
    };
    const validateZipcode = (rule, value, callback) => {
      if (!value) return callback();
      if (!/^[1-9][0-9]{5}$/.test(value)) {
        return callback(new Error("邮政编码格式不正确"));
      }
      callback();
    };
    const validateMobile = (rule, value, callback) => {
      if (!value) return callback(new Error("请输入手机号"));
      if (!/^1[3456789]\d{9}$/.test(value)) {
        return callback(new Error("手机号格式不正确"));
      }
      callback();
    };
    return {
      areas: {
        lazy: true,
        lazyLoad: (node, resolve) => {
          const id = node.level == 0 ? 0 : node.value;
          this.$http.get(`/api/app/sonArea/${id}`).then(data => {
            const nodes = [];
            data.forEach(i => {
              nodes.push({
                value: i.id,
                label: i.area_name,
                leaf: node.level == 2
              });
            });
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          });
        }
      },
      form: {
        province_id: "",
        city_id: "",
        district_id: "",
        address: "",
        zip_code: "",
        user_name: "",
        mobile: "",
        is_default: "2",
        init() {
          this.province_id = "";
          this.city_id = "";
          this.district_id = "";
          this.address = "";
          this.zip_code = "";
          this.user_name = "";
          this.mobile = "";
          this.is_default = "2";
        }
      },
      formRules: {
        area: [{ required: true, validator: validateArea, trigger: "blur" }],
        address: [
          { required: true, message: "请填写详细地址", trigger: "blur" }
        ],
        zip_code: [{ validator: validateZipcode, trigger: "blur" }],
        user_name: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" }
        ],
        mobile: [{ required: true, validator: validateMobile, trigger: "blur" }]
      },
      list: [],
      dlg: {
        show: false,
        title: "",
        mode: "",
        id: "" // 编辑时候用于记录id
      }
    };
  },
  created() {
    this.requestList();
  },
  methods: {
    // on event
    onShowAddDlg() {
      this.form.init();
      this.dlg.show = true;
      this.dlg.title = "添加地址";
      this.dlg.mode = "add";
    },
    onAreaChange(val) {
      this.form.province_id = val.provinceId;
      this.form.city_id = val.cityId;
      this.form.district_id = val.districtId;
    },
    onDlgOk() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        if (this.dlg.mode == "add") {
          this.$http.post("/api/app/address", this.form).then(() => {
            this.$success("新增成功", {
              duration: 1500
            });
            this.requestList();
            this.dlg.show = false;
          });
        } else if (this.dlg.mode == "modify") {
          this.$http
            .put(`/api/app/address/${this.dlg.id}`, this.form)
            .then(() => {
              this.$success("修改成功", {
                duration: 1500
              });
              this.requestList();
              this.dlg.show = false;
            });
        }
      });
    },
    onModify(item) {
      this.dlg.show = true;
      this.title = "修改地址";
      this.dlg.mode = "modify";
      this.dlg.id = item.id;
      this.form.province_id = item.province_id;
      this.form.city_id = item.city_id;
      this.form.district_id = item.district_id;
      this.form.address = item.address;
      this.form.zip_code = item.zip_code;
      this.form.user_name = item.user_name;
      this.form.mobile = item.mobile;
      this.form.is_default = item.is_default + "";
    },
    async onDelete(item) {
      await this.$promiseConfirm("确定要删除该收货地址吗？", {
        type: "warning"
      });
      this.$http.delete(`/api/app/address/${item.id}`).then(() => {
        this.$success("删除成功", {
          duration: 1500
        });
        this.requestList();
      });
    },
    onDefault(item) {
      this.$http.put(`/api/app/address/status/${item.id}`).then(() => {
        this.$success("设置成功", {
          duration: 1500
        });
        this.requestList();
      });
    },
    // request
    requestList() {
      this.$http.get("/api/app/address").then(data => {
        this.list = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.member-address {
  background-color: #f0f2f5;
  .list-wrapper {
    padding: 24px;
    margin: 20px 0;
    background-color: #fff;
    .el-row .el-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      padding-bottom: 15px;
      border-bottom: 1px solid #e8e8e8;
      font-size: 14px;
      font-weight: 500;
      color: #262626;
    }
    .item {
      font-size: 14px;
      border: 1px solid #e1e1e1;
      position: relative;
      margin: 15px 0;
      &:last-child {
        margin: 0;
      }
      .el-col {
        min-height: 120px;
        border-right: 1px solid #e1e1e1;
        padding: 10px;
        &:last-child {
          border-right: none;
        }
      }
      .action {
        flex-direction: column;
      }
      .btn {
        font-size: 15px;
        color: $primary-color;
        cursor: pointer;
      }
      .default {
        font-size: 15px;
        color: #8c8c8c;
        .tag-default {
          background-color: #f4f4f4;
          padding: 2px 8px;
          border-radius: 4px;
        }
      }
    }
  }
  .dlg {
    /deep/ .el-dialog__body {
      padding-bottom: 0;
    }
  }
}
</style>