<template>
  <div>
    <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{'tab-item-current':current==item.type}"
        @click="changeTab(item.type)"
        v-for="item in types"
        :key="item.type"
      >
        <span>{{item.label}}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <div class="tab-content">
      <div v-show="current == 1" class="contract">
        <img class="icon" src="@/assets/images/info@2x.png" />
        <router-link :to="{name:'Contract',query:{key:'coupon_use_contract'}}">使用规则说明</router-link>
      </div>
      <el-tabs v-if="current==1" v-model="currentCouponType" @tab-click="changeCouponType">
        <el-tab-pane v-for="(value,key) in couponStatus" :label="value" :name="key" :key="key">
          <ul class="clear coupons" v-if="couponList.length">
            <li v-for="(item,index) in couponList" :key="index" :class="{'valid':item.status==0}">
              <div class="f-left">
                <div class="price">
                  <Currency class="currency"></Currency>
                  <span class="discounted_price">{{item.discounted_price|price}}</span>
                  <span class="full_reduction">满{{item.full_reduction|price}}可用</span>
                </div>
                <div class="bottom">
                  <div
                    class="scope"
                    v-if="item.dis_type==1"
                  >{{item.scope_type==1?'全部课程':item.scope_type==2?'指定课程分类':'指定课程'}}可用</div>
                  <div
                    class="scope"
                    v-else-if="item.dis_type==4"
                  >{{item.scope_type==1?'全部约课':item.scope_type==2?'指定讲师':''}}</div>
                  <div
                    class="scope"
                    v-else-if="item.dis_type==2"
                  >{{item.scope_type==1?'全部图书':item.scope_type==2?'指定图书分类':'指定图书'}}</div>
                  <div class="time">有效期：{{item.valid_start}}-{{item.valid_end}}</div>
                </div>
              </div>
              <div class="f-right">
                <a @click="goToUsePage(item)" v-if="item.status==0">立即使用</a>
                <span v-else-if="item.status==1">已使用</span>
                <span v-else-if="item.status==2">已过期</span>
              </div>
            </li>
          </ul>
          <div v-else class="center" style="padding:20px 0;">暂无优惠券</div>
        </el-tab-pane>
      </el-tabs>
      <div v-if="current==2">
        <div class="clear">
          <span class="f-left title" v-if="cardRecords.length">兑换记录</span>
          <el-button class="f-right" type="warning" @click="beginCard">学习卡兑换</el-button>
        </div>
        <ul class="records" v-if="cardRecords.length">
          <li v-for="record in cardRecords" :key="record.study_card_id">
            <div class="f-left left">
              <div class="point"></div>
              <div class="line"></div>
            </div>
            <div class="f-left">
              <div class="time">兑换时间：{{new Date(record.used_at*1000).format("yyyy-MM-dd hh:mm:ss")}}</div>
              <div class="name">商品内容：{{record.title}}</div>
              <div class="number">卡号：{{record.card_number}}</div>
            </div>
            <div style="clear:both;"></div>
          </li>
        </ul>
        <div v-else class="center" style="padding:20px 0;">暂无兑换记录</div>
      </div>
    </div>
    <el-dialog title="学习卡兑换" :visible.sync="showDialog" width="500px">
      <div class="card-form-item">
        <label>卡号</label>
        <el-input v-model="card_number" placeholder="请输入卡号"></el-input>
      </div>
      <div class="card-form-item">
        <label>密码</label>
        <el-input v-model="card_pwd" placeholder="请输入密码"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitCard">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "MemberWealth",
  data() {
    return {
      current: "1",
      types: [
        {
          type: "1",
          label: "优惠券"
        }
      ],
      currentCouponType: "0",
      couponStatus: {
        "0": "未使用",
        "1": "已使用",
        "2": "已过期"
      },
      couponList: [],
      showDialog: false,
      card_number: "",
      card_pwd: "",
      cardRecords: []
    };
  },
  async created() {
    if (this.$store.state.commonSetting.switch_study_module === 1) {
      this.types.push({
        type: "2",
        label: "学习卡"
      });
    }
    await this.getCoupon();
    await this.getCardRecord();
  },
  methods: {
    changeTab(tab) {
      this.current = tab;
    },
    async getCoupon() {
      this.couponList = await this.$http.get("/api/app/coupon", {
        status: this.currentCouponType
      });
    },
    async getCardRecord() {
      this.cardRecords = await this.$http.post("/api/app/myStudy");
    },
    async changeCouponType() {
      await this.getCoupon();
    },
    beginCard() {
      this.showDialog = true;
      this.card_number = "";
      this.card_pwd = "";
    },
    async submitCard() {
      if (this.card_number == "") {
        await this.$info("请输入卡号");
        return;
      }
      if (this.card_pwd == "") {
        await this.$info("请输入密码");
        return;
      }
      await this.$http.post("/api/app/checkout/studyCard", {
        card_number: this.card_number,
        card_pwd: this.card_pwd
      });
      this.$success("兑换成功");
      this.showDialog = false;
      await this.getCardRecord();
    },
    goToUsePage(item) {
      if (item.status != 0) {
        return;
      }
      if (item.dis_type == 1) {
        if (item.scope_type == 1) {
          this.$router.push({ name: "Course" });
        } else {
          this.$router.push({ name: "CourseCoupon", query: { id: item.id } });
        }
      } else if (item.dis_type == 2) {
        if (item.scope_type == 1) {
          this.$router.push({ name: "Book" });
        } else {
          this.$router.push({ name: "BookCoupon", query: { id: item.id } });
        }
      } else if (item.dis_type == 4) {
        if (item.scope_type == 1) {
          this.$router.push({ name: "Oto" });
        } else {
          this.$router.push({ name: "OtoCoupon", query: { id: item.id } });
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.tab-item span {
  border-right: none;
}
.tab-content {
  background-color: white;
  padding: 24px;
  position: relative;
  .contract {
    position: absolute;
    z-index: 999999;
    top: 30px;
    right: 25px;
    .icon {
      width: 13px;
    }
    * {
      vertical-align: middle;
    }
    a {
      font-size: 14px;
      color: #8c8c8c;
      margin-left: 4px;
    }
  }
}
.coupons {
  padding-left: 20px;
  li {
    width: 384px;
    height: 148px;
    float: left;
    margin-right: 60px;
    margin-top: 41px;
    color: #b7b7b7;
    position: relative;
    > .f-left {
      box-sizing: border-box;
      padding-left: 15px;
      width: 302px;
      height: 100%;
      background-image: url(../../images/member/coupon-invalid-left@3x.png);
      background-size: 302px 148px;
      background-repeat: no-repeat;
    }
    > .f-right {
      width: 82px;
      height: 100%;
      background-image: url(../../images/member/coupon-invalid-right@3x.png);
      background-size: 82px 148px;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 22px;
        writing-mode: vertical-lr;
        color: #b7b7b7;
      }
      a {
        cursor: pointer;
      }
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.valid {
      color: #e14641;
      > .f-left {
        background-image: url(../../images/member/coupon-left@3x.png);
      }
      > .f-right {
        background-image: url(../../images/member/coupon-right@3x.png);
        a {
          color: white;
          font-size: 22px;
          writing-mode: vertical-lr;
        }
      }
      .bottom {
        .scope {
          font-size: 15px;
          font-weight: 500;
          color: #595959;
        }
        .time {
          font-size: 13px;
          font-weight: 500;
          color: #595959;
        }
      }
    }
    .price {
      padding-top: 5px;
    }
    .currency {
      font-size: 12px;
      width: 12px;
    }
    .discounted_price {
      font-size: 29px;
      margin: 0 5px;
    }
    .bottom {
      position: absolute;
      left: 15px;
      bottom: 11px;
      .scope {
        font-size: 15px;
        margin-bottom: 6px;
      }
      .time {
        font-size: 13px;
      }
    }
    .right {
      position: absolute;
      width: 116px;
      right: 0;
      text-align: center;
      font-size: 20px;
      top: 61px;
    }
  }
}
.card-form-item {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  label {
    width: 80px;
  }
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(89, 89, 89, 1);
}
.records {
  li {
    padding-bottom: 34px;
    font-size: 14px;
    color: rgba(89, 89, 89, 1);
    position: relative;
    .left {
      margin-right: 12px;
    }
    .point {
      width: 6px;
      height: 6px;
      background-color: $primary-color;
      border-radius: 100%;
      margin-top: 5px;
    }
    .line {
      width: 2px;
      background-color: #e9e9e9;
      height: 98px;
      top: 11px;
      position: absolute;
      left: 2px;
    }
    .time {
      margin-bottom: 8px;
    }
    .name {
      margin-bottom: 4px;
    }
  }
}
</style>