<template>
  <div>
    <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{ 'tab-item-current': current == item.type }"
        @click="changeTab(item.type)"
        v-for="item in types"
        :key="item.type"
      >
        <span>{{ item.label }}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <ul class="tab-content message" v-if="list.length">
      <li
        class="item"
        v-for="item in list"
        :key="item.id"
        @click="messageClick(item)"
      >
        <img
          src="../../images/member/message-course@3x.png"
          v-if="item.message_classify == 'course'"
        />
        <img
          src="../../images/member/message-oto@3x.png"
          v-else-if="item.message_classify == 'oto'"
        />
        <img
          src="../../images/member/message-order@3x.png"
          v-else-if="item.message_classify == 'order'"
        />
        <img
          src="../../images/member/message-system@3x.png"
          v-else-if="item.message_classify == 'system'"
        />
        <img
          src="../../images/member/message-exam@3x.png"
          v-else-if="item.message_classify == 'exam'"
        />
        <div class="f-left">
          <div class="title clear">
            <div class="f-left">{{ item.title }}</div>
            <div class="f-right">
              {{ item.created_at | tsFormat("yyyy-MM-dd hh:mm:ss") }}
            </div>
          </div>
          <span class="content" v-html="item.content"></span>
          <a
            style="margin-left: 10px"
            v-if="
              item.link_type == 1 &&
              parseInt(item.created_at) * 1000 + 30 * 60 * 1000 >
                new Date().getTime()
            "
            >立即支付</a
          >
          <a
            style="margin-left: 10px"
            v-if="
              item.link_type != 1 &&
              item.course_type &&
              item.message_classify == 'ask'
            "
            >点击查看</a
          >
          <!-- <div class="content"
               v-html="item.content+(item.link_type==1&&(parseInt(item.created_at) * 1000 + 30 * 60 * 1000 > new Date().getTime())?'<a>立即支付</a>':'') + (item.course_type&&item.message_classify=='ask' ?'<a>点击查看</a>':'')"></div> -->
        </div>
      </li>
    </ul>
    <div v-else class="center" style="padding: 60px 0">暂无消息</div>
    <el-pagination
      v-if="total > 10"
      class="center"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "MemberMessage",
  data() {
    return {
      current: "all",
      types: [
        {
          type: "all",
          label: "全部消息",
        },
        {
          type: "course",
          label: "课程消息",
        },
        // {
        //   type: "oto",
        //   label: "约课消息"
        // },
        {
          type: "order",
          label: "订单消息",
        },
        {
          type: "system",
          label: "系统消息",
        },
        {
          type: "ask",
          label: "问答消息",
        },
        // {
        //   type: "exam",
        //   label: "考试消息"
        // }
      ],
      list: [],
      limit: 10,
      page: 1,
      total: 0,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      let data = await this.$http.post("/api/app/message/getMessage", {
        message_classify: this.current,
        limit: this.limit,
        page: this.page,
      });
      data.list.forEach((m) => {
        if (
          m.link_type == 1 &&
          parseInt(m.created_at) * 1000 + 30 * 60 * 1000 < new Date().getTime()
        ) {
          m.content = m.content.replace("点击支付", "<a>点击支付</a>");
        }
      });
      this.list = data.list;
      this.total = data.total;
      this.$store.dispatch("refreshMessageState");
    },
    async changeTab(tab) {
      this.current = tab;
      this.page = 1;
      await this.getData();
    },
    async changePage(page) {
      this.page = page;
      await this.getData();
    },
    async messageClick(item) {
      if (item.link_type == 1) {
        let order = await this.$http.post("/api/app/myOrder/detail", {
          order_id: item.order_id,
        });
        if (order.order_status == 2) {
          await this.$info("该订单交易已完成");
        } else if (order.order_status == 3) {
          await this.$info("该订单交易已关闭");
        } else {
          if (order.pay_status == 1) {
            this.$router.push({
              name: "OrderPay",
              params: { id: item.order_id },
              query: { type: item.shop_type },
            });
          }
        }
      } else {
        if (item.message_classify != "ask") return;
        if (
          item.course_type == 1 ||
          item.course_type == 4 ||
          item.course_type == 2
        ) {
          //单独问答模块
          this.$router.push({
            name: "QuestionShow",
            params: { id: item.order_id },
          });
        } else if (item.course_type == 3) {
          //题目问答
          this.$router.push({
            name: "MemberQuestionExamDetail",
            query: { id: item.package_id, preview: 1 },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-item {
  width: 152px;
}
.tab-content {
  padding: 24px;
  background-color: white;
}
.item {
  padding: 24px;
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 20px;
  overflow: hidden;
  > div {
    width: 750px;
  }
  img {
    width: 44px;
    float: left;
    margin-right: 22px;
    margin-top: 6px;
  }
  .title {
    font-size: 16px;
    color: rgba(183, 183, 183, 1);
    margin-bottom: 10px;
  }
  .content {
    font-size: 18px;
    color: rgba(89, 89, 89, 1);
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: 0px 0px 16px 0px rgba(45, 57, 187, 0.16);
  }
}
/deep/ .el-pagination {
  margin: 0;
  padding-bottom: 24px;
}
</style>
<style lang="scss">
@import "@/assets/css/varibles.scss";
.message a {
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;
}
</style>
