<template>
  <div class="chapter-content">
    <div
      class="period-header clear"
      :class="{ 'period-header-7': course_type == 7 }"
      @click="targetPeriod(period)"
    >
      <div class="period-title f-left">
        <span class="period-point"></span>
        <span class="period-try" v-if="period.is_try_see">试看</span>
        <span
          v-if="course_type == 5 || course_type == 8"
          style="cursor: pointer"
          :title="period.periods_title"
        >
          {{ period.periods_title }}
        </span>
        <span
          v-else-if="course_type == 10"
          :title="period.periods_title"
          style="cursor: pointer"
        >
          {{ period.periods_title }}
        </span>
        <span v-else :title="period.periods_title">
          {{ period.periods_title }}
        </span>
      </div>
      <div class="f-right period-state" v-if="course_type != 10">
        <span class="live-state" v-if="course_type == 2 || course_type == 3">
          <span v-if="period.play_type == 1" class="operate">进入直播</span>
          <span v-if="period.play_type == 2" class="period-living">
            <img src="@/assets/images/course/live.png" />
            <span class="operate">进入直播</span>
          </span>
          <span v-if="period.play_type == 3" class="operate">暂无回放</span>
          <span v-if="period.play_type == 4" class="operate">查看回放</span>
        </span>
        <span v-if="course_type == 7">
          <span v-if="period.play_type == 1">未开始</span>
          <span v-else-if="period.play_type == 2">开课中</span>
          <span v-else-if="period.play_type == 3">已结束</span>
        </span>
        <span class="progress-wrap" v-if="course_type != 7">
          <RotateProgress
            class="progress"
            :progress="period.progress_rate"
            :size="14"
          >
          </RotateProgress>
          <span>{{ period.progress_rate }}%</span>
        </span>
      </div>
      <div
        class="f-right period-teacher"
        v-if="course_type != 5 && course_type != 8 && course_type != 10"
        :title="
          period.teacher_name +
          (period.start_play ? period.start_play + ' - ' + period.end_play : '')
        "
      >
        {{ period.teacher_name }}
        <span v-if="period.start_play">
          {{ period.start_play + " - " + period.end_play }}
        </span>
      </div>
    </div>
    <div
      v-for="(file, index) in period.files"
      class="period-file"
      :key="file.file_url"
    >
      <img src="@/assets/images/doc.png" class="file-type" />
      <a href="javascript:void(0)" @click="visitFile(file.datum_id, index + 1)">
        {{ file.file_name }}
      </a>
    </div>
    <div
      class="clear homework"
      v-if="
        period.homework_name &&
        $store.state.commonSetting.homework_module.is_open == '1'
      "
    >
      <router-link
        class="f-left"
        :to="{
          name: 'MemberCourseHomework',
          query: { course: course_id, period: period.period_id },
        }"
      >
        {{
          period.homework_name +
          (period.homework_num > 1
            ? " 等" + period.homework_num + "个作业"
            : "")
        }}
      </router-link>
      <router-link
        class="f-right operate"
        :to="{
          name: 'MemberCourseHomework',
          query: {
            course: course_id,
            period: period.period_id,
            title: period.periods_title,
          },
        }"
        v-if="period.is_submit"
      >
        作业提交
      </router-link>
      <router-link
        class="f-right operate"
        :to="{
          name: 'MemberCourseHomework',
          query: {
            course: course_id,
            period: period.period_id,
            title: period.periods_title,
          },
        }"
        v-else-if="period.is_view"
      >
        查看作业
      </router-link>
    </div>
    <el-dialog
      title="查看课时内容"
      :visible.sync="showContentDialog"
      :center="true"
      width="700px"
    >
      <div class="rich-text-img-width" v-html="chapter_content"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showContentDialog = false">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import RotateProgress from "@/components/RotateProgress.vue";
export default {
  name: "MemberStudyPeriod",
  components: {
    RotateProgress,
  },
  props: {
    period: {
      type: Object,
    },
    course_type: {
      type: Number,
    },
    course_id: {
      type: Number,
    },
  },
  data() {
    return {
      showContentDialog: false,
      chapter_content: "",
    };
  },
  created() {},
  methods: {
    async targetVideo(period) {
      console.log(period);
      let course_type = this.course_type;
      if (course_type == 2 || course_type == 3) {
        if (period.mock_live_url) {
          const url = period.mock_live_url.replace("http://", "https://");
          this.$router.push({
            name: "Video",
            query: {
              course_id: this.course_id,
              period_id: period.id,
              video_id: period.video_id,
              course_type: this.course_type,
              id: period.id,
              parent_id: period.parent_id,
              mlurl: url,
            },
          });

          return;
        }

        if (period.cloud_class_type === "plaso") {
          const meetingId = `${period.course_basis_id}_${period.id}`;
          const routeData = this.$router.resolve({
            name: "CloudClass",
            query: { meetingId },
          });
          window.open(routeData.href, "_blank");

          return;
        }

        this.$livePlay(this.course_id, this.course_type, period.id);
      } else if (course_type == 5 || course_type == 8) {
        this.$router.push({
          name: "Video",
          query: {
            course_id: this.course_id,
            period_id: period.id,
            video_id: period.video_id,
            course_type: this.course_type,
            id: period.id,
            parent_id: period.parent_id,
          },
        });
      }
    },
    async visitFile(id, index) {
      let data = await this.$http.get(
        "/api/app/chapterDatum/" + id + "/" + index
      );
      window.open(data.file_url);
    },
    async targetContent(id) {
      let data = await this.$http.get("/api/app/chapterInfo/" + id);
      this.chapter_content = data.course_content;
      this.showContentDialog = true;
    },
    async targetPeriod(period) {
      if (this.course_type == 10) {
        await this.targetContent(period.id);
      } else {
        await this.targetVideo(period);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/varibles.scss";
.chapter-content {
  display: none;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  .period-header:not(.period-header-7) {
    cursor: pointer;
    &:hover {
      .period-title,
      .period-teacher,
      .period-state {
        color: $primary-color;
      }
    }
  }
  .period-title {
    font-size: 16px;
    color: #595959;
    margin-left: 31px;
    width: 210px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 2px;
  }
  .period-try {
    background-color: $primary-color;
    color: white;
    font-size: 12px;
    padding: 2px 10px;
    margin-right: 5px;
  }
  .period-point {
    width: 5px;
    height: 5px;
    background-color: $primary-color;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 18px;
  }
  .period-file a {
    font-size: 14px;
    color: rgba(89, 89, 89, 1);
    &:hover {
      color: $primary-color;
    }
  }
  .chapter-expand .chapter-content {
    display: block;
  }
  .period-file {
    padding-left: 55px;
    margin-top: 8px;
  }
  .operate {
    width: 76px;
    height: 26px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px solid $primary-color;
    text-align: center;
    line-height: 26px;
    color: $primary-color !important;
    display: inline-block;
    &:hover {
      background-color: $primary-color;
      color: white !important;
    }
  }
  .homework {
    padding-left: 55px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    color: rgba(89, 89, 89, 1);
    padding-top: 8px;
    a:first-child {
      color: rgba(89, 89, 89, 1);
      margin-top: 4px;
      &:hover {
        color: $primary-color;
      }
    }
    .operate {
      margin-right: 80px;
    }
  }
  .file-type {
    margin-right: 5px;
  }
  .period-teacher {
    font-size: 14px;
    color: rgba(183, 183, 183, 1);
    width: 322px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 4px;
    margin-right: 50px;
  }
  .period-state {
    color: rgba(183, 183, 183, 1);
    width: 170px;
    text-align: right;
    font-size: 14px;
    .progress-wrap {
      width: 80px;
      display: inline-block;
    }
    .progress {
      margin-left: 30px;
      margin-right: 10px;
      vertical-align: text-bottom;
    }
  }
  .live-state {
    cursor: pointer;
  }
  .period-living {
    color: $primary-color;
    img {
      margin-right: 5px;
    }
  }
}
</style>
