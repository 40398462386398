<template>
  <div>
    <div class="tab-content">
      <div class="courses" v-if="exercises.length">
        <div class="nav nav-left" @click="navLeft"></div>
        <div class="nav nav-right" @click="navRight"></div>
        <div style="overflow:hidden;">
          <div
            class="item-container clear"
            :style="{width:exercises.length*400+'px',marginLeft:-navLeftIndex*348+'px'}"
          >
            <div
              class="item exercise"
              :class="{'selected':item.id==currentExercise.id}"
              v-for="(item,index) in exercises"
              :key="index"
              @click="changeCourse(index)"
            >
              <div class="content">
                <div class="title">
                  <span>{{item.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>
      </div>
      <Empty v-else></Empty>
      <div class="detail" v-if="detail.list&&detail.list.length > 0">
        <div class="chapters" v-if="detail.list">
          <div
            class="chapter"
            :class="{'expand':chapterExpand[chapter.id]==1}"
            v-for="chapter in detail.list"
            :key="chapter.id"
          >
            <div class="clear chapter-header" @click="toggleExpand(chapter.id)">
              <div class="flag f-left"></div>
              <div class="title f-left">{{chapter.title}}</div>
              <div class="title chapter-qustion-num f-right">共{{chapter.question_count || 0}}道</div>
            </div>
            <div class="periods" v-if="chapterExpand[chapter.id]==1&&chapter.children&&chapter.children.length">
              <div class="clear periods-header" v-for="child in chapter.children" @click="goToExam(child.id)">
                <div class="periods-title f-left">{{child.title}}</div>
                <div class="periods-qustion-num f-right">共{{child.question_num || 0}}道 <i class="el-icon-edit-outline"></i></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MemberStudyPeriod from "../../components/MemberStudyPeriod.vue";

export default {
  name: "MemberExercise",
  components: {
    MemberStudyPeriod
  },
  data() {
    return {
      user: {},
      exercises: [],
      currentExercise: {},
      currentIndex: 0,
      navLeftIndex: 0,
      detail: {},
      chapterExpand: {}
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      let data = await this.$http.get("/api/app/exam/exercise/my",{ limit: 100});

      this.exercises = data.list;
      if (data.list.length > 0) {
        let id = this.$route.query.id;
        let i = 0;
        if (id) {
          for (; i < data.list.length; i++) {
            if (data.list[i].id == id) {
              this.currentExercise = data.list[i];
              this.navLeftIndex = i;
              this.currentIndex = i;
              break;
            }
          }
        }
        if (!id || i == data.list.length) {
          this.currentExercise = data.list[0];
          this.navLeftIndex = 0;
          this.currentIndex = 0;
        }
      } else {
        this.currentExercise = {};
        this.navLeftIndex = 0;
        this.currentIndex = 0;
      }
    },
    async changeCourse(index) {
      this.currentExercise = this.exercises[index];
      this.currentIndex = index;
      if (
        this.currentIndex >= this.navLeftIndex + 2 &&
        this.currentIndex < this.exercises.length
      ) {
        this.navLeftIndex++;
      }
      if (this.currentIndex == this.navLeftIndex && this.currentIndex > 0) {
        this.navLeftIndex--;
      }
    },
    navLeft() {
      if (this.navLeftIndex == 0) {
        return;
      }
      this.navLeftIndex--;
    },
    navRight() {
      if (this.navLeftIndex >= this.exercises.length - 1) {
        return;
      }
      this.navLeftIndex++;
    },
    async getExerciseDetail() {
      if (!this.currentExercise.id) {
        this.detail = {};
        return;
      }
      this.detail = await this.$http.get(
        "/api/app/exercise-chapter?exercise_id=" + this.currentExercise.id
      );
      let chapterExpand = {};
      if (this.detail.list) {
        this.detail.list.forEach(c => {
          chapterExpand[c.id] = 1;
          if (!c.children) {
            return;
          }

        });
      }
      this.chapterExpand = chapterExpand;
    },

    toggleExpand(id) {
      if (this.chapterExpand[id] == 0) {
        this.chapterExpand[id] = 1;
      } else {
        this.chapterExpand[id] = 0;
      }
    },

    //去考试
    goToExam(id) {
      this.$router.push({
        name: "ExamExerciseDo",
        params: { },
        query: { id }
      });
    }
  },
  watch: {
    async currentExercise() {
      await this.getExerciseDetail();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/member-study.scss";
.courses .nav{
  top: 35px;
}
</style>
