<template>
  <div class="member-evaluation-mistake">
    <div class="total">
      <div ref="pie" class="pie"></div>
      <div class="pie-center">
        <p>{{count.kaodian + count.taojuan + count.fangzhen}}</p>
        <p>错题数</p>
      </div>
      <div class="count">
        <div class="count-item">
          <p class="count-item-number color-kaodian">{{count.kaodian}}</p>
          <p class="count-item-name">{{label.kaodian}}</p>
        </div>
        <div class="count-item">
          <p class="count-item-number color-taojuan">{{count.taojuan}}</p>
          <p class="count-item-name">{{label.taojuan}}</p>
        </div>
        <div class="count-item">
          <p class="count-item-number color-fangzhen">{{count.fangzhen}}</p>
          <p class="count-item-name">{{label.fangzhen}}</p>
        </div>
      </div>
    </div>
    <div class="detail">
      <el-tabs @tab-click="onClickTab" value="kaodian">
        <el-tab-pane :label="label.kaodian" name="kaodian">
          <el-form inline>
            <el-form-item>
              <el-date-picker
                v-model="datetimeRange"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              ></el-date-picker>
            </el-form-item>
            <el-form-item style="float:right;">
              <el-input
                v-model="searchCondition.keywords"
                placeholder="请输入考点名称关键字"
                @keydown.native.prevent.enter="onSearch"
                clearable
              >
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="label.taojuan" name="taojuan">
          <el-form inline>
            <el-form-item>
              <el-date-picker
                v-model="datetimeRange"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              ></el-date-picker>
            </el-form-item>
            <el-form-item style="float:right;">
              <el-input
                v-model="searchCondition.keywords"
                placeholder="请输入套卷名称关键字"
                @keydown.native.prevent.enter="onSearch"
                clearable
              >
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="label.fangzhen" name="fangzhen">
          <el-form inline>
            <el-form-item>
              <el-select v-model="examType" placeholder="考试类型" clearable>
                <el-option
                  v-for="(item, index) in examTypes"
                  :key="index"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="datetimeRange"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                style="width:390px;"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchCondition.keywords"
                placeholder="请输入考试名称关键字"
                @keydown.native.prevent.enter="onSearch"
                clearable
              >
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <Grid
        ref="grid"
        url="/api/app/myExam/errorRecord"
        method="post"
        :search="searchCondition"
        :columns="tableColumns"
        :getData="onGetData"
        :formatter="onFormatter"
      >
        <template slot-scope="{row}" slot="action">
          <el-button type="text" @click="onClickConsolidate(row)">巩固错题</el-button>
        </template>
        <div class="empty" slot="empty">
          <img src="~@/assets/images/empty@3x.png" alt />
          <p>暂无错题数据</p>
        </div>
      </Grid>
      <div class="setting">
        <el-button type="text" @click="onClickConsolidate('all')">巩固全部错题</el-button>
        <el-dropdown
          ref="autoDelete"
          :hide-on-click="false"
          trigger="click"
          @visible-change="autoDelete = autoDeleteSwitch"
        >
          <p class="remove">
            <i></i>自动移除
          </p>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-checkbox v-model="autoDelete">做对题目后自动移除该错题</el-checkbox>
              <el-button type="primary" size="small" @click="onSaveAutoDelete">保 存</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import Grid from "@/components/Grid";
import { ExamType } from "@/utils/enums";
export default {
  name: "member-evaluation-mistake",
  components: {
    Grid
  },
  data() {
    return {
      datetimeRange: null,
      label: {
        kaodian: "考点专练",
        taojuan: "套卷练习",
        fangzhen: "仿真模考"
      },
      count: {
        kaodian: 0,
        taojuan: 0,
        fangzhen: 0
      },
      tableColumns: [],
      searchCondition: {
        ques_source: 4,
        keywords: "",
        created_at_start: 0,
        created_at_end: 0,
        init() {
          this.keywords = "";
          this.created_at_start = 0;
          this.created_at_end = 0;
        }
      },
      myEchart: null,
      autoDelete: false, // 用于绑定控件变化
      autoDeleteSwitch: false, // 用于保存真实的开关状态,
      examTypes: ExamType.getOptions(),
      examType: ""
    };
  },
  watch: {
    datetimeRange(val) {
      if (val) {
        this.searchCondition.created_at_start = parseInt(
          val[0].getTime() / 1000
        );
        this.searchCondition.created_at_end = parseInt(val[1].getTime() / 1000);
      } else {
        this.searchCondition.created_at_start = 0;
        this.searchCondition.created_at_end = 0;
      }
    },
    examType(val) {
      this.searchCondition.ques_source = val || 5;
    }
  },
  created() {
    this.initTableColumns();
  },
  mounted() {
    this.initEchart();
  },
  methods: {
    // on event
    onClickTab(tab) {
      this.searchCondition.init();
      this.initTableColumns(tab.name);
      this.onSearch();
    },
    onClickConsolidate(row) {
      this.$router.push({
        name: "Consolidate",
        query: {
          paper_id: row === 'all' ? -1 : row.paper_id,
          auto: this.autoDeleteSwitch,
          created_at: row === 'all' ? -1 : parseInt(new Date(row.created_at).getTime() / 1000)
        }
      });
    },
    onSearch() {
      this.$refs.grid.searchRefresh();
    },
    onGetData(data) {
      this.count.kaodian =
        (data.statistics && data.statistics.exam_practise_num) || 0;
      this.count.taojuan =
        (data.statistics && data.statistics.exam_paper_num) || 0;
      this.count.fangzhen =
        (data.statistics && data.statistics.exam_simulation_num) || 0;
      this.setEchart();
      this.autoDelete = !!data.switch;
      this.autoDeleteSwitch = !!data.switch;
    },
    onFormatter(data) {
      data.forEach(i => {
        i.examTypeName = ExamType.getValue(i.ques_source);
      });
    },
    onSaveAutoDelete() {
      this.$http
        .get(`/api/app/myExam/autoDetach/${this.autoDelete ? 1 : 0}`)
        .then(() => {
          this.$message.success("设置成功");
          this.$refs.autoDelete.hide();
          this.autoDeleteSwitch = this.autoDelete;
        });
    },
    // other
    initEchart() {
      this.myEchart = echarts.init(this.$refs.pie);
    },
    setEchart() {
      if (!this.myEchart) return;
      this.myEchart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          right: 10,
          top: "middle",
          data: [this.label.kaodian, this.label.taojuan, this.label.fangzhen]
        },
        series: [
          {
            name: "占比",
            type: "pie",
            center: [140, "50%"],
            radius: ["30%", "70%"],
            label: {
              show: false,
              position: "inside",
              formatter: "{d}%",
              color: "#fff",
              fontSize: 16
            },
            data: [
              {
                value: this.count.kaodian,
                name: this.label.kaodian,
                itemStyle: { color: "#FFB05E" }
              },
              {
                value: this.count.taojuan,
                name: this.label.taojuan,
                itemStyle: { color: "#07BEAB" }
              },
              {
                value: this.count.fangzhen,
                name: this.label.fangzhen,
                itemStyle: { color: "#8CEC9E" }
              }
            ]
          }
        ]
      });
    },
    initTableColumns(type = "kaodian") {
      switch (type) {
        case "kaodian":
          this.searchCondition.ques_source = 4;
          this.tableColumns = [
            { label: "考点名称", prop: "name" },
            { label: "错题数", prop: "error_count", align: "center" },
            { label: "提交时间", prop: "created_at", align: "center" },
            { label: "操作", slot: "action", align: "center" }
          ];
          break;
        case "taojuan":
          this.searchCondition.ques_source = 3;
          this.tableColumns = [
            { label: "试卷名称", prop: "name" },
            { label: "错题数", prop: "error_count", align: "center" },
            { label: "提交时间", prop: "created_at", align: "center" },
            { label: "操作", slot: "action", align: "center" }
          ];
          break;
        case "fangzhen":
          this.searchCondition.ques_source = 5;
          this.tableColumns = [
            { label: "考试类型", prop: "examTypeName" },
            { label: "考试名称", prop: "name", align: "center" },
            { label: "错题数", prop: "error_count", align: "center" },
            { label: "提交时间", prop: "created_at", align: "center" },
            { label: "操作", slot: "action", align: "center" }
          ];
          break;
      }
    }
    // request
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.member-evaluation-mistake {
  background-color: #f0f2f5;
  margin-bottom: 50px;
  .color-kaodian {
    color: #ffb05e;
  }
  .color-taojuan {
    color: #07beab;
  }
  .color-fangzhen {
    color: #8cec9e;
  }
  .total {
    background-color: #fff;
    height: 290px;
    position: relative;
    .pie {
      width: 360px;
      height: 290px;
      float: left;
    }
    .pie-center {
      position: absolute;
      top: 103px;
      left: 98px;
      width: 84px;
      height: 84px;
      border-radius: 50%;
      background-color: #e4e5e7;
      color: #666666;
      font-size: 15px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .count {
      position: absolute;
      right: 30px;
      width: 520px;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .count-item {
        width: 142px;
        height: 134px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(238, 238, 238, 1);
        box-shadow: 0px 2px 4px 0px rgba(173, 173, 173, 0.25);
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .count-item-number {
          font-size: 24px;
          text-align: center;
          margin-bottom: 10px;
        }
        .count-item-name {
          font-size: 14px;
          text-align: center;
          color: #666666;
        }
      }
    }
  }
  .detail {
    background-color: #fff;
    margin-top: 30px;
    padding: 10px 20px 50px 20px;
    position: relative;
    .setting {
      position: absolute;
      top: 5px;
      right: 20px;
      display: flex;
      justify-content: space-between;
      width: 200px;
      .remove {
        font-size: 14px;
        color: #909399;
        cursor: pointer;
        i {
          display: inline-block;
          width: 30px;
          height: 40px;
          background: url("~@/assets/images/icon-setting@3x.png") no-repeat
            center;
          background-size: 16px 16px;
        }
        * {
          vertical-align: middle;
        }
      }
    }
  }
  /deep/ .el-input-group__append {
    background-color: $primary_color;
    border: 1px solid $primary_color;
    border-left: 0;
    .el-icon-search {
      font-size: 20px;
      color: #fff;
    }
  }
}
.el-dropdown-menu__item {
  cursor: auto;
  &:hover {
    background-color: #fff;
  }
}
</style>