<template>
  <div class="exams">
    <div class="exam-tabs"></div>
    <div class="exam-my" v-if="exams.length > 0 ">
      <div class="clear exam-item" v-for="(item, index) in exams" :key="index">
        <div class="f-left title">{{ item.name }}</div>
        <div class="f-right button" @click="goToExam(item.id)">
          <button class="btn btn-primary" v-if="item.is_record">重新答题</button>
          <button class="btn btn-default" v-else>开始答题</button>
        </div>
      </div>
    </div>
    <Empty v-else></Empty>
  </div>
</template>
<script>
export default {
  name: "MemberExam",
  components: {
  },
  data() {
    return {
      exams: []
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    //获取我的考试列表
    async getData() {
      let data = await this.$http.get("/api/app/exam/simulation/my",{ limit: 100});
      this.exams = data.list;
    },

    //去考试
    goToExam(id) {
      this.$router.push({
        name: "ExamPackagePracticeDes",
        params: {  },
        query: { id, type: 's' }
      });
    }
  },

  watch: {
  }
};
</script>
<style lang="scss" scoped>
.exams{
  min-height: 1060px;
  .exam-item{
    font-size: 14px;
    color: #666666;
    font-weight: 400;
    margin: 0 25px;
    padding: 23px 0;
    line-height: 38px;
    border-bottom:1px solid #F4F4F4;
  }
  .button{
    .btn{
      width: 100px;
      height: 36px;
      border-radius: 5px;
      line-height: 34px;
      font-size: 14px;
      border-radius: 5px;
      outline: none;
      &.btn-default{
        background-color: #fff;
        color: $primary-color;
        border: 1px solid $primary-color;
      }
      &.btn-primary{
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}
</style>
