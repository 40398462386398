<template>
  <div class="member-integral">
    <!-- <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{'tab-item-current':tab==item.key}"
        @click="changeTab(item.key)"
        v-for="item in tabs"
        :key="item.key"
      >
        <span>{{item.title}}</span>
        <div class="tab-line"></div>
      </li>
    </ul>-->
    <el-tabs v-model="tab">
      <el-tab-pane v-for="(item,key) in tabs" :key="key" :label="item.title" :name="item.key"></el-tab-pane>
    </el-tabs>
    <div class="tab-content">
      <MemberIntegralSurvey v-if="tab=='survey'" @changeTab="changeTab"></MemberIntegralSurvey>
      <MemberIntegralStore v-else-if="tab=='store'"></MemberIntegralStore>
      <MemberIntegralDetail v-else-if="tab=='detail'"></MemberIntegralDetail>
      <MemberIntegralRecord v-else-if="tab=='record'"></MemberIntegralRecord>
    </div>
  </div>
</template>
<script>
import MemberIntegralStore from "./MemberIntegralStore";
import MemberIntegralSurvey from "./MemberIntegralSurvey";
import MemberIntegralDetail from "./MemberIntegralDetail";
import MemberIntegralRecord from "./MemberIntegralRecord";
export default {
  name: "MemberIntegral",
  components: {
    MemberIntegralStore,
    MemberIntegralSurvey,
    MemberIntegralDetail,
    MemberIntegralRecord
  },
  data() {
    return {
      tab: this.$route.query.tab || "survey"
    };
  },
  computed: {
    tabs() {
      return [
        {
          key: "survey",
          title: `${this.$store.state.commonSetting.point_info.point_name ||
            "积分"}概况`
        },
        {
          key: "store",
          title: `${this.$store.state.commonSetting.point_info.point_name ||
            "积分"}商城`
        },
        {
          key: "detail",
          title: `${this.$store.state.commonSetting.point_info.point_name ||
            "积分"}明细`
        },
        {
          key: "record",
          title: "兑换记录"
        }
      ];
    }
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    }
  }
};
</script>
<style lang="scss" scoped>
.tab-content {
  padding: 0 25px;
}
.member-integral {
  /deep/ .el-tabs__nav-wrap {
    padding: 0 30px;
    .el-tabs__item {
      font-size: 16px;
      height: 45px;
      line-height: 45px;
      color: #b7b7b7;
    }
    .is-active {
      color: #20242a !important;
    }
  }
}
</style>