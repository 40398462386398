<template>
  <div>
    <div class="tab-content">
      <span class="problem"
            @click="showProblem=true">常见问题</span>
      <div class="account_info">
        <div class="info">
          <p>账户余额</p>
          <p class="count"><span>￥</span>{{info.balance | toFixPrice}}</p>
          <p class="unit">余额（元）</p>
          <div class="withdraw_btn"
               @click="withdraw">支付宝提现</div>
        </div>
        <div class="list">
          <p>个人账户<span @click="add_account">添加绑定</span></p>
          <ul class="account_list">
            <li v-for="(item,index) in accout_list"
                :key="index">
              <img src="@/assets/images/order/alipay@3x.png"
                   style="vertical-align: middle;width:20px;" />
              支付宝：
              <span class="account_num">{{item.account_no}}</span>
              <span class="unbind"
                    @click="unbind(item)">解绑</span>
            </li>
            <Empty style="padding:0;background-color:transparent" v-if="accout_list.length==0" title="暂无账户" />
          </ul>
        </div>
      </div>
      <el-tabs v-model="currentCouponType"
               @tab-click="changeCouponType">
        <el-tab-pane v-for="(value,key) in accountStatus"
                     :label="value"
                     :name="key"
                     :key="key">
        </el-tab-pane>
      </el-tabs>
      <div v-if="current=='0'">
        <ul class="balance_detail">
          <li v-for="(item,index) in balanceCenter"
              :key="index">
            {{item.remark}}
          </li>
          <Empty  v-if="accout_list.length==0" title="暂无明细" />
        </ul>
      </div>
      <div v-if="current=='1'">
        <el-table :data="transferRecord.list"
                  class="table_wrap"
                  border>
          <el-table-column label="提交时间"
                           align="center">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.create_at }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提现金额"
                           align="center">
            <template slot-scope="scope">
              ￥{{ scope.row.amount/100 }}
            </template>
          </el-table-column>
          <el-table-column label="审核状态"
                           align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status==1">通过</span>
              <span v-if="scope.row.status==2">未通过</span>
              <span v-if="scope.row.status==3">待审核</span>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template slot-scope="scope">
              <span class="look_detail"
                    @click="transferRecordDetail(scope.row)">查看详情</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination background v-if="balanceCenter.length || transferRecord.list.length"
                     :current-page="page"
                     @current-change="handleCurrentChange"
                     small
                     layout="prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
    <el-dialog title="查看详情"
               show-close
               center
               :visible.sync="showDialog"
               width="510px">
      <div class="detail-form-item">
        <label>提交时间：</label>
        <span>{{detail.create_at}}</span>
      </div>
      <div class="detail-form-item">
        <label>提现账户：</label>
        <span>{{detail.account_no}}</span>
      </div>
      <div class="detail-form-item">
        <label>真实姓名：</label>
        <span>{{detail.real_name}}</span>
      </div>
      <div class="detail-form-item">
        <label>提现金额：</label>
        <span>￥{{detail.amount/100}}</span>
      </div>
      <div class="detail-form-item">
        <label>审核状态：</label>
        <span>{{WithdrawStatus.getValue(detail.status)}}</span>
      </div>
      <div class="detail-form-item">
        <label>审核时间：</label>
        <span>{{detail.audit_at}}</span>
      </div>
      <div class="detail-form-item">
        <label>审核原因：</label>
        <span>{{detail.remark}}</span>
      </div>
    </el-dialog>
    <!-- 绑定账户 -->
    <el-dialog title="添加绑定账户"
               center
               :visible.sync="addDialog"
               width="566px">
      <el-form :model="addRuleForm"
               :rules="rules"
               ref="addRuleForm"
               label-width="180px"
               class="demo-ruleForm">
        <el-form-item label="姓名"
                      prop="real_name">
          <el-input v-model="addRuleForm.real_name"></el-input>
        </el-form-item>
        <el-form-item label="支付宝账号"
                      prop="account_no">
          <el-input v-model="addRuleForm.account_no"></el-input>
        </el-form-item>
        <el-form-item label="请输入预留手机号"
                      prop="mobile">
          <el-input v-model="addRuleForm.mobile"></el-input>
        </el-form-item>
        <!-- <el-form-item label="验证码"
                      prop="mobile">
          <el-input v-model="addRuleForm.mobile"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button style="width:150px;margin-left:-80px;"
                     @click="resetAddForm('addRuleForm')">取消</el-button>
          <el-button style="width:150px"
                     type="primary"
                     @click="submitAddForm('addRuleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 解绑 -->
    <el-dialog title="解绑账户"
               center
               :visible.sync="unbindDialog"
               width="566px">
      <el-form :model="unbindRuleForm"
               :rules="rules"
               ref="unbindRuleForm"
               label-width="180px"
               class="demo-ruleForm">
        <el-form-item label="请输入预留手机号："
                      prop="mobile">
          <el-input disabled
                    v-model="unbindRuleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="验证码："
                      prop="sms_code">
          <el-input style="width:186px;"
                    v-model="unbindRuleForm.sms_code"></el-input>
          <el-button :disabled="codeBtn"
                     style="width:123px;margin-left:27px;"
                     @click="get_code"
                     type="primary">{{codeBtnInfo}}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button style="width:150px;margin-left:-80px;"
                     @click="resetAddForm('unbindRuleForm')">取消</el-button>
          <el-button style="width:150px"
                     type="primary"
                     @click="submitAddForm('unbindRuleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 常见问题 -->
    <el-dialog title="常见问题"
               center
               show-close
               :visible.sync="showProblem"
               width="566px">
      <div v-html="problem_content"></div>
    </el-dialog>
    <!-- 提现弹框 -->
    <el-dialog title="提现"
               center
               :visible.sync="showWithdraw"
               width="516px">
      <el-form :model="withdrawRuleForm"
               :rules="rules"
               ref="withdrawRuleForm"
               label-width="100px"
               class="demo-ruleForm">
        <el-form-item label="提现账户："
                      prop="account_id">
          <el-select style="width:100%;"
                     v-model="withdrawRuleForm.account_id"
                     placeholder="请选择">
            <el-option v-for="(item,index) in accout_list"
                       :key="index"
                       :label="item.account_no"
                       :value="item.account_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提现金额："
                      prop="amount">
          <el-input v-model="withdrawRuleForm.amounts"
                    placeholder="请输入提现金额度"></el-input>
        </el-form-item>
        <el-form-item label="可用金额：">
          <span style="color:#D46060;">{{info.balance | toFixPrice}}元</span>
        </el-form-item>
        <el-form-item>
          <el-button style="width:150px;"
                     @click="resetAddForm('withdrawRuleForm')">取消</el-button>
          <el-button style="width:150px;"
                     type="primary"
                     @click="submitAddForm('withdrawRuleForm')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { WithdrawStatus } from "@/utils/enums";

export default {
  name: "MemberAccount",
  data () {
    return {
      WithdrawStatus,
      current: "0",
      currentCouponType: "0",
      accountStatus: {
        "0": "余额明细",
        "1": "提现记录"
      },
      showProblem: false,
      showDialog: false,
      card_number: "",
      card_pwd: "",
      cardRecords: [],
      info: {},
      accout_list: [],
      transferRecord: {
        list: []
      },
      balanceCenter: [],
      total: 0,
      addDialog: false,
      unbindDialog: false,
      showWithdraw: false,
      account_id: '',
      addRuleForm: {
        real_name: '',
        account_no: '',
        mobile: ''
      },
      unbindRuleForm: {
        mobile: '',
        sms_code: ''
      },
      withdrawRuleForm: {
        account_id: '',
        amounts: ''
      },
      codeBtn: false,
      codeBtnInfo: "获取验证码",
      problem_content: '',
      detail: {},
      page: 1,
      rules: {
        real_name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        account_no: [
          { required: true, message: '请输入手机号、邮箱格式的账号', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        sms_code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        account_id: [
          { required: true, message: '请选择账户', trigger: 'change' }
        ],
        amounts: [
          { required: true, message: '请输入提现金额', trigger: 'blur' },
        ]
      }
    };
  },
  async created () {
    await this.getUInfo();
    await this.accoutList();
    await this.getBalanceCenter(1);
    await this.getTransferRecord(1);
    await this.requestProblem();
  },
  methods: {
    async changeCouponType (tab) {
      this.current = tab.index;
      if (this.current == 0) {
        await this.getBalanceCenter(1);
        this.page = 1
      } else {
        await this.getTransferRecord(1)
        this.page = 1;
      }
    },
    // 常见问题
    requestProblem () {
      this.$http.get('/api/app/account/commonProblems').then(data => {
        this.problem_content = data.common_problems;
      })
    },
    //获取余额
    async getUInfo () {
      this.info = await this.$http.get("/api/app/getUCenterInfo");
    },
    //获取绑定账户列表
    async accoutList () {
      await this.$http.get('/api/app/account/info').then(data => {
        this.accout_list = data.list;
        // this.total = data.total;
      })
    },
    //获取余额明细列表
    async getBalanceCenter (page) {
      let params = { page: page,limit: 10 };
      await this.$http.get('/api/app/balanceCenter', params).then(data => {
        this.balanceCenter = data.balanceDetail.list;
        this.total = data.balanceDetail.total;
      })
    },
    //获取提现记录
    async getTransferRecord (page) {
      let params = { page: page,limit: 10 };
      await this.$http.post('/api/app/account/transferRecord', params).then(data => {
        this.transferRecord = data;
        if(this.current == 1){
          this.total = data.total;
        }
      })
    },
    // 添加绑定
    add_account () {
      this.addDialog = true;
    },
    // 解绑
    unbind (item) {
      this.unbindDialog = true;
      this.account_id = item.account_id;
      this.unbindRuleForm.mobile = item.mobile;
    },
    withdraw () {
      this.showWithdraw = true;
    },
    submitAddForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName == 'addRuleForm') {
            this.$http.post('/api/app/account/bind', this.addRuleForm).then(res => {
              this.addDialog = false;
              this.accoutList();
            })
          } else if (formName == 'unbindRuleForm') {
            let params = { account_id: this.account_id, sms_code: this.unbindRuleForm.sms_code }
            this.$http.post(`/api/app/account/unbind`, params).then(() => {
              this.accoutList();
            });
          } else if (formName == 'withdrawRuleForm') {
            this.withdrawRuleForm.amount = this.withdrawRuleForm.amounts * 100;
            this.$http.post('/api/app/transfer', this.withdrawRuleForm).then(data => {
              this.getUInfo();
              this.showWithdraw = false;
              this.resetAddForm('withdrawRuleForm');
            })
          }
        } else {
          return false;
        }
      });
    },
    resetAddForm (formName) {
      this.$refs[formName].resetFields();
      if (formName == 'addRuleForm') {
        this.addDialog = false;
      } else if (formName == 'unbindRuleForm') {
        this.unbindDialog = false;
      } else if (formName == 'withdrawRuleForm') {
        this.showWithdraw = false;
      }
    },
    handleCurrentChange (page) {
      this.page = page;
      if (this.current == 0) {
        this.getBalanceCenter(page)
      } else if (this.current == 1) {
        this.getTransferRecord(page)
      }
    },
    // 查看提现详情
    transferRecordDetail (item) {
      this.detail = item;
      this.showDialog = true;
    },
    get_code () {
      let that = this;
      that.$http.post(
        "/api/app/smsCode",
        {
          mobile: that.unbindRuleForm.mobile,
          sms_type: "login"
        },
        false
      );
      let count = 60;
      that.codeBtn = true;
      let sendTimer = setInterval(function () {
        count--;
        that.codeBtnInfo = "重新发送" + count;
        if (count <= 0) {
          that.codeBtn = false;
          clearInterval(sendTimer);
          that.codeBtnInfo = "获取验证码";
        }
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.tab-item span {
  border-right: none;
}
.problem {
  position: absolute;
  top: -72px;
  right: 25px;
  color: $primary_color;
  cursor: pointer;
}
.tab-content {
  position: relative;
  background-color: white;
  padding: 24px;
  position: relative;
  .account_info {
    display: flex;
    margin-bottom: 37px;
    .info {
      width: 386px;
      height: 270px;
      background-color: rgba(229, 74, 66, 0.04);
      text-align: center;
      font-size: 16px;
      p {
        color: #333333;
        &:nth-child(1) {
          padding: 22px 0 0 28px;
          text-align: left;
        }
      }
      .count {
        font-weight: 500;
        font-family: Adobe Heiti Std R;
        margin-top: 50px;
        font-size: 24px;
        span {
          font-weight: 400;
          font-size: 16px;
        }
      }
      .unit {
        color: #999999;
        margin: 15px 0 30px 0;
      }
      .withdraw_btn {
        background: #2b70df;
        width: 120px;
        height: 34px;
        line-height: 34px;
        border-radius: 3px;
        color: #fff;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .list {
      width: 459px;
      height: 270px;
      background: rgba(59, 148, 226, 0.04);
      margin-left: 26px;
      p {
        color: #333333;
        &:nth-child(1) {
          padding: 22px 0 0 28px;
          text-align: left;
          span {
            display: inline-block;
            margin-left: 17px;
            font-size: 14px;
            color: $primary-color;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      .account_list {
        margin-top: 48px;
        font-size: 16px;
        padding-left: 25px;
        color: #999;
        li {
          margin-top: 20px;
        }
        .account_num {
          color: #333333;
        }
        .unbind {
          float: right;
          text-decoration: underline;
          color: #e54a42;
          margin-right: 110px;
          cursor: pointer;
        }
      }
    }
  }
  .contract {
    position: absolute;
    z-index: 999999;
    top: 30px;
    right: 25px;
    .icon {
      width: 13px;
    }
    * {
      vertical-align: middle;
    }
    a {
      font-size: 14px;
      color: #8c8c8c;
      margin-left: 4px;
    }
  }
}
.coupons {
  padding-left: 20px;
  li {
    width: 384px;
    height: 148px;
    float: left;
    margin-right: 60px;
    margin-top: 41px;
    color: #b7b7b7;
    position: relative;
    > .f-left {
      box-sizing: border-box;
      padding-left: 15px;
      width: 302px;
      height: 100%;
      background-image: url(../../images/member/coupon-invalid-left@3x.png);
      background-size: 302px 148px;
      background-repeat: no-repeat;
    }
    > .f-right {
      width: 82px;
      height: 100%;
      background-image: url(../../images/member/coupon-invalid-right@3x.png);
      background-size: 82px 148px;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 22px;
        writing-mode: vertical-lr;
        color: #b7b7b7;
      }
      a {
        cursor: pointer;
      }
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
    &.valid {
      color: #e14641;
      > .f-left {
        background-image: url(../../images/member/coupon-left@3x.png);
      }
      > .f-right {
        background-image: url(../../images/member/coupon-right@3x.png);
        a {
          color: white;
          font-size: 22px;
          writing-mode: vertical-lr;
        }
      }
      .bottom {
        .scope {
          font-size: 15px;
          font-weight: 500;
          color: #595959;
        }
        .time {
          font-size: 13px;
          font-weight: 500;
          color: #595959;
        }
      }
    }
    .price {
      padding-top: 5px;
    }
    .currency {
      font-size: 12px;
      width: 12px;
    }
    .discounted_price {
      font-size: 29px;
      margin: 0 5px;
    }
    .bottom {
      position: absolute;
      left: 15px;
      bottom: 11px;
      .scope {
        font-size: 15px;
        margin-bottom: 6px;
      }
      .time {
        font-size: 13px;
      }
    }
    .right {
      position: absolute;
      width: 116px;
      right: 0;
      text-align: center;
      font-size: 20px;
      top: 61px;
    }
  }
}
.detail-form-item {
  display: flex;
  margin: 0 62px 10px 62px;
  align-items: center;
  label {
    width: 80px !important;
  }
  span {
    flex: 1;
  }
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(89, 89, 89, 1);
}
.records {
  li {
    padding-bottom: 34px;
    font-size: 14px;
    color: rgba(89, 89, 89, 1);
    position: relative;
    .left {
      margin-right: 12px;
    }
    .point {
      width: 6px;
      height: 6px;
      background-color: $primary-color;
      border-radius: 100%;
      margin-top: 5px;
    }
    .line {
      width: 2px;
      background-color: #e9e9e9;
      height: 98px;
      top: 11px;
      position: absolute;
      left: 2px;
    }
    .time {
      margin-bottom: 8px;
    }
    .name {
      margin-bottom: 4px;
    }
  }
}
.table_wrap {
  margin-top: 30px;
  /deep/ {
    thead th {
      background-color: #f4f4f4;
      border-right: none;
    }
    th .cell {
      color: #999999;
    }
    .el-table__row td:last-child {
      color: $primary-color;
      cursor: pointer;
    }
  }
}
.balance_detail {
  color: #333333;
  font-size: 14px;
  li {
    line-height: 42px;
    border-bottom: 1px dashed #eef1f2;
    &:last-of-type {
      border: none;
    }
  }
}
</style>