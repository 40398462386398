<template>
  <div class="circle-container" :style="{'fontSize':size+'px'}">
    <div class="circle-bar" :class="{'circle-bar-half':progress>50}">
      <div class="circle-bar-left" v-if="progress<=50"></div>
      <div
        class="circle-bar-left"
        v-if="progress>50"
        :style="{transform:'rotate('+((progress>100?50:progress-50)*3.6)+'deg)'}"
      ></div>
      <div
        class="circle-bar-right"
        v-if="progress<=50"
        :style="{transform:'rotate('+(progress*3.6)+'deg)'}"
      ></div>
      <div class="circle-bar-right" v-if="progress>50"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RotateProgress",
  props: {
    progress: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 50
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.circle-container {
  border: 2px solid $primary-color;
  width: 1em;
  height: 1em;
  border-radius: 100%;
  display: inline-block;
}

.circle-bar {
  width: 1em;
  height: 1em;
  position: relative;
  background-color: $primary-color;
}

.circle-bar-left,
.circle-bar-right {
  width: 1em;
  height: 1em;
}

.circle-bar-right {
  clip: rect(0, auto, auto, 0.5em);
  background-color: white;
}

.circle-bar-left {
  clip: rect(0, 0.5em, auto, 0);
  background-color: white;
}

.circle-bar-half {
  .circle-bar-right {
    background-color: $primary-color;
    transform: rotate(0deg);
  }
}

.mask {
  width: 0.8em;
  height: 0.8em;
  background-color: #fff;
  text-align: center;
  line-height: 0.2em;
  color: rgba(0, 0, 0, 0.5);
}

.mask :first-child {
  font-size: 0.3em;
  height: 0.8em;
  line-height: 0.8em;
  display: block;
}

/*所有的后代都水平垂直居中，这样就是同心圆了*/

.circle-bar * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

/*自身以及子元素都是圆*/

.circle-bar,
.circle-bar > * {
  border-radius: 50%;
}
</style>