<template>
  <div :class="type==1?'courses':'books'" class="clearfix">
    <template v-for="item in list">
      <div :key="item.id" v-if="item.goods_type==1" class="item item-course">
        <div class="name">{{item.course_name}}</div>
        <div class="period">
          <span>共</span>
          <span class="period-count">{{item.periods_num}}</span>
          <span>个课时</span>
        </div>
        <div class="teachers">
          <div class="teacher" v-for="(teacher,index) in item.teacher" :key="index">
            <img class="teacher-avatar" :src="teacher.avatar" />
            <div class="teacher-name">{{teacher.teacher_name}}</div>
          </div>
        </div>
        <div class="bottom">
          <span class="integral-wrap">
            <span class="integral">{{item.exchange_price}}</span>
            <span class="integral-label">{{$store.state.commonSetting.point_info.point_name}}</span>
          </span>
          <router-link
            class="lnk-exchange"
            :to="{name:'IntegralGoodsDetail',params:{id:item.id}}"
          >去兑换</router-link>
        </div>
      </div>
      <div v-else class="item item-book" :key="item.id">
        <div class="name-wrap">
          <img class="cover" :src="item.cover_img" />
          <div class="right">
            <div class="book-name">{{item.book_name}}</div>
            <div class="book-author">{{item.author}}</div>
          </div>
        </div>
        <div class="bottom">
          <span class="integral-wrap">
            <span class="integral">{{item.exchange_price}}</span>
            <span class="integral-label">{{$store.state.commonSetting.point_info.point_name}}</span>
          </span>
          <router-link
            class="lnk-exchange"
            :to="{name:'IntegralGoodsDetail',params:{id:item.id}}"
          >去兑换</router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "IntegralGoodsItem",
  props: {
    type: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.item {
  border-radius: 10px;
  border: 1px solid rgba(245, 245, 245, 1);
  float: left;
  box-sizing: border-box;
  &:hover {
    box-shadow: 0px 11px 55px 10px rgba(0, 0, 0, 0.07),
      0px 13px 18px -8px rgba(0, 0, 0, 0.15);
    border: 0;
  }
}
.item-course {
  padding: 20px 15px 15px 15px;
  margin-right: 19px;
  margin-bottom: 20px;
  width: 276px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .name {
    height: 52px;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(89, 89, 89, 1);
    line-height: 26px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .period {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(89, 89, 89, 1);
    line-height: 20px;
    margin-bottom: 20px;
  }
  .period-count {
    margin: 0 5px;
  }
  .teachers {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 15px;
  }
  .teacher {
    margin-right: 25px;
    text-align: center;
  }
  .teacher-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-bottom: 8px;
  }
  .teacher-name {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 20px;
    width: 45px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.item-book {
  width: 419px;
  border-radius: 10px;
  margin-right: 28px;
  padding: 20px 20px 15px 20px;
  margin-bottom: 30px;
  &:nth-child(2n) {
    margin-right: 0;
  }
  .name-wrap {
    display: flex;
    margin-bottom: 16px;
  }
  .cover {
    width: 87px;
    height: 107px;
    border-radius: 10px;
    margin-right: 20px;
  }
  .right {
    overflow: hidden;
  }
  .book-name {
    height: 25px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(32, 36, 42, 1);
    line-height: 25px;
    letter-spacing: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin-bottom: 26px;
  }
  .book-author {
    height: 24px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(140, 140, 140, 1);
    line-height: 24px;
    letter-spacing: 1px;
  }
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .integral-wrap {
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(238, 31, 31, 1);
    display: flex;
    height: 32px;
  }
  .integral {
    font-size: 24px;
    line-height: 32px;
  }
  .integral-label {
    font-size: 15px;
    line-height: 34px;
  }
  .lnk-exchange {
    width: 74px;
    height: 32px;
    background: rgba(255, 255, 255, 1);
    border-radius: 16px;
    border: 1px solid rgba(210, 210, 210, 1);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(89, 89, 89, 1);
    line-height: 32px;
    text-align: center;
  }
}
</style>