import { render, staticRenderFns } from "./MemberVip.vue?vue&type=template&id=01b0315b&scoped=true&"
import script from "./MemberVip.vue?vue&type=script&lang=js&"
export * from "./MemberVip.vue?vue&type=script&lang=js&"
import style0 from "./MemberVip.vue?vue&type=style&index=0&id=01b0315b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b0315b",
  null
  
)

export default component.exports