<template>
  <div class="homework-container">
    <div class="title" v-if="$route.query.title">{{$route.query.title}}-作业列表</div>
    <div class="clear">
      <ul class="state-wrap f-left">
        <li
          class="state"
          :class="{'current':key==searchCondition.status}"
          v-for="(value,key) in states"
          :key="key"
          @click="changeState(key)"
        >{{value}}</li>
      </ul>
      <el-input
        class="f-right search"
        placeholder="请输入课程/课时/作业名称"
        suffix-icon="el-icon-search"
        v-model="searchCondition.keywords"
        @click.native="onInputClick"
        @keypress.native.enter="search"
        clearable
        style="width:222px;"
      ></el-input>
    </div>
    <list ref="list" url="/api/app/homework/list" :search="searchCondition" :alwaysShowPager="true">
      <template slot-scope="{list}">
        <el-card shadow="hover" class="homework-card" v-for="item in list" :key="item.id">
          <div class="clear">
            <div class="f-left left">
              <span class="state state5" v-if="item.homework_status==2">已关闭</span>
              <span
                class="state state4"
                v-else-if="item.homework_deadline<new Date().getTime()/1000&&item.report_status==1"
              >已过期</span>
              <span
                v-else
                class="state"
                :class="'state'+item.report_status.toString()"
              >{{states[item.report_status]}}</span>
              <div class="name" :title="item.homework_name">{{item.homework_name}}</div>
              <div class="time">
                <img src="@/assets/images/icon_news_time.png" />
                <span>{{item.homework_deadline|tsFormat("yyyy-MM-dd hh:mm")}}截止</span>
                <span class="score">
                  <span>{{item.score}}</span>
                  <span>分</span>
                </span>
                <span class="count">
                  <span>共</span>
                  <span>{{item.ques_count}}</span>
                  <span>题</span>
                </span>
              </div>
            </div>
            <div class="f-right"></div>
          </div>
          <div class="clear bottom">
            <span class="course f-left">{{item.course_name}}</span>
            <span
              class="point f-right"
              v-if="item.homework_status!=2&&item.report_status==3"
            >{{item.point}}分</span>
          </div>
          <span class="operate disabled" v-if="item.homework_status==2">查看详情</span>
          <router-link
            class="operate"
            :to="{name:'MemberHomeworkDo',query:{id:item.report_id}}"
            v-else-if="item.report_status==1&&item.homework_deadline>=new Date().getTime()/1000"
          >开始答题</router-link>
          <router-link
            class="operate"
            :to="{name:'MemberHomeworkPreview',query:{id:item.report_id}}"
            v-else-if="item.report_status==1&&item.homework_deadline<new Date().getTime()/1000"
          >预览</router-link>
          <router-link
            class="operate"
            :to="{name:'MemberHomeworkDetail',query:{id:item.report_id}}"
            v-else
          >查看详情</router-link>
          <img class="close" src="../../images/member/close@3x.png" v-if="item.homework_status==2" />
        </el-card>
        <div v-if="list.length==0" class="empty center">
          <img src="../../images/member/homework-empty@2x.png" />
          <div>暂无作业哦</div>
        </div>
      </template>
    </list>
  </div>
</template>
<script>
export default {
  name: "MemberHomework",
  data() {
    return {
      states: {
        0: "全部",
        1: "未完成",
        2: "待批阅",
        3: "已完成",
        4: "已过期"
      },
      searchCondition: {
        keywords: "",
        status: 0,
        course_id: this.$route.query.course || "",
        period_id: this.$route.query.period || ""
      }
    };
  },
  methods: {
    changeState(state) {
      this.searchCondition.status = state;
      this.search();
    },
    search() {
      this.$refs.list.searchRefresh();
    },
    onInputClick(event) {
      if (event.target.tagName == "I") {
        this.search();
      }
    }
  },
  watch: {
    $route() {
      this.searchCondition.status = 0;
      this.searchCondition.keywords = "";
      this.searchCondition.course_id = this.$route.query.course || "";
      this.searchCondition.period_id = this.$route.query.period || "";
      this.search();
    }
  }
};
</script>
<style lang="scss" scoped>
.homework-container {
  padding: 24px;
  .title {
    font-size: 20px;
    margin-bottom: 24px;
    font-weight: 500;
  }
}
.state-wrap {
  overflow: hidden;
  margin-bottom: 24px;
  .state {
    float: left;
    font-size: 14px;
    padding: 5px 16px;
    border: 1px solid #d9d9d9;
    border-right: none;
    color: #595959;
    cursor: pointer;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-right: 1px solid #d9d9d9;
      border-radius: 0px 4px 4px 0px;
    }
    &.current {
      color: $primary-color;
      border-color: $primary-color;
      & + .state {
        border-left-color: $primary-color;
      }
    }
  }
}
.search {
  /deep/ {
    .el-input__inner {
      height: 31px;
      line-height: 31px;
    }
    .el-input__icon {
      line-height: 31px;
    }
  }
}
.empty {
  font-size: 18px;
  font-weight: 400;
  color: rgba(140, 140, 140, 1);
  padding-top: 94px;
  img {
    width: 158px;
    margin-bottom: 20px;
  }
}
.homework-card {
  margin-bottom: 15px;
  border-radius: 10px;
  position: relative;
  /deep/ {
    .el-card__body {
      padding: 20px 24px;
      overflow: hidden;
      .left {
        width: 460px;
        position: relative;
      }
      .operate {
        position: absolute;
        top: 20px;
        right: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFangSC;
        font-weight: 400;
        color: $primary-color;
        &.disabled {
          color: #8c8c8c;
        }
      }
      .close {
        position: absolute;
        width: 42px;
        top: 65px;
        right: 29px;
      }
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFangSC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        height: 52px;
        line-height: 26px;
        margin-bottom: 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-indent: 51px;
      }
      .state {
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFangSC;
        font-weight: 400;
        float: left;
        width: 41px;
        height: 17px;
        text-align: center;
        line-height: 17px;
        border-radius: 2px;
        color: white;
        margin-right: 10px;
        margin-top: 5px;
        position: absolute;
      }
      .state1 {
        background-color: $primary-color;
      }
      .state2 {
        background-color: #ee1f1f;
      }
      .state3 {
        background-color: #30b5a6;
      }
      .state4 {
        background-color: #8c8c8c;
      }
      .state5 {
        background-color: #b7b7b7;
      }
      .time {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFangSC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
      }
      .score {
        margin-left: 30px;
        span:first-child {
          color: #ee1f1f;
        }
      }
      .count {
        margin-left: 30px;
        span:nth-child(2) {
          color: $primary-color;
          margin: 0 8px;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        .course {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFangSC;
          font-weight: 400;
          color: rgba(89, 89, 89, 1);
          flex-grow: 1;
        }
        .point {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFangSC;
          font-weight: 500;
          color: rgba(238, 31, 31, 1);
        }
      }
    }
  }
}
</style>