<template>
  <div>
    <el-tabs class="integral-tab" @tab-click="onClickTab" value="1">
      <el-tab-pane label="好课推荐" name="1"></el-tab-pane>
      <el-tab-pane label="热门图书" name="2"></el-tab-pane>
    </el-tabs>
    <List ref="list" class="list" url="/api/integral/shop/index" :search="search" :limit="pageSize">
      <template v-slot="{list}">
        <IntegralGoodsList :type="search.type" :list="list"></IntegralGoodsList>
      </template>
    </List>
  </div>
</template>
<script>
import List from "@/components/List";
import IntegralGoodsList from "../../../components/Integral/IntegralGoodsList";
export default {
  name: "MemberIntegralStore",
  components: {
    List,
    IntegralGoodsList
  },
  data() {
    return {
      search: { type: "1" },
      pageSize: 9
    };
  },
  methods: {
    onClickTab(tab) {
      this.search.type = tab.name;
      if (tab.name == 1) {
        this.pageSize = 9;
      } else {
        this.pageSize = 8;
      }
      this.$refs.list.searchRefresh();
    }
  }
};
</script>
<style lang="scss" scoped>
.integral-tab {
  /deep/ {
    .el-tabs__header {
      margin-bottom: 20px;
    }
    .el-tabs__nav-wrap {
      padding-left: 0 !important;
      &::after {
        display: none;
      }
    }
  }
}
</style>