<template>
  <div>
    <Header />
    <div class="main main-container">
      <div class="left">
        <div class="userinfo">
          <div class="clear user-info">
            <img class="avatar f-left"
                 :src="user.avatar" />
            <div class="f-right">
              <div class="name">{{user.nickname}}</div>
              <div class="greeting">
                <span>Hi，</span>
                <span v-if="new Date().getHours()<12">上午</span>
                <span v-else>下午</span>
                <span>好{{user.nickname}}</span>
              </div>
            </div>
          </div>
          <div v-if="this.$store.state.commonSetting.switch_vip_module === 1">
            <div class="clear vip"
                 v-if="!user.is_vip">
              <span>您还不是会员</span>
              <div>
                <router-link class="buy"
                             :to="{name:'MemberVip'}">开通会员，免费学习</router-link>
              </div>
            </div>
            <div class="clear vip"
                 v-else-if="user.vip">
              <span>您的会员到期日为：</span>
              <div style="padding-top:5px;">
                <span>{{new Date(user.vip.vip_end_at*1000).format("yyyy.MM.dd")}}</span>
                <router-link class="f-right"
                             :to="{name:'MemberVip'}">续费</router-link>
              </div>
            </div>
          </div>
        </div>
        <ul>
          <router-link :to="{name:'MemberStudy'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberStudy'"
                   src="../../images/member_new/course-selected@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:5px;" />
              <img v-else
                   src="../../images/member_new/course@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:5px;" />
              <span class="f-left">我的学习</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberCalendar'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberCalendar'"
                   src="../../images/member_new/calendar-selected@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/calendar@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">学习日历</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberExercise'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberExercise'"
                   src="../../images/member_new/exercise-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/exercise@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">我的练习</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberExam'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberExam'"
                   src="../../images/member_new/exam-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/exam@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">我的考试</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberHomework'}"
                       v-if="$store.state.commonSetting.homework_module.is_open=='1'">
            <li class="menu-item">
              <img v-if="$route.name.indexOf('MemberHomework')>=0"
                   src="../../images/member_new/homework-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/homework@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">我的作业</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberErrorList'}">
            <li class="menu-item">
              <img v-if="$route.name.indexOf('MemberErrorList')>=0"
                   src="../../images/member_new/error-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/error@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">错题本</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberQuestion'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberQuestion'"
                   src="../../images/member_new/question-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:5px;" />
              <img v-else
                   src="../../images/member_new/question@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:5px;" />
              <span class="f-left">我的问答</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberPath'}"
                       v-if="$store.state.commonSetting.homework_module.is_open=='1'">
            <li class="menu-item">
              <img v-if="$route.name.indexOf('MemberPath')>=0"
                   src="../../images/member_new/path-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/path@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">学习轨迹</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberCollect'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberCollect'"
                   src="../../images/member_new/collect-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;" />
              <img v-else
                   src="../../images/member_new/collect@2x.png"
                   class="f-left member-icon"
                   style="width:15px;" />
              <span class="f-left">我的收藏</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberWealth'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberWealth'"
                   src="../../images/member_new/wealth-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <img v-else
                   src="../../images/member_new/wealth@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <span class="f-left">我的财富</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberIntegral'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberIntegral'"
                   src="../../images/member_new/integral-selected@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <img v-else
                   src="../../images/member_new/integral@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <span class="f-left">我的积分</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberAccount'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberAccount'"
                   src="../../images/member_new/account-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <img v-else
                   src="../../images/member_new/account@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <span class="f-left">账户余额</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberOrder'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberOrder'"
                   src="../../images/member_new/order-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/order@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">我的订单</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberAddress'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberAddress'"
                   src="../../images/member_new/address-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/address@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">收货地址</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberMessage'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberMessage'"
                   src="../../images/member_new/message-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member_new/message@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">消息中心</span>
              <span v-if="$store.state.hasNewMessage"
                    class="red-point"></span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <router-link :to="{name:'MemberProfile'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberProfile'"
                   src="../../images/member_new/person-selected@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <img v-else
                   src="../../images/member_new/person@2x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <span class="f-left">个人信息</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link>
          <!-- <router-link :to="{name:'MemberEvaluation'}"
                       :class="{'router-link-active': $route.name.startsWith('MemberEvaluation')}"
                       v-if="$store.state.commonSetting.switch_question_module==1">
            <li class="menu-item">
              <img v-if="$route.name.startsWith('MemberEvaluation')"
                   src="../../images/member/evaluation-selected@3x.png"
                   class="f-left member-icon"
                   style="width:13px;" />
              <img v-else
                   src="../../images/member/evaluation@3x.png"
                   class="f-left member-icon"
                   style="width:13px;" />
              <span class="f-left">我的测评</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link> -->
          <!-- <router-link :to="{name:'MemberOto'}">
            <li class="menu-item">
              <img v-if="$route.name=='MemberOto'||$route.name=='MemberOtoCommunication'"
                   src="../../images/member/oto-selected@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <img v-else
                   src="../../images/member/oto@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:3px;" />
              <span class="f-left">我的约课</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link> -->

          <!-- <router-link :to="{ name: 'MemberIntegral' }">
            <li class="menu-item">
              <img v-if="$route.name == 'MemberIntegral'"
                   src="../../images/member/integral-selected@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <img v-else
                   src="../../images/member/integral@3x.png"
                   class="f-left member-icon"
                   style="width:15px;margin-top:4px;" />
              <span class="f-left"
                    v-if="$store.state.commonSetting.point_info">我的{{$store.state.commonSetting.point_info.point_name}}</span>
              <i class="el-icon-arrow-right f-right"></i>
            </li>
          </router-link> -->
        </ul>
      </div>
      <div class="right">
        <div class="member-bread"
             v-if="breads.length">
          <span v-for="(bread,index) in breads"
                :key="index">
            <router-link v-if="bread.name"
                         :to="{name:bread.name,query:bread.query,params:bread.params}">{{bread.title}}</router-link>
            <span v-else
                  :class="{'current':index==breads.length-1,query:bread.query,params:bread.params}">{{bread.title}}</span>
            <span class="slash"
                  v-if="index<breads.length-1">/</span>
          </span>
        </div>
        <div class="member-bread"
             v-else-if="$route.meta.bread">
          <span v-for="(bread,index) in $route.meta.bread"
                :key="index">
            <router-link v-if="bread.route"
                         :to="bread.route">{{bread.title}}</router-link>
            <span v-else
                  :class="{'current':index==$route.meta.bread.length-1}">{{bread.title}}</span>
            <span class="slash"
                  v-if="index<$route.meta.bread.length-1">/</span>
          </span>
        </div>
        <div class="content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";

export default {
  name: "Member",
  components: {
    Header
  },
  created () {
    this.$store.dispatch("refreshMessageState");
    this.$store.commit("setBreads", this.$route);
  },
  computed: {
    user () {
      return this.$store.state.user_info;
    },
    breads () {
      return this.$store.state.breads;
    }
  },
  watch: {
    $route () {
      this.$store.commit("setBreads", this.$route);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.main {
  min-height: 300px;
  padding-top: 114px;
  overflow: hidden;
}

.left {
  background-color: white;
  width: 256px;
  float: left;
  padding: 24px 0;
  box-sizing: border-box;
}

.userinfo {
  padding: 0 24px;
}

.right {
  width: 916px;
  float: right;
  .content {
    background-color: white;
  }
}

.user-info {
  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }

  .name {
    font-size: 20px;
    color: rgba(69, 69, 69, 1);
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .greeting {
    font-size: 14px;
    color: rgba(184, 184, 184, 1);
    width: 112px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.vip {
  padding: 43px 0;
  font-size: 14px;
  .buy {
    margin-top: 4px;
    display: inline-block;
    text-decoration: underline;
  }
  span {
    color: #8c8c8c;
  }
  a {
    color: $primary-color;
  }
}

.menu-item {
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
  color: rgba(183, 183, 183, 1);
  padding: 16px 25px;
  position: relative;
  i {
    margin-top: 2px;
  }
  .member-icon {
    margin-right: 15px;
    margin-top: 5px;
  }
}

.router-link-active .menu-item {
  color: $primary-color;
  &::after {
    content: "";
    width: 2px;
    height: 69px;
    background-color: $primary-color;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.member-bread {
  background-color: white;
  height: 62px;
  line-height: 62px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  padding-left: 24px;
  a {
    color: rgba(0, 0, 0, 0.45);
  }
  .current {
    color: #595959;
  }
  .slash {
    margin: 0 8px;
  }
}

.red-point {
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: red;
  border-radius: 100%;
    margin-left: 5px;
    margin-bottom: 2px;
}
</style>
