<template>
  <div>
    <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{ 'tab-item-current': current == item.type }"
        @click="changeTab(item.type)"
        v-for="item in types"
        :key="item.type"
      >
        <span>{{ item.label }}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <div class="main" v-if="list.length">
      <div class="header">
        <div class="name">商品名称</div>
        <div class="price">订单金额</div>
        <div class="status">订单状态</div>
        <div class="pay-type">支付类型</div>
        <div class="operate">操作</div>
      </div>
      <div class="item" v-for="item in list" :key="item.order_number">
        <div class="item-header clear">
          <span class="f-left">订单编号：{{ item.order_number }}</span>
          <span
            class="red f-left"
            v-if="item.pay_status == 1 && item.order_status == 1"
            style="margin-left: 20px"
          >
            30分钟内未付款，订单将自动取消
          </span>
          <span class="f-right time">
            下单时间：{{
              new Date(parseInt(item.created_at) * 1000).format(
                "yyyy-MM-dd hh:mm:ss"
              )
            }}</span
          >
        </div>
        <div class="clear content">
          <div class="name f-left" @click="toDetail(item)">
            <span>{{
              (item.spell_id > 0 ? "【拼团】" : "") + item.title
            }}</span>
          </div>
          <div class="price f-left">
            <Currency class="currency"></Currency>
            <span>{{ item.order_price | price }}</span>
          </div>
          <div class="status f-left">
            <div>
              <div>{{ item.status_name }}</div>
              <div
                v-if="
                  item.shop_type == 12 &&
                  item.order_status == 1 &&
                  item.ship_status == 3
                "
              >
                <div>{{ item.logistics_name }}</div>
                <div>{{ item.logistics_no }}</div>
              </div>
            </div>
          </div>
          <div class="pay-type f-left" v-if="item.order_price > 0">
            {{ item.pay_type | payType }}
          </div>
          <div
            class="pay-type f-left"
            v-else-if="item.pay_status == 2 && item.order_status == 2"
          >
            系统
          </div>
          <div class="pay-type f-left" v-else>--</div>
          <div class="operate f-left">
            <div>
              <a @click="showDetail(item.id)">订单详情</a>
            </div>

            <div v-if="item.shop_type == 12 && item.ship_status == 3">
              <a @click="confirmShip(item.order_item_id)">确认收货</a>
            </div>

            <div
              v-if="
                item.shop_type == 12 &&
                (item.ship_status == 2 || item.ship_status == 4)
              "
            >
              <span v-if="item.is_comment == 1">已评价</span>
              <a v-else @click="comment(item)">去评价</a>
            </div>
            <div
              v-if="
                item.shop_type != 12 &&
                item.order_status == 2 &&
                item.pay_status == 2 &&
                (item.ship_status == 2 || item.ship_status == 4)
              "
            >
              <span v-if="item.is_comment == 1">已评价</span>
              <a v-else @click="comment(item)">去评价</a>
            </div>

            <div v-if="item.pay_status == 1">
              <div v-if="item.order_status == 1">
                <div>
                  <el-button type="primary" size="small" @click="goToPay(item)"
                    >立即付款</el-button
                  >
                </div>
                <a @click="cancelOrder(item)">取消订单</a>
              </div>
              <div v-else-if="item.order_status == 3">
                <span>已取消</span>
              </div>
            </div>

            <div
              v-if="
                item.order_price > 0 &&
                item.pay_type != 9 &&
                (item.status_id == 3 ||
                  item.status_id == 4 ||
                  item.status_id == 5 ||
                  item.status_id == 6 ||
                  item.status_id == 13 ||
                  item.status_id == 14)
              "
            >
              <span class="red" v-if="item.pay_status == 3">退款申请中</span>
              <span class="red" v-if="item.pay_status == 4">退款中</span>
              <span v-if="item.pay_status == 6">
                <span class="red">退款失败</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'退款失败原因：' + (item.err_msg || '')"
                  placement="right"
                >
                  <i class="el-icon-question" style="margin-bottom: 0"></i>
                </el-tooltip>
              </span>
              <span v-else-if="item.pay_status == 2 && item.refund_status == 3">
                <span class="red">退款被驳回</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'退款驳回原因：' + (item.remark || '')"
                  placement="right"
                >
                  <i class="el-icon-question" style="margin-bottom: 0"></i>
                </el-tooltip>
              </span>
              <div>
                <a
                  @click="applyRefund(item.id)"
                  v-if="
                    item.pay_status == 2 &&
                    (current == 2 ||
                      current == 4 ||
                      current == 5 ||
                      current == 6) &&
                    item.refund_status != 3 &&
                    item.pay_type != 5
                  "
                  >退款</a
                >
                <a
                  @click="applyRefund(item.id)"
                  v-if="
                    item.pay_status == 2 &&
                    (current == 2 ||
                      current == 4 ||
                      current == 5 ||
                      current == 6) &&
                    item.refund_status == 3
                  "
                  >重新发起退款</a
                >
              </div>
            </div>
            <div v-if="item.order_status == 2 && current == 2">
              <a v-if="!item.is_join_study" @click="joinStudy(item)"
                >加入学习</a
              >
              <span v-else>已加入学习</span>
            </div>
            <div
              v-if="
                item.order_status == 3 &&
                (current == 2 ||
                  current == 1 ||
                  current == 4 ||
                  current == 5 ||
                  current == 6)
              "
            >
              <div v-if="item.order_price > 0">
                <span v-if="item.pay_status == 5">
                  <span>退款成功</span>
                  <el-tooltip class="item" effect="dark" placement="right">
                    <i class="el-icon-question" style="margin-bottom: 0"></i>
                    <template v-slot:content>
                      <div>退款金额：{{ item.send_back_fee | price }}</div>
                      <div>备注：{{ item.remark }}</div>
                    </template>
                  </el-tooltip>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="limit"
        layout="prev, pager, next"
        :total="total"
        v-if="total > 15"
      ></el-pagination>
    </div>
    <div v-else class="center" style="padding: 60px 0">暂无订单</div>
    <el-dialog
      class="detail"
      title="订单详情"
      :visible.sync="showDetailDialog"
      width="630px"
    >
      <div>
        <div class="detail-item">
          <label>订单编号：</label>
          <span>{{ detail.order_number }}</span>
        </div>
        <div class="detail-item">
          <label>下单用户：</label>
          <span>{{ detail.nickname }}</span>
        </div>
        <div class="detail-item">
          <label>手机号码：</label>
          <span>{{ detail.mobile }}</span>
        </div>
        <div class="detail-item">
          <label>商品名称：</label>
          <span>{{ detail.shop_name }}</span>
        </div>
        <div class="detail-item">
          <label>下单时间：</label>
          <span>{{
            new Date(parseInt(detail.created_at) * 1000).format(
              "yyyy-MM-dd hh:mm:ss"
            )
          }}</span>
        </div>
        <div class="detail-item">
          <label>支付渠道：</label>
          <span>{{ detail.pay_type | payType }}</span>
        </div>
        <div class="detail-item">
          <label>订单状态：</label>
          <span>{{ detail.order_status | orderStatus }}</span>
        </div>
        <div
          class="detail-item"
          v-if="detail.shop_type == 12 && detail.logistics_info"
        >
          <label>物流信息：</label>
          <span>{{
            detail.logistics_info.logistics_name +
            " " +
            detail.logistics_info.logistics_no
          }}</span>
        </div>
        <div class="detail-item detail-item-line"></div>
        <div class="clear">
          <div class="f-right detail-item-right">
            <div class="detail-item">
              <label>商品原价：</label>
              <span>
                <span class="jianhao"></span>
                <Currency class="currency"></Currency>
                <span>{{ detail.original_price | price }}</span>
              </span>
            </div>
            <div class="detail-item" v-if="detail.shop_type == 12">
              <label>运费：</label>
              <span>
                <span class="jianhao"></span>
                <Currency class="currency"></Currency>
                <span>{{ detail.ship_price | price }}</span>
              </span>
            </div>
            <div class="detail-item">
              <label>优惠券：</label>
              <span>
                <span class="jianhao">-</span>
                <Currency class="currency"></Currency>
                <span>{{ detail.coupon_discount | price }}</span>
              </span>
            </div>
            <div class="detail-item">
              <label>订单金额：</label>
              <span>
                <span class="jianhao"></span>
                <Currency class="currency"></Currency>
                <span>{{ detail.order_price | price }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showDetailDialog = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      class="comment-dialog"
      title="评价"
      :visible.sync="showCommentDialog"
      width="784px"
    >
      <div class="name item">商品名称：{{ commentDetail.title }}</div>
      <div class="item">
        <label>星级：</label>
        <el-rate
          v-model="commentInfo.grade"
          :disabled="commentDetail.is_comment == 1"
        ></el-rate>
      </div>
      <div class="item">
        <label>评价内容：</label>
        <br />
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入评价内容，最多500字"
          v-model="commentInfo.content"
          :maxlength="500"
          :readonly="commentDetail.is_comment == 1"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitComment">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderFuc from "@/utils/order.js";
export default {
  name: "MemberOrder",
  data() {
    return {
      current: "2",
      types: [
        {
          type: "2",
          label: "课程订单",
        },
        // {
        //   type: "1",
        //   label: "约课订单"
        // }
      ],
      list: [],
      showDetailDialog: false,
      detail: {},
      page: 1,
      limit: 15,
      total: 0,
      showCommentDialog: false,
      commentDetail: {},
      commentInfo: {
        grade: 0,
        content: "",
      },
    };
  },
  async created() {
    // 隐藏会员订单
    // if (this.$store.state.commonSetting.switch_vip_module === 1) {
    //   this.types.push({
    //     type: "3",
    //     label: "会员订单"
    //   });
    // }
    // 图书订单
    this.types.push(
      { type: "4", label: "图书订单" },
      { type: "5", label: "练习订单" },
      { type: "6", label: "模考订单" }
    );
    let type = this.$route.query.type;
    if (type) {
      if (type == 1) {
        this.current = "1";
      } else if (type == 14) {
        this.current = "3";
      } else if (type == 12) {
        this.current = "4";
      } else {
        this.current = "2";
      }
    }
    await this.getData();
  },
  methods: {
    async getData() {
      let data = await this.$http.post("/api/app/myOrder", {
        order_type: this.current,
        page: this.page,
        limit: this.limit,
      });
      data.list.forEach((item) => {
        let desc = orderFuc.getListDescData(item);
        let text = desc.text;
        item.status_name = text ? text.list : "";
        item.status_id = desc.id;
      });

      this.list = data.list;
      this.total = data.total;
    },
    async joinStudy(item) {
      await this.$http.post("/api/app/joinStudy", {
        order_id: item.id,
        join_from: 2,
        course_type: item.shop_type,
      });
      await this.$success("加入成功");
      item.is_join_study = 1;
    },
    async changeTab(tab) {
      this.page = 1;
      this.current = tab;
      await this.getData();
    },
    goToPay(order) {
      this.$router.push({
        name: "OrderPay",
        params: { id: order.id },
        query: { type: order.shop_type },
      });
    },
    async cancelOrder(item) {
      await this.$confirm("确定要取消订单么？", "提示", { type: "warning" });
      await this.$http.post("/api/app/myOrder/cancel", {
        order_id: item.id,
      });
      await this.$success("取消成功");
      await this.getData();
    },
    async showDetail(id) {
      // let data = await this.$http.post("/api/app/myOrder/detail", {
      //   order_id: id
      // });
      // this.showDetailDialog = true;
      // this.detail = data;
      this.$router.push({ name: "MemberOrderDetail", query: { order_id: id } });
    },
    async applyRefund(id) {
      await this.$confirm(
        "申请退款及具体退款金额需要管理员审核，确认退款么？",
        "提示",
        { type: "warning" }
      );
      await this.$http.get("/api/app/myOrder/application/" + id);
      await this.$success("申请退款成功");
      await this.getData();
    },
    async handlePageChange(page) {
      this.page = page;
      await this.getData();
    },
    async confirmShip(id) {
      await this.$confirm("请收到货物后再点击确认收货，确认收货么？", "提示", {
        type: "warning",
      });
      await this.$http.put(`/api/app/myOrder/confirmReceive`, { order_id: id });
      await this.getData();
    },
    comment(item) {
      this.showCommentDialog = true;
      this.commentDetail = item;
    },
    async submitComment() {
      if (this.commentDetail.is_comment == 1) {
        this.showCommentDialog = false;
        return;
      }
      if (this.commentInfo.grade == 0) {
        await this.$info("请选择星级");
        return;
      }
      if (this.commentInfo.content == "") {
        await this.$info("请输入评价内容");
        return;
      }
      if (this.current == 5 || this.current == 6) {
        //章节模拟评价
        await this.$http.post("/api/app/commentOrderItem", {
          order_item_id: this.commentDetail.order_item_id,
          grade: this.commentInfo.grade,
          content: this.commentInfo.content,
          shop_id: this.commentDetail.shop_ids,
          order_num: this.commentDetail.order_number,
          type: this.current == 5 ? 15 : 16,
        });
      } else {
        //图书评价
        await this.$http.post("/api/app/book/comment", {
          grade: this.commentInfo.grade,
          content: this.commentInfo.content,
          book_id: this.commentDetail.shop_ids,
          order_num: this.commentDetail.order_number,
        });
      }
      this.showCommentDialog = false;
      this.commentDetail.is_comment = 1;
      await this.$success("评价成功");
      this.commentInfo.grade = 0;
      this.commentInfo.content = "";
    },
    // 点击商品名称跳转商品对应详情页
    toDetail(item) {
      if (item.shop_status_remark) {
        this.$message.error(item.shop_status_remark);
        return;
      }
      let name = "CourseShow";
      let params = { id: item.shop_ids };
      if (item.shop_type == 12) {
        name = "BookDetail";
      } else if (item.shop_type == 15) {
        name = "ExerciseShow";
      } else if (item.shop_type == 16) {
        name = "ExamSimulationDes";
      }
      this.$router.push({ name: name, params: params });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/member-order.scss";
</style>
