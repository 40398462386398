<template>
  <div>
    <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{'tab-item-current':current==item.type}"
        @click="changeTab(item.type)"
        v-for="item in types"
        :key="item.type"
      >
        <span>{{item.label}}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <div class="oto-list" v-if="list.length">
      <div class="oto-item clear" v-for="item in list" :key="item.id">
        <img class="avatar f-left" :src="item.avatar" />
        <div class="f-left main">
          <div class="clear">
            <div class="f-left">
              <div
                class="time"
              >{{new Date(item.start_play*1000).format("yyyy.MM.dd hh:mm")}} ~ {{new Date(item.end_play*1000).format("hh:mm")}}</div>
              <div class="tip">
                <span class="name">{{item.nickname}}（{{item.chapter_time}}）分钟</span>
                <span class="tips">{{item.course_tips}}</span>
              </div>
            </div>
            <div class="f-right operate">
              <router-link
                :to="{name:'MemberOtoCommunication',query:{id:item.id,teacher_id:item.teacher_id,from:current}}"
                class="lnk-commu"
                v-if="current!=3"
              >
                沟通记录
                <span class="point" v-if="item.is_read"></span>
              </router-link>
              <a v-if="current==1" @click="enterLive(item.id)">进入课堂</a>
              <a v-else-if="current==2" @click="enterLive(item.id)">查看回放</a>
              <a v-if="current==1" @click="cancelOto(item)">取消约课</a>
              <a
                v-else-if="current==2"
                @click="beginComment(item)"
              >{{item.is_comment==0?'评价老师':'已评价'}}</a>
              <span
                class="cancel-time"
                v-if="current==3"
              >于{{item.cancel_at|tsFormat("yyyy.MM.dd hh:mm")}}取消</span>
            </div>
          </div>
          <div class="comment" v-if="item.comment_info">老师对我的点评：{{item.comment_info.content}}</div>
        </div>
      </div>
    </div>
    <div v-else class="center" style="padding:60px 0;">暂无约课</div>
    <div class="center">
      <a class="more" v-if="total>page*limit" @click="loadMore">加载更多...</a>
    </div>
    <el-dialog class="dialog-comment" title="评价" :visible.sync="showCommentDialog" width="800px">
      <div class="clear item">
        <img class="avatar f-left" :src="commentInfo.avatar" />
        <div class="f-left">
          <div class="time">{{commentInfo.course_time}}</div>
          <div class="name">{{commentInfo.teacher_name}}（{{commentInfo.chapter_time}}）分钟</div>
        </div>
      </div>
      <div class="item">
        <label>星级：</label>
        <el-rate v-model="commentForm.grade" :disabled="commentItem.is_comment>0"></el-rate>
      </div>
      <div class="item">
        <label>标签：</label>
        <div class="clear">
          <span
            class="tag f-left"
            :class="{'selected':commentForm.tag[item.id]==1}"
            v-for="item in commentInfo.tag"
            :key="item.id"
            @click="toggleSelected(item.id)"
          >{{item.title}}</span>
        </div>
      </div>
      <div>
        <label>评价内容：</label>
      </div>
      <div v-if="commentItem.is_comment>0">{{commentForm.content}}</div>
      <div v-else>
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入对老师的评价内容，最多500字"
          :maxlength="500"
          v-model="commentForm.content"
          :readonly="commentItem.is_comment>0"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer" v-if="commentItem.is_comment==0">
        <el-button @click="showCommentDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitComment">发布</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-else>
        <el-button type="primary" @click="showCommentDialog=false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "MemberOto",
  data() {
    return {
      current: "1",
      types: [
        {
          type: "1",
          label: "待上课"
        },
        {
          type: "2",
          label: "已上课"
        },
        {
          type: "3",
          label: "已取消"
        }
      ],
      page: 1,
      limit: 10,
      total: 0,
      list: [],
      showCommentDialog: false,
      commentItem: {},
      commentInfo: {},
      commentForm: {
        grade: 0,
        tag: {},
        content: ""
      }
    };
  },
  async created() {
    this.current = this.$route.query.type || "1";
    await this.getData();
  },
  methods: {
    async getData() {
      let data = await this.$http.post("/api/app/oto/myInviteCourse/index", {
        type: this.current,
        limit: this.limit,
        page: this.page
      });
      data.list.forEach(o => {
        this.list.push(o);
      });
      this.total = data.total;
    },
    async changeTab(tab) {
      this.current = tab;
      this.page = 1;
      this.list = [];
      await this.getData();
    },
    async loadMore() {
      this.page++;
      await this.getData();
    },
    async cancelOto(item) {
      let time =
        new Date(item.start_play * 1000).format("yyyy-MM-dd hh:mm") +
        "-" +
        new Date(item.end_play * 1000).format("hh:mm");
      await this.$promiseConfirm(
        "取消课程后，资金将原路返回您的账户。确认取消" +
          item.nickname +
          "   " +
          time +
          "   的约课么？",
        {
          type: "warning",
          cancelButtonText: "再想想",
          confirmButtonText: "确定取消"
        }
      );
      await this.$http.get("/api/app/oto/cancelInvite/" + item.id + "/0");
      this.list = [];
      this.page = 1;
      await this.getData();
    },
    async beginComment(item) {
      this.commentForm.tag = {};
      this.commentInfo = await this.$http.get(
        "/api/app/oto/comment/info/" + item.id
      );
      this.commentInfo.tag.forEach(t => {
        this.$set(this.commentForm.tag, t.id, 0);
      });
      if (item.is_comment > 0) {
        let data = await this.$http.post("/api/app/oto/comment/send", {
          teacher_id: item.teacher_id,
          user_id: item.user_id,
          oto_course_id: item.id,
          comment_id: item.is_comment
        });
        this.commentForm.grade = data.grade;
        this.commentForm.content = data.content;
        let tagIds = data.tag_id.split(",");
        tagIds.forEach(t => {
          this.commentForm.tag[t] = 1;
        });
      } else {
        this.commentForm.grade = 0;
        this.commentForm.content = "";
      }
      this.commentItem = item;
      this.showCommentDialog = true;
    },
    toggleSelected(id) {
      if (this.commentItem.is_comment > 0) {
        return;
      }
      if (this.commentForm.tag[id] == 0) {
        this.commentForm.tag[id] = 1;
      } else {
        this.commentForm.tag[id] = 0;
      }
    },
    async submitComment() {
      if (this.commentItem.comment_id > 0) {
        this.showCommentDialog = false;
        return;
      }
      if (this.commentForm.grade == 0) {
        await this.$info("请选择星级");
        return;
      }
      let tagIds = [];
      for (let key in this.commentForm.tag) {
        if (this.commentForm.tag[key] == 1) {
          tagIds.push(key);
        }
      }
      if (tagIds.length == 0) {
        await this.$info("请选择标签");
        return;
      }
      if (this.commentForm.content == "") {
        await this.$info("请输入评价内容");
        return;
      }
      let data = await this.$http.post("/api/app/oto/comment/send", {
        teacher_id: this.commentItem.teacher_id,
        user_id: this.commentItem.user_id,
        content: this.commentForm.content,
        grade: this.commentForm.grade,
        oto_course_id: this.commentItem.id,
        tag_id: tagIds.join(","),
        comment_id: 0
      });
      this.commentItem.is_comment = data.comment_id;
      this.showCommentDialog = false;
      await this.$success("评价成功");
    },
    async enterLive(id) {
      this.$livePlay(id, 1);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.tab-item span {
  border-right: none;
}

.more {
  display: inline-block;
  text-align: center;
  width: 166px;
  height: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin: 16px 0;
  cursor: pointer;
}

.oto-list {
  padding: 20px 24px;
}

.oto-item {
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  padding: 24px 32px;
  margin-bottom: 20px;
  .avatar {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    margin-right: 24px;
  }
  .main {
    width: 705px;
    margin-top: 5px;
  }
  .time {
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
  }
  .tip {
    font-size: 14px;
    color: rgba(89, 89, 89, 1);
    .name {
      margin-right: 20px;
    }
    .tips {
      font-size: 12px;
      color: rgba(140, 140, 140, 1);
    }
  }
  .comment {
    font-size: 14px;
    color: rgba(89, 89, 89, 1);
    padding-top: 24px;
    border-top: 1px solid #e8e8e8;
  }
  .operate {
    padding-top: 20px;
    a {
      font-size: 14px;
      color: $primary-color;
      margin-left: 50px;
      cursor: pointer;
      display: inline-block;
      width: 60px;
    }
    .lnk-commu {
      position: relative;
      .point {
        width: 6px;
        height: 6px;
        background-color: #f5222d;
        display: inline-block;
        border-radius: 100%;
        position: absolute;
        right: -2px;
        top: 0;
      }
    }
  }
  .cancel-time {
    font-size: 14px;
    color: #b7b7b7;
    padding: 4px 12px;
    background: rgba(244, 244, 244, 1);
    border-radius: 4px;
    border: 1px solid rgba(217, 217, 217, 1);
  }
}

.dialog-comment {
  /deep/ .el-dialog__header {
    text-align: center;
  }
  /deep/ .dialog-footer {
    text-align: center;
  }
  .avatar {
    width: 62px;
    height: 62px;
    border-radius: 100%;
    margin-right: 16px;
  }
  .time {
    margin-bottom: 12px;
  }
  .item {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
  }
  label {
    white-space: nowrap;
  }
  .tag {
    background: rgba(245, 245, 245, 1);
    border-radius: 4px;
    border: 1px solid rgba(217, 217, 217, 1);
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    padding: 5px 10px;
    margin-right: 12px;
    cursor: pointer;
    &.selected {
      background-color: #ea7a2f;
      color: white;
    }
  }
}
</style>
