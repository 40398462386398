var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Calendar',{attrs:{"courseDate":_vm.times},on:{"onClickDay":_vm.onClickDay}}),_c('div',{staticClass:"list"},_vm._l((_vm.list),function(item,index){return _c('el-card',{key:index,staticClass:"item clear",attrs:{"shadow":"hover"}},[_c('div',{staticClass:"f-left main"},[(item.start_play)?_c('div',{staticClass:"time"},[_vm._v("\n          "+_vm._s(new Date(item.start_play * 1000).format("hh:mm"))+"-"+_vm._s(new Date(item.end_play * 1000).format("hh:mm"))+"\n        ")]):_vm._e(),_c('div',[_c('span',{staticClass:"type"},[_vm._v(_vm._s(item.course_type_name))]),(
              item.course_type == 2 ||
              item.course_type == 3 ||
              item.course_type == 4 ||
              item.course_type == 1
            )?_c('span',{staticClass:"state"},[(item.live_status == 0)?_c('span',[_vm._v("[未开始]")]):(item.live_status == 1)?_c('span',{staticClass:"living"},[_vm._v("[直播中]")]):(item.live_status == 2)?_c('span',[_vm._v("[已结束]")]):_vm._e()]):_vm._e(),(item.course_type == 7)?_c('span',{staticClass:"state"},[(item.live_status == 0)?_c('span',[_vm._v("[未开始]")]):(item.live_status == 1)?_c('span',[_vm._v("[上课中]")]):(item.live_status == 2)?_c('span',[_vm._v("[已结束]")]):_vm._e()]):_vm._e(),(item.course_type != 1)?_c('span',{staticClass:"title",on:{"click":function($event){return _vm.goToPlay(item)}}},[_vm._v(_vm._s(item.title)+"\n          ")]):_c('span',{staticClass:"title",on:{"click":function($event){return _vm.goToPlay(item)}}},[_vm._v(_vm._s(item.oto_desc))])]),(
            item.homework_name &&
            _vm.$store.state.commonSetting.homework_module.is_open == '1'
          )?_c('div',{staticClass:"homework"},[_vm._v("\n          "+_vm._s(item.homework_name +
            (item.homework_num > 1
              ? " 等" + item.homework_num + "个作业"
              : ""))+"\n        ")]):_vm._e()]),_c('div',{staticClass:"f-right"},[(
            (item.course_type == 1 ||
              item.course_type == 2 ||
              item.course_type == 3 ||
              item.course_type == 4) &&
            (item.live_status == 1 || item.live_status == 2)
          )?_c('span',{staticClass:"live-state f-left"},[(item.live_status == 1)?_c('span',{staticClass:"period-living",on:{"click":function($event){$event.stopPropagation();return _vm.goToPlay(item)}}},[_vm._v("\n            直播\n          ")]):(
              item.live_status == 2 && item.cloud_class_type === 'baijiayun'
            )?_c('span',[(item.is_play_back)?_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.goToPlay(item)}}},[_vm._v("\n              回放\n            ")]):_c('span',[_vm._v("暂无回放")])]):(
              item.live_status == 2 && item.cloud_class_type === 'plaso'
            )?_c('span',[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.goToPlay(item)}}},[_vm._v(" 回放 ")])]):_vm._e()]):_vm._e(),(
            item.is_submit &&
            _vm.$store.state.commonSetting.homework_module.is_open == '1'
          )?_c('router-link',{staticClass:"live-state f-left",attrs:{"to":{
            name: 'MemberHomework',
            query: {
              course: item.course_basis_id,
              period: item.course_chapter_id,
            },
          }}},[_vm._v("作业提交")]):(
            item.is_view &&
            _vm.$store.state.commonSetting.homework_module.is_open == '1'
          )?_c('router-link',{staticClass:"live-state f-left",attrs:{"to":{
            name: 'MemberHomework',
            query: {
              course: item.course_basis_id,
              period: item.course_chapter_id,
            },
          }}},[_vm._v("查看作业")]):_vm._e()],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }