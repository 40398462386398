<template>
  <div>
    <img class="advert" src="../../images/vip.png" />
    <div class="main-container">
      <div class="title">购买会员</div>
      <div class="contract">
        <img src="@/assets/images/info@2x.png" style="width:16px;" />
        <router-link :to="{name:'Contract',query:{key:'vip_user_contract'}}">会员服务协议</router-link>
      </div>
      <div class="clear">
        <div
          class="item"
          :class="{'current':item.id==current}"
          v-for="item in list"
          :key="item.id"
          @click="current=item.id"
        >
          <div class="clear">
            <span class="name f-left">{{item.name}}</span>
            <span class="duration f-right">{{item.duration}}</span>
          </div>
          <div class="price">
            <Currency class="currency"></Currency>
            <span>{{item.price|price}}</span>
          </div>
          <img class="logo" src="../../images/vip-logo.png" />
        </div>
      </div>
      <div class="center buy">
        <el-button type="primary" :disabled="current==0" @click="goToPay">立即支付</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MemberVip",
  data() {
    return {
      list: [],
      current: 0
    };
  },
  async created() {
    this.list = await this.$http.get("/api/app/vip/grade");
  },
  methods: {
    goToPay() {
      this.$router.push({
        name: "OrderConfirm",
        params: { id: this.current },
        query: { type: 14 }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.advert {
  width: 100%;
}

.title {
  font-size: 40px;
  font-weight: bold;
  color: rgba(89, 89, 89, 1);
  text-align: center;
  padding: 50px 0 10px 0;
}

.main-container {
  width: 1242px;
}

.item {
  width: 414px;
  height: 215px;
  background-image: url(../../images/vip-back.png);
  float: left;
  box-sizing: border-box;
  padding: 49px 53px 0 60px;
  position: relative;
  cursor: pointer;
  .name {
    font-size: 30px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
  }
  .duration {
    font-size: 24px;
    font-weight: bold;
    color: rgba(255, 235, 190, 1);
  }
  .price {
    font-size: 41px;
    color: rgba(255, 255, 255, 1);
  }
  .currency {
    width: 36px;
  }
  .logo {
    position: absolute;
    right: 56px;
    bottom: 50px;
  }
  &.current {
    background-image: url(../../images/vip-back-selected.png);
  }
}

.buy {
  padding: 50px 0;
}

.contract {
  text-align: center;
  margin-bottom: 30px;
  img,
  a {
    vertical-align: middle;
  }
  a {
    font-size: 18px;
    color: #8c8c8c;
    margin-left: 4px;
  }
}
</style>
