<template>
  <div style="display:inline-block;">
    <el-select
      v-model="province_id"
      placeholder="请选择省"
      @change="onProvinceChange"
      style="width:150px; margin-right:10px;"
    >
      <el-option v-for="item in provinces" :value="item.id" :key="item.id" :label="item.area_name"></el-option>
    </el-select>
    <el-select
      v-model="city_id"
      placeholder="请选择市"
      @change="onCityChange"
      style="width:150px; margin-right:10px;"
    >
      <el-option v-for="item in cities" :value="item.id" :key="item.id" :label="item.area_name"></el-option>
    </el-select>
    <el-select
      v-model="district_id"
      placeholder="请选择区"
      @change="onDistrictChange"
      style="width:150px; margin-right:10px;"
    >
      <el-option v-for="item in districts" :value="item.id" :key="item.id" :label="item.area_name"></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  name: "AreaSelect",
  data() {
    return {
      province_id: 0,
      city_id: 0,
      district_id: 0,
      provinces: [],
      cities: [],
      districts: []
    };
  },
  props: ["provinceId", "cityId", "districtId"],
  async created() {
    this.province_id = this.provinceId || "";
    this.city_id = this.cityId || "";
    this.district_id = this.districtId || "";
    this.provinces = await this.getArea(0);
    if (this.province_id > 0) {
      this.cities = await this.getArea(this.province_id);
    }
    if (this.city_id > 0) {
      this.districts = await this.getArea(this.city_id);
    }
  },
  methods: {
    onChange() {
      this.$emit("change", {
        provinceId: this.province_id,
        cityId: this.city_id,
        districtId: this.district_id
      });
    },
    async getArea(parent) {
      return await this.$http.get("/api/app/sonArea/" + parent);
    },
    async onProvinceChange() {
      this.cities = await this.getArea(this.province_id);
      this.districts = [];
      this.city_id = "";
      this.district_id = "";
      this.onChange();
    },
    async onCityChange() {
      this.districts = await this.getArea(this.city_id);
      this.district_id = "";
      this.onChange();
    },
    onDistrictChange() {
      this.onChange();
    }
  },
  watch: {
    provinceId: async function(newValue) {
      if (this.province_id == newValue) {
        return;
      }
      this.province_id = newValue;
      this.cities = await this.getArea(newValue);
    },
    cityId: async function(newValue) {
      if (this.city_id == newValue) {
        return;
      }
      this.city_id = newValue;
      this.districts = await this.getArea(newValue);
    },
    districtId: function(newValue) {
      if (this.district_id == newValue) {
        return;
      }
      this.district_id = newValue;
    }
  }
};
</script>
