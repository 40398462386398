<template>
  <div class="main-content clear">
    <div class="header">
      <div class="name">{{report.homework_name}}</div>
      <div class="point" v-if="report.report_status==3">{{report.point}}</div>
      <div class="info">
        <img class="avatar" :src="$store.state.user_info.avatar" />
        <div>
          <div class="name-wrap">
            <span class="stu-name">{{$store.state.user_info.nickname}}</span>
            <span class="mobile">{{$store.state.user_info.mobile}}</span>
          </div>
          <div class="course-name">{{report.course_name}}</div>
          <div class="count-wrap">
            <span>本次应交：</span>
            <span class="count">{{report.objective_sum}}</span>
            <span>道客观题、</span>
            <span class="count">{{report.subjective_sum}}</span>
            <span>道主观题</span>
            <span class="real">本次实交：</span>
            <span class="count">{{report.submit_objective_num}}</span>
            <span>道客观题、</span>
            <span class="count">{{report.submit_subjective_num}}</span>
            <span>道主观题</span>
          </div>
          <div class="count-wrap">
            <span style="margin-right:30px;">
              <span>总分：</span>
              <span class="num-red">{{report.score}}</span>
              <span>分</span>
            </span>
            <span style="margin-right:30px;">
              <span>通过分数：</span>
              <span class="num-red">{{report.pass_score}}</span>
              <span>分</span>
            </span>
          </div>
        </div>
        <div class="progress-wrapper">
          <div class="progress" v-if="report.objectiveRate !== undefined">
            <el-progress
              type="circle"
              :percentage="report.objectiveRate"
              :stroke-width="10"
              :width="80"
              :show-text="false"
            ></el-progress>
            <p class="text">
              <span>客观题</span>
              <span>得分</span>
              <span class="score">{{report.submit_objective_point}}</span>
            </p>
          </div>
          <div class="progress" v-if="report.subjectiveRate !== undefined">
            <el-progress
              type="circle"
              :percentage="report.subjectiveRate"
              :stroke-width="10"
              :width="80"
              :show-text="false"
            ></el-progress>
            <p class="text">
              <span>简答题</span>
              <span>得分</span>
              <span class="score">{{report.submit_subjective_point}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="f-left">
        <el-tabs class="ques-tab" v-model="type">
          <el-tab-pane label="全部" name="all"></el-tab-pane>
          <el-tab-pane label="简答题" name="subjective"></el-tab-pane>
          <el-tab-pane label="客观题" name="objective"></el-tab-pane>
        </el-tabs>
        <ExamQuestion
          v-for="ques in filterList"
          :key="ques.id"
          :ques="ques"
          :answer="answer"
          :readonly="true"
          :hasAnalysis="true"
          class="ques-homework"
        ></ExamQuestion>
      </div>
      <div
        class="f-right"
        :style="{position:fixed?'fixed':'static',right:fixedRight+'px',top:'84px'}"
      >
        <div class="remark">
          <div class="title">作业总评</div>
          <div class="content">{{report.remark?report.remark:'待讲师批改后显示总评'}}</div>
        </div>
        <div class="answer-view">
          <div class="progress">
            <span>答题卡</span>
            <span class="primary-color">{{answerCount}}</span>
            <span>/{{total}}</span>
          </div>
          <div class="nums">
            <a
              class="num"
              :class="{'right':answerState[key]==1,'error':answerState[key]==0,'none':answerState[key]==0,'reviewing':answerState[key]=='reviewing','review':answerState[key]=='review'}"
              :key="key"
              v-for="(value,key) in answerState"
              @click="scrollTo(key)"
            >{{key}}</a>
          </div>
          <div class="legend">
            <span class="num right"></span>
            <span>正确{{rightCount}}</span>
            <span class="num error"></span>
            <span>错误{{errorCount}}</span>
            <span class="num review"></span>
            <span>已批阅{{reviewCount}}</span>
            <span class="num none"></span>
            <span>未做{{noneCount}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ExamTopicType } from "@/utils/enums";
import ExamQuestion from "../../components/ExamQuestion.vue";
export default {
  name: "MemberHomeworkDetail",
  components: {
    ExamQuestion
  },
  data() {
    return {
      id: this.$route.query.id,
      list: [],
      answer: {},
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      fixed: false,
      fixedRight: 0,
      total: 0,
      answerCount: 0,
      answerState: {},
      rightCount: 0,
      errorCount: 0,
      noneCount: 0,
      reviewCount: 0,
      report: {},
      statistics: {},
      type: "all"
    };
  },
  computed: {
    filterList() {
      if (this.type == "all") {
        return this.list;
      } else if (this.type == "subjective") {
        return this.list.filter(q => q.ques_type == 5);
      } else {
        return this.list.filter(q => q.ques_type != 5);
      }
    }
  },
  async created() {
    let data = await this.$http.get("/api/app/homework/detail/" + this.id);
    this.report = data.report_base;
    this.statistics = JSON.parse(this.report.statistics_detail);

    if (this.report.score) {
      this.report.objectiveRate =
        (this.report.submit_objective_point / this.report.score) * 100;
      this.report.subjectiveRate =
        (this.report.submit_subjective_point / this.report.score) * 100;
    } else {
      this.report.objectiveRate = 0;
      this.report.subjectiveRate = 0;
    }

    let subjectiveScore = 0;
    let objectiveScore = 0;
    let index = 1;
    data.question.forEach(ques => {
      if (ques.ques_type != 5) {
        objectiveScore += ques.score;
      } else {
        subjectiveScore += ques.score;
      }
      ques.id = ques.ques_id;
      if (!ques.id) {
        ques.id = index;
        ques.ques_id = index;
      }
      ques.index = index;
      ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
      if (typeof ques.ques_option === "string" && ques.ques_type != 5) {
        ques.ques_option = JSON.parse(ques.ques_option);
      }
      let answers = [];
      if (ques.user_answer) {
        let user_answer;
        if (typeof ques.user_answer === "string") {
          user_answer = JSON.parse(ques.user_answer);
        } else {
          user_answer = ques.user_answer;
        }
        if (ques.ques_type != 5) {
          user_answer.forEach(a => {
            if (ques.ques_type == 4) {
              if (a.answer) {
                answers.push(a.id + ")" + this.chars[a.answer] + " ");
              }
            } else {
              if (a.id) {
                answers.push(this.chars[a.id]);
              }
            }
          });
          ques.user_answer_label = answers.join("");
        } else {
          ques.user_answer_label = user_answer;
        }
      }
      if (ques.ques_type != 5) {
        if (answers.length > 0) {
          this.answerCount++;
          if (ques.is_right == 1) {
            this.rightCount++;
          } else {
            this.errorCount++;
          }
          this.$set(this.answerState, ques.index, ques.is_right == 1 ? 1 : 0);
        } else {
          this.$set(this.answerState, ques.index, -1);
          this.noneCount++;
        }
      } else {
        if (ques.user_answer.length == 0) {
          this.noneCount++;
          this.$set(this.answerState, ques.index, -1);
        } else {
          this.answerCount++;
          if (ques.review_status == 1) {
            this.reviewCount++;
            this.$set(this.answerState, ques.index, "review");
          } else {
            this.$set(this.answerState, ques.index, "reviewing");
          }
        }
      }
      if (ques.ques_type == 1 || ques.ques_type == 3) {
        let rightOption = ques.ques_option.filter(o => o.is_right == 1)[0];
        if (rightOption) {
          let id = rightOption.id;
          this.$set(this.answer, ques.ques_id, id);
          ques.answer = this.chars[id];
        }
      } else if (ques.ques_type == 2) {
        let ids = [];
        let answers = [];
        ques.ques_option
          .filter(o => o.is_right == 1)
          .forEach(o => {
            ids.push(o.id);
            answers.push(this.chars[o.id]);
          });
        this.$set(this.answer, ques.ques_id, ids);
        ques.answer = answers.join("");
      } else if (ques.ques_type == 4) {
        let answer = {};
        let answers = [];
        ques.ques_option.left.forEach(op => {
          answer[op.id] = op.is_right;
          answers.push(op.id + ")" + this.chars[op.is_right]);
        });
        this.$set(this.answer, ques.ques_id, answer);
        ques.answer = answers.join(" ");
      }
      index++;
    });
    this.total = index - 1;
    this.list = data.question;
  },
  mounted() {
    this.$nextTick(() => {
      let $ = window.$;
      $(window)
        .scroll(() => {
          this.setFixed();
        })
        .resize(() => {
          this.setFixed();
        });
    });
  },
  methods: {
    setFixed() {
      let $ = window.$;
      if ($(window).scrollTop() + 84 > 473) {
        this.fixed = true;
        this.fixedRight = ($(window).width() - 1200) / 2;
        if (this.fixedRight < 0) {
          this.fixedRight = 0;
        }
      } else {
        this.fixed = false;
      }
    },
    scrollTo(id) {
      let $ = window.$;
      let $ele = $("#" + id);
      if ($ele.length == 0) {
        return;
      }
      let top = $ele.offset().top - 84;
      $(window).scrollTop(top);
    }
  },
  watch: {
    filterList() {
      let index = 1;
      let answerState = {};
      let answerCount = 0;
      let noneCount = 0;
      let rightCount = 0;
      let errorCount = 0;
      let reviewCount = 0;
      this.filterList.forEach(ques => {
        ques.index = index;
        let answers = [];
        if (ques.user_answer) {
          let user_answer;
          if (typeof ques.user_answer === "string") {
            user_answer = JSON.parse(ques.user_answer);
          } else {
            user_answer = ques.user_answer;
          }
          if (ques.ques_type != 5) {
            user_answer.forEach(a => {
              if (ques.ques_type == 4) {
                if (a.answer) {
                  answers.push(a.id + ")" + this.chars[a.answer] + " ");
                }
              } else {
                if (a.id) {
                  answers.push(this.chars[a.id]);
                }
              }
            });
          }
        }
        if (ques.ques_type != 5) {
          if (answers.length > 0) {
            answerCount++;
            if (ques.is_right == 1) {
              rightCount++;
            } else {
              errorCount++;
            }
            answerState[ques.index] = ques.is_right == 1 ? 1 : 0;
          } else {
            answerState[ques.index] = -1;
            noneCount++;
          }
        } else {
          if (ques.user_answer.length == 0) {
            noneCount++;
            answerState[ques.index] = -1;
          } else {
            answerCount++;
            if (ques.review_status == 1) {
              reviewCount++;
              answerState[ques.index] = "review";
            } else {
              answerState[ques.index] = "reviewing";
            }
          }
        }
        index++;
      });
      this.answerCount = answerCount;
      this.noneCount = noneCount;
      this.rightCount = rightCount;
      this.errorCount = errorCount;
      this.reviewCount = reviewCount;
      this.total = index - 1;
      this.answerState = answerState;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.main-content {
  margin-bottom: 50px;
  background-color: #f0f2f5;
  .header {
    background-color: white;
    padding: 32px 25px;
    padding-bottom: 35px;
    margin-bottom: 30px;
    position: relative;
    .name {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC;
      margin-bottom: 24px;
      font-weight: 500;
    }
    .point {
      width: 80px;
      height: 55px;
      box-sizing: border-box;
      text-align: right;
      padding-right: 15px;
      position: absolute;
      color: red;
      font-size: 40px;
      line-height: 32px;
      top: 15px;
      right: 47px;
      background-image: url(../../images/exam/package/score-line.png);
      background-repeat: no-repeat;
      background-position: right bottom;
    }
    .info {
      border: 1px solid rgba(217, 217, 217, 1);
      border-radius: 10px;
      padding: 24px 20px;
      box-sizing: border-box;
      display: flex;
      position: relative;
      .avatar {
        width: 73px;
        height: 73px;
        border-radius: 50%;
        margin-right: 20px;
      }
      .name-wrap {
        line-height: 19px;
      }
      .stu-name {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: black;
        margin-right: 13px;
      }
      .mobile {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
      }
      .course-name {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
        padding: 14px 0;
        line-height: 13px;
      }
      .count-wrap {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
        line-height: 13px;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .num-red {
          color: #ee1f1f;
        }
      }
      .count {
        color: $primary-color;
        margin-right: 8px;
      }
      .real {
        margin-left: 29px;
      }
      .progress-wrapper {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        .progress {
          display: inline-block;
          margin-left: 30px;
          position: relative;
          .text {
            width: 100px;
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            font-size: 10px;
            color: #595959;
            .score {
              font-size: 12px;
              font-weight: 500;
              color: #ee1f1f;
            }
          }
        }
      }
    }
  }
  .f-left {
    width: 537px;
    background-color: white;
  }
  .f-right {
    width: 355px;
  }
}
.section {
  .title {
    background-color: #f7f7f7;
    padding: 16px 25px;
    font-size: 18px;
    color: #262626;
    span {
      margin-right: 5px;
    }
  }
}
.remark {
  border: 1px solid rgba(231, 233, 233, 1);
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
  padding: 20px;
  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(38, 38, 38, 1);
    line-height: 16px;
    position: relative;
    padding-left: 11px;
    margin-bottom: 15px;
    &::before {
      position: absolute;
      content: "";
      width: 3px;
      height: 14px;
      background: rgba(65, 105, 255, 1);
      border-radius: 2px;
      top: 1px;
      left: 0;
    }
  }
  .content {
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 5px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(89, 89, 89, 1);
    padding: 14px 18px 14px 16px;
  }
}
.answer-view {
  box-sizing: border-box;
  border-radius: 5px;
  .progress {
    background: rgba(247, 247, 247, 1);
    padding: 10px;
    font-size: 16px;
    .primary-color {
      font-size: 18px;
    }
  }
  .nums {
    background-color: white;
    padding: 0 12px 12px 12px;
    margin-bottom: 15px;
  }
  .num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 3px;
    color: #b8b8b8;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
    &.right {
      background-color: #30c596;
      color: white;
    }
    &.error {
      background-color: #ff5500;
      color: white;
    }
    &.reviewing {
      border-color: $primary-color;
      color: $primary-color;
    }
    &.review {
      background-color: $primary-color;
      color: white;
    }
    &:nth-child(9) {
      margin-right: 0;
    }
  }
  .legend {
    background-color: white;
    padding: 13px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    .num {
      margin-top: 0;
      margin-right: 5px;
      width: 14px;
      height: 14px;
      cursor: default;
    }
    span:nth-child(2),
    span:nth-child(4),
    span:nth-child(6) {
      margin-right: 25px;
    }
  }
}
.ques-homework {
  padding-top: 39px;
  /deep/ {
    .icon-result {
      top: 65px;
    }
    .options {
      padding-left: 0 !important;
    }
  }
}
.ques-tab {
  padding: 5px 24px 0 24px;
  /deep/ {
    .el-tabs__header {
      margin-bottom: 0;
    }
    .el-tabs__item {
      color: #595959;
      height: 50px;
      line-height: 50px;
    }
  }
}
</style>