<template>
  <div>
    <Calendar @onClickDay="onClickDay" :courseDate="times"></Calendar>
    <div class="list">
      <el-card
        class="item clear"
        v-for="(item, index) in list"
        :key="index"
        shadow="hover"
      >
        <div class="f-left main">
          <div class="time" v-if="item.start_play">
            {{ new Date(item.start_play * 1000).format("hh:mm") }}-{{
              new Date(item.end_play * 1000).format("hh:mm")
            }}
          </div>
          <div>
            <span class="type">{{ item.course_type_name }}</span>
            <span
              class="state"
              v-if="
                item.course_type == 2 ||
                item.course_type == 3 ||
                item.course_type == 4 ||
                item.course_type == 1
              "
            >
              <span v-if="item.live_status == 0">[未开始]</span>
              <span v-else-if="item.live_status == 1" class="living"
                >[直播中]</span
              >
              <span v-else-if="item.live_status == 2">[已结束]</span>
            </span>
            <span v-if="item.course_type == 7" class="state">
              <span v-if="item.live_status == 0">[未开始]</span>
              <span v-else-if="item.live_status == 1">[上课中]</span>
              <span v-else-if="item.live_status == 2">[已结束]</span>
            </span>
            <span
              class="title"
              @click="goToPlay(item)"
              v-if="item.course_type != 1"
              >{{ item.title }}
            </span>
            <span class="title" @click="goToPlay(item)" v-else>{{
              item.oto_desc
            }}</span>
          </div>
          <div
            class="homework"
            v-if="
              item.homework_name &&
              $store.state.commonSetting.homework_module.is_open == '1'
            "
          >
            {{
              item.homework_name +
              (item.homework_num > 1
                ? " 等" + item.homework_num + "个作业"
                : "")
            }}
          </div>
        </div>
        <div class="f-right">
          <span
            class="live-state f-left"
            v-if="
              (item.course_type == 1 ||
                item.course_type == 2 ||
                item.course_type == 3 ||
                item.course_type == 4) &&
              (item.live_status == 1 || item.live_status == 2)
            "
          >
            <span
              v-if="item.live_status == 1"
              class="period-living"
              @click.stop="goToPlay(item)"
            >
              直播
            </span>
            <span
              v-else-if="
                item.live_status == 2 && item.cloud_class_type === 'baijiayun'
              "
            >
              <span v-if="item.is_play_back" @click.stop="goToPlay(item)">
                回放
              </span>
              <span v-else>暂无回放</span>
            </span>

            <span
              v-else-if="
                item.live_status == 2 && item.cloud_class_type === 'plaso'
              "
            >
              <span @click.stop="goToPlay(item)"> 回放 </span>
            </span>
          </span>
          <router-link
            class="live-state f-left"
            v-if="
              item.is_submit &&
              $store.state.commonSetting.homework_module.is_open == '1'
            "
            :to="{
              name: 'MemberHomework',
              query: {
                course: item.course_basis_id,
                period: item.course_chapter_id,
              },
            }"
            >作业提交</router-link
          >
          <router-link
            class="live-state f-left"
            v-else-if="
              item.is_view &&
              $store.state.commonSetting.homework_module.is_open == '1'
            "
            :to="{
              name: 'MemberHomework',
              query: {
                course: item.course_basis_id,
                period: item.course_chapter_id,
              },
            }"
            >查看作业</router-link
          >
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import Calendar from "@/components/Calendar.vue";
import * as enums from "@/utils/enums.js";

export default {
  name: "MemberCalendar",
  data() {
    return {
      times: [],
      list: [],
    };
  },
  components: {
    Calendar,
  },
  async created() {
    this.times = await this.$http.get("/api/app/study/schedule");
    await this.getData(parseInt(new Date().getTime() / 1000));
  },
  methods: {
    async getData(time) {
      let list = await this.$http.get("/api/app/study/live/" + time);
      list.forEach((item) => {
        item.course_type_name = enums.CouseType.getValue(item.course_type);
      });
      this.list = list;
    },
    async onClickDay(date) {
      await this.getData(parseInt(new Date(date).getTime() / 1000));
    },
    async enterClass(item) {
      const meetingId = `${item.course_basis_id}_${item.course_chapter_id}`;
      const routeData = this.$router.resolve({
        name: "CloudClass",
        query: { meetingId },
      });
      window.open(routeData.href, "_blank");
    },
    async goToPlay(item) {
      if (item.mock_live_url) {
        const url = item.mock_live_url.replace("http://", "https://");
        this.$router.push({
          name: "Video",
          query: {
            course_id: this.course_id,
            period_id: item.periods_id,
            video_id: item.video_id,
            course_type: this.course_type,
            id: item.id,
            parent_id: item.parent_id,
            mlurl: url,
          },
        });

        return;
      }

      if (item.cloud_class_type === "plaso") {
        this.enterClass(item);
        return;
      }
      this.$livePlay(
        item.course_basis_id,
        item.course_type,
        item.course_chapter_id
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.list {
  padding: 24px;
}
.item {
  padding: 20px 24px;
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 20px;
  /deep/ {
    .el-card__body {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
  .main {
    flex: 1;
  }
  .time {
    font-size: 14px;
    color: rgba(89, 89, 89, 1);
    margin-bottom: 10px;
  }
  .type {
    font-size: 11px;
    color: white;
    background-color: $primary-color;
    padding: 2px 5px;
  }
  .state {
    font-size: 14px;
    color: #d9d9d9;
    margin: 0 5px;
  }
  .living {
    color: $primary-color;
  }
  .title {
    font-size: 14px;
    color: rgba(140, 140, 140, 1);
    cursor: pointer;
  }
  .homework {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    color: rgba(183, 183, 183, 1);
    padding-top: 5px;
  }
  .live-state {
    font-size: 12px;
    color: $primary-color;
    cursor: pointer;
    width: 66px;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid $primary-color;
    margin-left: 20px;
    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }
}
</style>
