<template>
  <div class="memeber-evaluation-detail">
    <div class="question">
      <div class="stem">
        <span
          class="type"
          v-if="question.ques_type"
        >({{ExamTopicType.getValue(question.ques_type)}})</span>
        <div class="rich-text" v-html="question.ques_stem"></div>
      </div>
      <div class="option-wrapper">
        <div class="single" v-if="question.ques_type == 1 || question.ques_type == 3">
          <el-radio-group class="options" :value="result" disabled>
            <el-radio
              class="option"
              v-for="(item, index) in question.ques_option"
              :key="index"
              :label="item.id"
            >
              <span>{{$pub.getLetterNumber(item.id)}}、</span>
              <div class="option-content" v-html="item.content"></div>
            </el-radio>
          </el-radio-group>
        </div>
        <div class="multi" v-if="question.ques_type == 2">
          <el-checkbox-group class="options" :value="result" disabled>
            <el-checkbox
              class="option"
              v-for="(item, index) in question.ques_option"
              :key="index"
              :label="item.id"
            >
              <span>{{$pub.getLetterNumber(item.id)}}、</span>
              <div class="option-content" v-html="item.content"></div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-if="question.ques_type == 4">
          <div class="connection">
            <div class="part-ques">
              <p class="part-title">题目</p>
              <div class="ques" v-for="(item, index) in question.ques_option.left" :key="index">
                <div class="stem">
                  <span class="stem-number">{{index + 1}})</span>
                  <div class="stem-text" v-html="item.content"></div>
                </div>
                <div class="answer">
                  <span class="tag">答案</span>
                  <el-radio-group :value="item.is_right" disabled>
                    <el-radio
                      v-for="(item, index) in question.ques_option.right"
                      :key="index"
                      :label="item.id"
                    >{{$pub.getLetterNumber(item.id)}}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div class="part-option">
              <p class="part-title">选项</p>
              <div class="option" v-for="(item, index) in question.ques_option.right" :key="index">
                <div class="option-number">选项{{$pub.getLetterNumber(item.id)}}</div>
                <div class="option-text" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="watch-answer">
        <div class="bar">
          <span class="right">
            <span>正确答案：</span>
            <span class="tag">{{getCurDisplay()}}</span>
          </span>
          <div class="left">
            <p @click="onCollect">
              <img
                v-show="question.collect_id"
                src="~@/assets/images/icon-collect-active@3x.png"
                alt
              />
              <img v-show="!question.collect_id" src="~@/assets/images/icon-collect@3x.png" alt />
              <span :class="{active: question.collect_id}">收藏</span>
            </p>
            <p @click="onCorrection">
              <img src="~@/assets/images/icon-stop@3x.png" alt />
              <span>纠错</span>
            </p>
          </div>
        </div>
        <div class="point">
          <span class="title">考点：</span>
          <span class="tag" v-for="(item, index) in question.points" :key="index">{{item}}</span>
          <span class="empty-tag" v-if="!question.points">暂无考点</span>
        </div>
        <div class="analysis">
          <span class="title">题目解析：</span>
          <div class="rich-text" v-html="question.ques_analysis"></div>
        </div>
      </div>
    </div>
    <CorrectionDlg v-model="showCorrectionDlg" :quesID="quesID"></CorrectionDlg>
  </div>
</template>

<script>
import { ExamTopicType } from "@/utils/enums";
import CorrectionDlg from "@/components/CorrectionDlg";
export default {
  name: "memeber-evaluation-detail",
  components: {
    CorrectionDlg
  },
  data() {
    return {
      ExamTopicType,
      quesID: this.$route.query.ques_id,
      question: {},
      showCorrectionDlg: false
    };
  },
  computed: {
    // 单选、多选、判断的结果，连线题直接用 is_right 字段
    result() {
      let ret;
      switch (this.question.ques_type) {
        case 1:
        case 3:
          this.question.ques_option.forEach(i => {
            if (i.is_right == 1) {
              ret = i.id;
            }
          });
          break;
        case 2:
          {
            ret = [];
            this.question.ques_option.forEach(i => {
              if (i.is_right == 1) {
                ret.push(i.id);
              }
            });
          }
          break;
      }
      return ret;
    }
  },
  created() {
    this.requestData();
  },
  methods: {
    // on event
    onCollect() {
      const ques = this.question;
      if (ques.collect_id) {
        this.$http
          .put(`/api/app/collect/cancel/${ques.collect_id}/3`)
          .then(() => {
            ques.collect_id = 0;
          });
      } else {
        this.$http
          .post("/api/app/collect", {
            ques_id: this.quesID,
            type: 3
          })
          .then(data => {
            ques.collect_id = data;
          });
      }
    },
    onCorrection() {
      this.showCorrectionDlg = true;
    },
    // other
    getCurDisplay() {
      const ques = this.question;
      let ret = "";
      switch (ques.ques_type) {
        case 1:
        case 3:
          ques.ques_option.forEach(i => {
            if (i.is_right == 1) {
              ret = this.$pub.getLetterNumber(i.id);
            }
          });
          break;
        case 2:
          ques.ques_option.forEach(i => {
            if (i.is_right == 1) {
              ret += this.$pub.getLetterNumber(i.id) + ",";
            }
          });
          ret = ret.slice(0, -1);
          break;
        case 4:
          ques.ques_option.left.forEach(i => {
            ret += this.$pub.getLetterNumber(i.is_right) + ",";
          });
          ret = ret.slice(0, -1);
          break;
      }
      return ret;
    },
    // request
    requestData() {
      this.$http.get(`/api/app/myCollect/detail/${this.quesID}`).then(data => {
        this.question = data;
        if (this.question.point_name) {
          this.question.points = this.question.point_name.split(",");
        }
        if (!this.question.ques_analysis) {
          this.question.ques_analysis = "暂无解析";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.memeber-evaluation-detail {
  padding: 20px;
  min-height: 700px;
  .question {
    .stem {
      font-size: 18px;
      font-weight: 500;
      color: #333;
      .type {
        color: $primary_color;
        margin-right: 8px;
      }
    }
    .option-wrapper {
      position: relative;
      .options {
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 30px;
        .option {
          margin-bottom: 15px;
          color: #333;
          &:last-child {
            margin-bottom: 0;
          }
          .option-content {
            display: inline-block;
          }
        }
      }
      .connection {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .part-title {
          font-size: 18px;
          font-weight: 500;
          color: #333;
          margin-bottom: 20px;
        }
        .part-ques {
          margin-left: 10px;
          .ques {
            width: 353px;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            .stem {
              color: #333;
              font-weight: 400;
              font-size: 16px;
              margin-bottom: 10px;
              .stem-number {
                margin-right: 5px;
              }
              .stem-text {
                display: inline-block;
              }
            }
            .answer {
              .tag {
                margin-right: 10px;
              }
            }
          }
        }
        .part-option {
          .option {
            width: 353px;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            .option-number {
              display: inline-block;
              width: 65px;
              height: 25px;
              border: 1px solid $primary_color;
              color: $primary_color;
              border-radius: 3px;
              font-size: 16px;
              line-height: 25px;
              text-align: center;
              margin-right: 10px;
            }
            .option-text {
              display: inline-block;
            }
          }
        }
      }
    }
    .watch-answer {
      margin-top: 20px;
      font-size: 16px;
      color: #666;
      .bar {
        height: 50px;
        line-height: 50px;
        background-color: #f7f7f7;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        .right {
          margin-right: 50px;
          .tag {
            color: $primary_color;
          }
        }
        .left {
          display: flex;
          justify-content: space-between;
          width: 150px;
          p {
            font-size: 16px;
            color: #595959;
            cursor: pointer;
            .active {
              color: $primary_color;
            }
            img {
              width: 17px;
              height: 17px;
              margin-right: 5px;
            }
            * {
              vertical-align: middle;
            }
          }
        }
      }
      .point {
        padding: 20px;
        .tag {
          display: inline-block;
          background-color: $primary_color;
          border-radius: 5px;
          color: #fff;
          padding: 0 10px;
          margin-right: 5px;
        }
        .empty-tag {
          color: #333;
        }
      }
      .analysis {
        padding: 0 20px;
        display: flex;
        .title {
          width: 90px;
        }
        .rich-text {
          color: #333;
        }
      }
    }
  }
}
</style>