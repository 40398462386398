<template>
  <div>
    <ul class="clear tabs">
      <li class="tab-item"
          :class="{'tab-item-current':current==item.type}"
          @click="changeTab(item.type)"
          v-for="item in types"
          :key="item.type">
        <span>{{item.label}}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <div class="tab-content">
      <div class="clear courses"
           v-if="current==1">
        <div v-if="courses.length">
          <div class="item"
               v-for="course in courses"
               :key="course.collect_id">
            <div class="course_left">
              <img :src="course.cover">
            </div>
            <div class="course_right">
              <a class="link-cancel"
                 @click="cancelCollect(course.collect_id,1)">取消收藏</a>
              <router-link :to="{name:'CourseShow',params:{id:course.course_id}}">
                <div class="title">{{course.title}}</div>
              </router-link>
              <div v-if="course.start_play_date>0">
                <span class="time">
                  <img class="clock"
                       src="../../images/clock.png" />
                  <span>{{new Date(course.start_play_date*1000).format("MM月dd日 hh:mm")}}</span>
                  <span>~</span>
                  <span>{{new Date(course.end_play_date*1000).format("MM月dd日 hh:mm")}}</span>
                  <span class="line">|</span>
                  <span>共{{course.section_num}}个课时</span>
                </span>
              </div>
              <div class="bottom clear">
                <div v-if="course.teachers.length<=6"
                     style="overflow:hidden;">
                  <div class="f-left teacher"
                       v-for="(teacher,index) in course.teachers"
                       :key="index">
                    <img class="avatar"
                         :src="teacher.avatar" />
                    <div class="name">{{teacher.name}}</div>
                  </div>
                </div>
                <div class="teachers"
                     v-if="course.teachers.length>6">
                  <swiper class="swiper-box"
                          :options="swiperOption">
                    <div class="f-left teacher swiper-slide"
                         v-for="(teacher,index) in course.teachers"
                         :key="index">
                      <img class="avatar"
                           :src="teacher.avatar" />
                      <div class="name">{{teacher.name}}</div>
                    </div>
                  </swiper>
                </div>
                <div :class="course.start_play_date > 0 ? '' : 'mt20'">
                  <span class="price">
                    <Currency class="currency"></Currency>
                    <span>{{course.price|price}}</span>
                  </span>
                  <strike v-if="course.underlined_price">
                    <Currency class="currency-mini"></Currency>
                    <span>{{course.underlined_price|price}}</span>
                  </strike>
                  <span class="number">{{course.sales_num}}人已购买</span>
                </div>
              </div>
            </div>
          </div>
          <el-pagination background
                         @current-change="handleCourseChange"
                         :current-page="course_page"
                         :page-size="course_limit"
                         layout="prev, pager, next"
                         :total="course_total"
                         v-if="course_total>15"></el-pagination>
        </div>
        <div v-else
             class="center"
             style="padding:60px 0;">暂无收藏课程</div>
      </div>
      <div class="clear teachers"
           v-if="current==2">
        <div v-if="teachers.length">
          <div class="item"
               v-for="teacher in teachers"
               :key="teacher.collect_id">
            <a class="lnk-cancel"
               @click="cancelCollect(teacher.collect_id,2)">取消收藏</a>
            <router-link :to="{name:'OtoTeacherShow',params:{id:teacher.teacher_id}}">
              <div>
                <img class="avatar"
                     :src="teacher.avatar" />
              </div>
            </router-link>
            <router-link :to="{name:'OtoTeacherShow',params:{id:teacher.teacher_id}}">
              <div class="name">
                <span>{{teacher.teacher_name}}</span>
                <span v-if="teacher.level_name">({{teacher.level_name}})</span>
              </div>
            </router-link>
            <div class="introduction">{{teacher.introduction}}</div>
          </div>
          <div style="clear:both;"></div>
          <el-pagination background
                         @current-change="handleTeaChange"
                         :current-page="tea_page"
                         :page-size="tea_limit"
                         layout="prev, pager, next"
                         :total="tea_total"
                         v-if="tea_total>15"></el-pagination>
        </div>
        <div v-else
             class="center"
             style="padding:60px 0;">暂无收藏名师</div>
      </div>
      <div class="clear books"
           v-if="current==4">
        <div v-if="books.length">
          <div class="item"
               v-for="book in books"
               :key="book.collect_id"
               @click="$router.push({name:'BookDetail',params:{id:book.book_id}})">
            <div class="item-left">
              <img class="avatar"
                   :src="book.cover_img" />
              <p>作者：{{book.author}}</p>
            </div>
            <div class="item-right">
              <div>
                <p class="item-title">{{book.book}}</p>
                <i class="item-collect"
                   @click.stop="cancelCollect(book.collect_id,4)"></i>
              </div>
              <p class="item-info">{{book.instruction}}</p>
              <p class="item-price">
                <Currency class="currency"></Currency>
                <span>{{book.price | price}}</span>
              </p>
            </div>
          </div>
          <div style="clear:both;"></div>
          <el-pagination background
                         @current-change="handleBookChange"
                         :current-page="book_page"
                         :page-size="book_limit"
                         layout="prev, pager, next"
                         :total="book_total"
                         v-if="book_total>15"></el-pagination>
        </div>
        <div v-else
             class="center"
             style="padding:60px 0;">暂无收藏图书</div>
      </div>
      <div class="clear exercises"
           v-if="current==5">
        <div v-if="exercise.length">
          <div class="item"
               v-for="exercise in exercise" @click="$router.push({name:'ExerciseShow',params:{id:exercise.collect_id}})"
               :key="exercise.collect_id">
            <div class="course_left">
              <img :src="exercise.cover_img">
            </div>
            <div class="course_right">
              <a class="link-cancel"
                 @click="cancelCollect(exercise.collect_id,5)">取消收藏</a>
              <!-- :to="{name:'CourseShow',params:{id:exercise.course_id}}" -->
              <router-link :to="{}">
                <div class="title">{{exercise.exercise}}</div>
              </router-link>
              <div class="sales_num">{{exercise.sales_num}}人已做题</div>
              <div class="bottom clear">
                <div class="price_bottom">
                  <span class="price">
                    <Currency class="currency"></Currency>
                    <span>{{exercise.price|price}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <el-pagination background
                         @current-change="handleExerciseChange"
                         :current-page="exercise_page"
                         :page-size="exercise_limit"
                         layout="prev, pager, next"
                         :total="exercise_total"
                         v-if="exercise_total>15"></el-pagination>
        </div>
        <div v-else
             class="center"
             style="padding:60px 0;">暂无收藏章节练习</div>
      </div>
      <div class="clear exam"
           v-if="current==6">
        <div v-if="exam.length">
          <div class="item"
               v-for="exam_item in exam"  @click="$router.push({name:'ExamSimulationDes',params:{id:exam_item.package_id}})"
               :key="exam_item.collect_id">
            <div class="course_left">
              <img :src="exam_item.cover_img">
            </div>
            <div class="course_right">
              <a class="link-cancel"
                 @click="cancelCollect(exam_item.collect_id,5)">取消收藏</a>
              <!-- :to="{name:'CourseShow',params:{id:exercise.course_id}}" -->
              <router-link :to="{}">
                <div class="title">{{exam_item.exercise}}</div>
              </router-link>
              <div class="sales_num">{{exam_item.sales_num}}人已做题</div>
              <div class="bottom clear">
                <div class="price_bottom">
                  <span class="price">
                    <Currency class="currency"></Currency>
                    <span>{{exam_item.price|price}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <el-pagination background
                         @current-change="handleExamChange"
                         :current-page="exam_page"
                         :page-size="exam_limit"
                         layout="prev, pager, next"
                         :total="exam_total"
                         v-if="exam_total>15"></el-pagination>
        </div>
        <div v-else
             class="center"
             style="padding:60px 0;">暂无收藏模拟考试</div>
      </div>
      <div class="clear question"
           v-if="current==3">
        <MemberEvaluationCollect />
      </div>
    </div>
  </div>
</template>
<script>
import MemberEvaluationCollect from './MemberEvaluationCollect';
export default {
  name: "MemberCollect",
  components: {
    MemberEvaluationCollect
  },
  data () {
    return {
      current: "1",
      types: [
        {
          type: "1",
          label: "课程"
        },
        {
          type: "4",
          label: "图书"
        },
        {
          type: "5",
          label: "章节练习"
        },
        {
          type: "6",
          label: "模拟考试"
        },
        {
          type: "3",
          label: "习题"
        },
        {
          type: "2",
          label: "讲师"
        },

      ],
      courses: [],
      teachers: [],
      books: [],
      exercise: [],
      exam: [],
      question: [],
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        loop: true,
        observer: true,
        observeParents: true,
        slidesPerView: 6,
        slidesPerGroup: 1,
        spaceBetween: 16
      },
      course_page: 1,
      course_limit: 15,
      course_total: 0,
      tea_page: 1,
      tea_limit: 15,
      tea_total: 0,
      book_page: 1,
      book_limit: 15,
      book_total: 0,
      exercise_page: 1,
      exercise_limit: 15,
      exercise_total: 0,
      question_page: 1,
      question_limit: 15,
      question_total: 0,
      exam_page: 1,
      exam_limit: 15,
      exam_total: 0
    };
  },
  async created () {
    await this.getCourses();
    await this.getTeachers();
    await this.getBooks();
    await this.getExercise();
    await this.getExam();
    await this.getQuestion();
  },
  methods: {
    async getCourses () {
      let data = await this.$http.get("/api/app/collect", {
        type: 1,
        page: this.course_page,
        limit: this.course_limit
      });
      let courses = data.list;
      courses.forEach(c => {
        if (!c.teachers || !c.teachersAvatar) {
          c.teachers = [];
          return;
        }
        let teachers = [];
        let names = c.teachers.split(",");
        let avatars = c.teachersAvatar.split(",");
        for (let i = 0; i < names.length && i < avatars.length; i++) {
          let name = names[i];
          let avatar = avatars[i];
          if (!name || !avatar) {
            continue;
          }
          teachers.push({ name: name, avatar: avatar });
        }
        c.teachers = teachers;
      });
      this.courses = courses;
      this.course_total = data.total;
    },
    async getTeachers () {
      let data = await this.$http.get("/api/app/collect", {
        type: 2,
        page: this.tea_page,
        limit: this.tea_limit
      });
      this.teachers = data.list;
      this.tea_total = data.total;
    },
    async getBooks () {
      let data = await this.$http.get("/api/app/collect", {
        type: 4,
        page: this.book_page,
        limit: this.book_limit
      });
      this.books = data.list;
      this.book_total = data.total;
    },
    async getExercise () {
      let data = await this.$http.get("/api/app/collect", {
        type: 5,
        page: this.exercise_page,
        limit: this.exercise_limit
      });
      this.exercise = data.list;
      this.exercise_total = data.total;
    },
    async getExam () {
      let data = await this.$http.get("/api/app/collect", {
        type: 6,
        page: this.exam_page,
        limit: this.exam_limit
      });
      this.exam = data.list;
      this.exam_total = data.total;
    },
    async getQuestion () {
      let data = await this.$http.get("/api/app/collect", {
        type: 6,
        page: this.question_page,
        limit: this.question_limit
      });
      this.question = data.list;
      this.question_total = data.total;
    },
    changeTab (tab) {
      this.current = tab;
    },
    async cancelCollect (id, type) {
      if (type == 2) {
        await this.$confirm("确定要取消收藏该名师么？", "提示", {
          type: "warning"
        });
      } else if (type == 1) {
        await this.$confirm("确定要取消收藏该课程么？", "提示", {
          type: "warning"
        });
      } else if (type == 4) {
        await this.$confirm("确定要取消收藏该图书么？", "提示", {
          type: "warning"
        });
      }
      else if (type == 5) {
        await this.$confirm("确定要取消收藏该章节练习么？", "提示", {
          type: "warning"
        });
      }
      await this.$http.put("/api/app/collect/cancel/" + id + "/" + type);
      if (type == 2) {
        await this.getTeachers();
      } else if (type == 1) {
        await this.getCourses();
      } else if (type == 4) {
        await this.getBooks();
      } else if (type == 5) {
        await this.getExercise();
      }
    },
    async handleCourseChange (page) {
      this.course_page = page;
      await this.getCourses();
    },
    async handleTeaChange (page) {
      this.tea_page = page;
      await this.getTeachers();
    },
    async handleBookChange (page) {
      this.book_page = page;
      await this.getBooks();
    },
    async handleExerciseChange (page) {
      this.exercise_page = page;
      await this.getExercise();
    },
    async handleExamChange (page) {
      this.exam_page = page;
      await this.getExam();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.tabs {
  background-color: #fff;
  .tab-item {
    width: 120px;
    color: #333333;
    .tab-line {
      left: 35px;
      width: 52px;
    }
  }
  .tab-item-current {
    color: $primary_color;
  }
}
.tab-item span {
  border-right: none;
}
.tab-content {
  background-color: white;
  padding: 24px;
}
.courses,
.exercises ,
.exam{
  .item {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    // align-items: center;
    .course_left {
      width: 125px;
      height: 125px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .course_right {
      position: relative;
      width: 680px;
      margin-left: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      width: 610px;
      font-size: 15px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    .time {
      font-size: 14px;
      color: rgba(89, 89, 89, 1);
    }
    .name {
      // width: 62px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .clock {
      width: 11px;
      height: 11px;
      margin-right: 5px;
    }
    .line {
      margin: 0 10px;
    }
    .link-cancel {
      cursor: pointer;
      font-size: 14px;
      color: rgba(140, 140, 140, 1);
      position: absolute;
      top: 0;
      right: 0;
    }
    .bottom {
      position: relative;
      min-height: 25px;
      // .f-right {
      //   position: absolute;
      //   bottom: 0;
      //   right: 0;
      // }
      .teachers {
        width: 500px;
      }
      .teacher {
        text-align: center;
        margin-right: 27px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        padding-top: 10px;
      }
      .avatar {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        // margin-bottom: 5px;
      }
      .number {
        font-size: 14px;
        color: rgba(140, 140, 140, 1);
        margin-left: 30px;
      }
      strike {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
        margin-left: 5px;
      }
      .price {
        font-size: 19px;
        font-weight: bold;
        color: rgba(238, 31, 31, 1);
      }
    }
    .currency {
      width: 16px;
    }
    .currency-mini {
      width: 12px;
    }
  }
}
.teachers {
  .item {
    width: 274px;
    height: 300px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    border: 1px solid rgba(217, 217, 217, 1);
    float: left;
    margin-right: 23px;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 13px;
    .avatar {
      width: 122px;
      height: 122px;
      border-radius: 100%;
      margin-top: 35px;
    }
    .name {
      padding: 22px 0 16px 0;
      font-size: 17px;
      font-weight: bold;
      color: rgba(38, 38, 38, 1);
    }
    .introduction {
      font-size: 14px;
      color: rgba(140, 140, 140, 1);
      text-align: justify;
      max-height: 133px;
      overflow: hidden;
      word-break: break-all;
      width: 224px;
      margin: 0 25px;
      box-sizing: border-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .lnk-cancel {
      cursor: pointer;
      color: $primary-color;
      position: absolute;
      top: 16px;
      right: 16px;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.books {
  .item {
    display: flex;
    padding: 20px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    border: 1px solid rgba(217, 217, 217, 1);
    cursor: pointer;
    .item-left {
      margin-right: 20px;
      .avatar {
        width: 101px;
        height: 124px;
      }
      p {
        width: 101px;
        height: 23px;
        margin-top: 12px;
        background: linear-gradient(
          90deg,
          rgba(255, 51, 64, 1) 0%,
          rgba(247, 99, 105, 1) 100%
        );
        border-radius: 12px;
        font-size: 11px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 23px;
        text-align: center;
        overflow: hidden;
      }
    }
    .item-right {
      > div {
        display: flex;
        .item-title {
          flex: 1;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(32, 36, 42, 1);
          line-height: 25px;
        }
        .item-collect {
          flex: none;
          background: url("../../images/book/love@3x.png") no-repeat center
            center;
          height: 25px;
          width: 30px;
          background-size: 12px 11px;
        }
      }
      .item-info {
        margin-top: 4px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(140, 140, 140, 1);
        line-height: 20px;
        overflow: hidden;
        height: 102px;
      }
      .item-price {
        text-align: right;
        font-size: 17px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(231, 77, 61, 1);
        line-height: 32px;
        .currency {
          width: 16px;
        }
      }
    }
  }
}
.mt20 {
  margin-top: 20px;
}
.exercises {
  .sales_num {
    color: #666666;
    font-size: 12px;
    margin-top: 10px;
  }
  .price_bottom {
    margin-top: 50px;
  }
}
</style>
