<template>
  <div class="my-evaluation">
    <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{'tab-item-current':curTab==item.id}"
        @click="onChangeTab(item)"
        v-for="(item, index) in tabs"
        :key="index"
      >
        <span>{{item.name}}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "my-evaluation",
  data() {
    return {
      tabs: [
        { id: 0, name: "测评记录", url: "MemberEvaluationTest" },
        { id: 1, name: "错题记录", url: "MemberEvaluationMistake" },
        { id: 2, name: "习题收藏", url: "MemberEvaluationCollect" }
      ],
      curTab: 0
    };
  },
  created() {
    this.initTab();
  },
  methods: {
    // on event
    onChangeTab(item) {
      this.curTab = item.id;
      this.$router.replace({
        name: item.url
      });
    },
    // other
    initTab() {
      this.curTab = 0;
      for (const i of this.tabs) {
        if (i.url == this.$route.name) {
          this.curTab = i.id;
          break;
        }
      }
    }
    // request
  }
};
</script>

<style lang="scss" scoped>
</style>