<template>
  <div class="member-evaluation-collect">
    <el-form inline>
      <el-form-item>
        <el-select v-model="searchCondition.ques_type" placeholder="全部题目类型" clearable>
          <el-option
            v-for="(item, index) in examTopicTypes"
            :key="index"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="searchCondition.bank"
          placeholder="请输入题库名称关键字"
          @keydown.native.prevent.enter="onSearch"
          clearable
        >
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="searchCondition.ques_stem"
          placeholder="请输入题干名称关键字"
          @keydown.native.prevent.enter="onSearch"
          clearable
        >
          <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <Grid
      ref="grid"
      url="/api/app/collect"
      method="get"
      selectType="multi"
      :search="searchCondition"
      :columns="tableColumns"
      :formatter="onFormatter"
      @selection-change="onSelectionChange"
    >
      <template slot-scope="{row}" slot="ques_stem">
        <div class="rich-text" v-html="row.ques_stem"></div>
      </template>
      <template slot-scope="{row}" slot="action">
        <el-button type="text" @click="onDetail(row)">详情</el-button>
        <span class="action-split">|</span>
        <el-button type="text" @click="onCancelCollect(row)">取消收藏</el-button>
      </template>
      <div class="empty" slot="empty">
        <img src="~@/assets/images/empty-collect@3x.png" alt />
        <p>暂无收藏习题</p>
      </div>
      <div slot="footer-left">
        <el-button size="small" @click="onCancelCollect('select')">取消收藏</el-button>
      </div>
    </Grid>
  </div>
</template>

<script>
import Grid from "@/components/Grid";
import { ExamTopicType } from "@/utils/enums";
export default {
  name: "member-evaluation-collect",
  components: {
    Grid
  },
  data() {
    return {
      examTopicTypes: ExamTopicType.getOptions(),
      searchCondition: {
        type: 3,
        ques_type: "",
        bank: "",
        ques_stem: ""
      },
      tableColumns: [
        { label: "题目类型", prop: "quesTypeName", width: 100 },
        { label: "所属题库", prop: "bank", align: "center" },
        { label: "题干", slot: "ques_stem", align: "center" },
        { label: "操作", slot: "action", align: "center", width: 140 }
      ],
      selection: []
    };
  },
  methods: {
    // on event
    onSearch() {
      this.$refs.grid.searchRefresh();
    },
    onFormatter(data) {
      data.forEach(i => {
        i.quesTypeName = ExamTopicType.getValue(i.ques_type);
      });
    },
    onDetail(row) {
      this.$router.push({
        name: "MemberEvaluationDetail",
        query: {
          ques_id: row.ques_id
        }
      });
    },
    onCancelCollect(row) {
      if (row === "select") {
        if (!this.selection.length) {
          this.$message.warning("请先选择题目");
          return;
        }
        const ary = [];
        this.selection.forEach(i => {
          ary.push(i.collect_id);
        });
        this.$confirm("确定要取消收藏这些习题吗？", "提示")
          .then(() => {
            this.$http
              .put(`/api/app/collect/cancel/${ary.join()}/3`)
              .then(() => {
                this.$refs.grid.refresh();
                this.$message.success("取消收藏成功");
              });
          })
          .catch(() => {});
      } else {
        this.$confirm("确定要取消收藏该题吗？", "提示")
          .then(() => {
            this.$http
              .put(`/api/app/collect/cancel/${row.collect_id}/3`)
              .then(() => {
                this.$refs.grid.refresh();
                this.$message.success("取消收藏成功");
              });
          })
          .catch(() => {});
      }
    },
    onSelectionChange(val) {
      this.selection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.member-evaluation-collect {
  padding: 20px 20px 50px 20px;
  margin-bottom: 50px;
  /deep/ .el-input-group__append {
    background-color: $primary_color;
    border: 1px solid $primary_color;
    border-left: 0;
    .el-icon-search {
      font-size: 20px;
      color: #fff;
    }
  }
  .action-split {
    margin: 0 5px;
  }
}
</style>