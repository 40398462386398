<template>
  <div class="member-integral-survey">
    <div class="info">
      <div class="list" v-for="(inte, index) in intlist" :key="index">
        <img :src="inte.imgsrc" />
        <div>
          <p>
            {{ inte.title }}
            <el-tooltip
              v-if="index == 1"
              class="item"
              effect="dark"
              :content="`已消耗积分为在平台内兑换商品消耗的${$store.state.commonSetting.point_info.point_name}`"
              placement="bottom-start"
            >
              <i class="notice"></i>
            </el-tooltip>
          </p>
          <p>{{ inte.change_price }}</p>
        </div>
      </div>
      <div class="sign" :class="{ point: signInfo.is_sign != 1 }" @click="onSign">
        <img :src="signInfo.is_sign == 1 ? sign_img.active : sign_img.default" alt />
        <p class="success-text" v-if="signInfo.is_sign == 1">签到成功</p>
        <p v-else>点击签到</p>
      </div>
    </div>
    <div class="course">
      <div class="title" @click="changeTab('store')">
        <p>好课推荐</p>
        <p>更多</p>
      </div>
      <IntegralGoodsList type="1" :list="courses"></IntegralGoodsList>
    </div>
    <div class="book">
      <div class="title" @click="changeTab('store')">
        <p>热门图书</p>
        <p>更多</p>
      </div>
      <IntegralGoodsList type="2" :list="books"></IntegralGoodsList>
    </div>
  </div>
</template>

<script>
import IntegralGoodsList from "../../../components/Integral/IntegralGoodsList";
export default {
  name: "MemberIntegralSurvey",
  components: {
    IntegralGoodsList
  },
  computed: {
    intlist() {
      return [
        {
          title: `当前${this.$store.state.commonSetting.point_info.point_name ||
            ""}`,
          change_price: this.info.current,
          imgsrc: require("@/assets/images/integral/current-integral.png")
        },
        {
          title: `已消耗${this.$store.state.commonSetting.point_info
            .point_name || ""}`,
          change_price: this.info.total - this.info.current,
          imgsrc: require("@/assets/images/integral/used-integral.png")
        },
        {
          title: `本月${this.$store.state.commonSetting.point_info.point_name ||
            ""}`,
          change_price: this.info.monthly,
          imgsrc: require("@/assets/images/integral/month-integral.png")
        }
      ];
    }
  },
  data() {
    return {
      courses: [],
      books: [],
      sign_img: {
        default: require("@/assets/images/integral/sign-default.png"),
        active: require("@/assets/images/integral/sign-active.png")
      },
      signInfo: {},
      info: {
        current: 0,
        monthly: 0,
        total: 0
      }
    };
  },
  methods: {
    async getList(type) {
      let data = await this.$http.get("/api/integral/shop/index", { type });
      if (type == 1) {
        if (data.list.length > 3) {
          data.list = data.list.slice(0, 3);
        }
        this.courses = data.list;
      } else {
        if (data.list.length > 4) {
          data.list = data.list.slice(0, 4);
        }
        this.books = data.list;
      }
    },
    async getInfo() {
      this.info = await this.$http.get("/api/app/user/integral");
    },
    // 签到
    async getSign() {
      this.signInfo = await this.$http.get("/api/app/user/integral/isSign");
    },
    onSign() {
      if (this.signInfo.is_sign == 1) {
        return;
      }
      this.$http.post("/api/app/user/integral/sign").then(() => {
        this.$success("签到成功");
        this.getSign();
        this.getInfo();
      });
    },
    changeTab(val) {
      this.$emit("changeTab", val);
    }
  },
  created() {
    this.getList(1);
    this.getList(2);
    this.getInfo();
    this.getSign();
  }
};
</script>

<style lang="scss" scoped>
.member-integral-survey {
  .info {
    display: flex;
    margin-top: 30px;
    .list {
      width: 221px;
      height: 108px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-right: 25px;
      display: flex;
      > img {
        width: 29px;
        height: 31px;
        margin: 28px 15px 0 53px;
      }
      &:nth-child(2) > img {
        width: 30px;
        height: 30px;
        margin-left: 38px;
      }
      &:nth-child(3) > img {
        width: 31px;
        height: 28px;
        margin-left: 51px;
      }
      > div {
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(183, 183, 183, 1);
          line-height: 38px;
          &:nth-child(1) {
            margin-top: 14px;
            font-size: 16px;
          }
          &:nth-child(2) {
            font-size: 30px;
            color: #363837;
          }
          /deep/ .notice {
            background: url("~@/assets/images/integral/notice.png") no-repeat;
            width: 14px;
            height: 14px;
            background-size: 100% 100%;
            display: inline-block;
            vertical-align: -1px;
          }
        }
      }
    }
    .sign {
      width: 130px;
      height: 108px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 10px 0px rgba(14, 62, 129, 0.1);
      border-radius: 10px;
      &.point {
        cursor: pointer;
      }
      > img {
        width: 100%;
        height: 72px;
      }
      p {
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(89, 89, 89, 1);
      }
      .success-text {
        color: $primary-color;
      }
    }
  }
  .title {
    margin: 50px 0 20px;
    padding: 0 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 3px;
      height: 18px;
      background: #4169ff;
      margin-top: -9px;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 8px;
      height: 14px;
      margin-top: -7px;
      background: url("~@/assets/images/icon_fourm_right_arrow.png") no-repeat;
      background-size: 100% 100%;
    }
    p {
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 30px;
      &:nth-child(1) {
        font-weight: 500;
        font-size: 22px;
        color: rgba(32, 36, 42, 1);
      }
      &:nth-child(2) {
        font-weight: 400;
        font-size: 18px;
        color: #8c8c8c;
      }
    }
  }
}
</style>
