<template>
  <div class="oto-comm">
    <div class="base-info clear">
      <img :src="info.teacher_avatar" class="avatar f-left" />
      <div class="info">
        <div class="name">
          <label>讲师姓名：</label>
          <span>{{info.teacher_name}}</span>
        </div>
        <div class="clear time">
          <label class="f-left">上课时间：</label>
          <span class="f-left">{{info.course_time}}</span>
          <a class="f-right btn-commu" @click="beginCommunicate">发起沟通</a>
        </div>
      </div>
    </div>
    <div class="communicates">
      <el-checkbox v-model="onlyShowTeacher" @change="changeType" class="f-right show-teacher">只看老师</el-checkbox>
      <div v-for="item in communicates" :key="item.dateStr">
        <div class="f-left date-wrap">
          <div class="clear">
            <span class="date f-left">{{item.date.getDate()}}</span>
            <span class="month f-left">{{item.date.getMonth()+1}}月</span>
          </div>
          <div class="week">{{item.week}}</div>
          <div class="year">{{item.date.getFullYear()}}年</div>
        </div>
        <div class="list f-left">
          <div class="child" v-for="child in item.list" :key="child.id">
            <div class="line"></div>
            <div class="f-left time">{{child.communicate_at}}</div>
            <div class="f-left circle"></div>
            <div class="f-left main">
              <div
                class="name"
                :class="{'mine':child.belong==0}"
              >{{child.belong==0?'我':child.teacher_name}}：</div>
              <div class="clear">
                <div class="content f-left">{{child.content}}</div>
                <a
                  class="delete f-right"
                  v-if="child.belong==0"
                  @click="deleteCommunicate(child.id)"
                >删除</a>
              </div>
              <div class="file" v-for="file in child.files" :key="file.url">
                <a :href="file.url" target="_blank">
                  <img src="@/assets/images/file@2x.png" style="width:12px;" />
                  <span>{{file.name}}</span>
                </a>
              </div>
              <div class="file" v-for="img in child.images" :key="img.url">
                <a :href="img.url" target="_blank">
                  <img src="@/assets/images/image@2x.png" style="width:12px;" />
                  <span>{{img.name}}</span>
                </a>
              </div>
            </div>
            <div style="clear:both;"></div>
          </div>
        </div>
        <div style="clear:both;"></div>
      </div>
    </div>
    <el-dialog class="dialog-communication" title="发起沟通" :visible.sync="showDialog" width="800px">
      <div>
        <el-input
          type="textarea"
          autosize
          placeholder="请输入要与老师沟通的问题，如需要老师待会上课讲的问题。最多500字。"
          v-model="form.content"
          :maxlength="500"
        ></el-input>
      </div>
      <div class="upload">
        <label>上传文件：</label>
        <div>
          <div class="upload-file">
            <el-upload
              ref="uploadFile"
              :action="uploadFileUrl"
              :on-success="uploadFileSuccess"
              :headers="headers"
            >
              <el-button size="small" type="default" icon="el-icon-plus">选择文件</el-button>
            </el-upload>
            <div class="clear">
              <div class="item f-left" v-for="(file,index) in form.fileList" :key="file.url">
                <a :href="file.url" target="_blank">
                  <img src="@/assets/images/file@2x.png" style="width:12px;" />
                  <span>{{file.name}}</span>
                </a>
                <i class="el-icon-error" @click.stop="form.fileList.splice(index,1)"></i>
              </div>
            </div>
          </div>
          <div class="upload-file" v-if="false">
            <el-upload
              ref="upload"
              :action="uploadUrl"
              :on-success="uploadSuccess"
              :headers="headers"
              accept="image/gif, image/jpeg, image/jpg, image/png"
              :before-upload="beforeUpload"
            >
              <el-button size="small" type="default" icon="el-icon-plus">选择图片</el-button>
            </el-upload>
            <div class="clear">
              <div class="item f-left" v-for="(file,index) in form.imageList" :key="file.url">
                <a :href="file.url" target="_blank">
                  <img src="@/assets/images/image@2x.png" style="width:12px;" />
                  <span>{{file.name}}</span>
                </a>
                <i class="el-icon-error" @click.stop="form.imageList.splice(index,1)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitCommucate">发布</el-button>
      </div>
    </el-dialog>
    <ImageViewer :images="images" :isShow.sync="isShowImageViewer"></ImageViewer>
  </div>
</template>
<script>
const buildConfig = require("@/build/" + process.env.BUILD_ENV + ".js");
import ImageViewer from "@/components/ImageViewer.vue";

export default {
  name: "MemberOtoCommunication",
  components: {
    ImageViewer
  },
  data() {
    return {
      id: "",
      user_id: "",
      teacher_id: "",
      onlyShowTeacher: false,
      info: {},
      communicates: [],
      showDialog: false,
      form: {
        content: "",
        fileList: [],
        imageList: []
      },
      uploadUrl: "",
      uploadFileUrl: "",
      headers: { Authorization: "Bearer " + localStorage.studentToken },
      weeks: {
        0: "星期日",
        1: "星期一",
        2: "星期二",
        3: "星期三",
        4: "星期四",
        5: "星期五",
        6: "星期六"
      },
      hasInit: false,
      images: [],
      isShowImageViewer: false
    };
  },
  computed: {
    user() {
      return this.$store.state.user_info;
    }
  },
  async created() {
    if (this.$route.query.from == 1) {
      this.$route.meta.bread = [
        {
          title: "待上课",
          route: { name: "MemberOto", query: { type: this.$route.query.from } }
        },
        { title: "沟通记录" }
      ];
    } else if (this.$route.query.from == 2) {
      this.$route.meta.bread = [
        {
          title: "已上课",
          route: { name: "MemberOto", query: { type: this.$route.query.from } }
        },
        { title: "沟通记录" }
      ];
    }
    this.uploadUrl = buildConfig.BASE_URL + "/api/app/public/img";
    this.uploadFileUrl = buildConfig.BASE_URL + "/api/app/public/file";
    this.id = this.$route.query.id;
    this.user_id = this.user.id;
    this.teacher_id = this.$route.query.teacher_id;
    await this.getData();
  },
  watch: {
    user: async function() {
      if (this.hasInit) {
        return;
      }
      this.user_id = this.user.id;
      await this.getData();
    }
  },
  methods: {
    async getData() {
      if (!this.user_id) {
        return;
      }
      this.hasInit = true;
      let params = {
        teacher_id: this.teacher_id,
        user_id: this.user_id,
        oto_course_id: this.id
      };
      if (this.onlyShowTeacher) {
        params.belong = 1;
      }
      let data = await this.$http.post(
        "/api/app/oto/communicate/index",
        params
      );
      this.info = data.base_info;
      let communicates = [];
      for (let key in data.communicate) {
        let date = new Date(key);
        let list = data.communicate[key];
        list.forEach(c => {
          let files = [];
          if (c.file) {
            c.file.forEach(f => {
              for (let key in f) {
                files.push({ url: key, name: f[key] });
              }
            });
          }
          c.files = files;

          let images = [];
          if (c.img_url) {
            c.img_url.forEach(f => {
              for (let key in f) {
                images.push({ url: key, name: f[key] });
              }
            });
          }
          c.images = images;
        });
        communicates.push({
          date: date,
          dateStr: date.format("yyyy-MM-dd"),
          week: this.weeks[date.getDay()],
          list: list
        });
      }
      this.communicates = communicates;
    },
    beginCommunicate() {
      this.showDialog = true;
      this.form.content = "";
      this.form.fileList = [];
    },
    async submitCommucate() {
      if (this.form.content == "") {
        await this.$info("请输入要与老师沟通的问题");
        return;
      }
      let data = {
        oto_course_id: this.id,
        teacher_id: this.teacher_id,
        user_id: this.user_id,
        content: this.form.content
      };

      let images = [];
      this.form.imageList.forEach(file => {
        let f = {};
        f[file.url] = file.name;
        images.push(f);
      });
      data.img_url = JSON.stringify(images);

      let files = [];
      this.form.fileList.forEach(file => {
        let f = {};
        f[file.url] = file.name;
        files.push(f);
      });
      data.file = JSON.stringify(files);

      await this.$http.post("/api/app/oto/communicate/send", data);
      await this.$success("发布成功");
      this.showDialog = false;
      await this.getData();
    },
    async uploadSuccess(response) {
      this.$refs.upload.clearFiles();
      if (response.code != 200) {
        await this.$error(response.msg);
      } else {
        this.form.imageList.push({
          name: response.data.originalName,
          url: response.data.path
        });
      }
    },
    async uploadFileSuccess(response) {
      this.$refs.uploadFile.clearFiles();
      if (response.code != 200) {
        await this.$error(response.msg);
      } else {
        this.form.fileList.push({
          name: response.data.originalName,
          url: response.data.path
        });
      }
    },
    async changeType() {
      await this.getData();
    },
    async deleteCommunicate(id) {
      await this.$confirm("删除后老师会看不到你的问题，确定删除么？", "提示", {
        type: "warning"
      });
      await this.$http.delete("/api/app/oto/communicate/" + id);
      await this.$success("删除成功");
      await this.getData();
    },
    beforeUpload(file) {
      let type = file.type.toLowerCase();
      if (type.indexOf("image/") < 0) {
        this.$message.info("请上传图片");
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/member-oto.scss";
</style>
