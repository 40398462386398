<template>
  <div class="correction-dlg">
    <el-dialog title="纠错" :visible.sync="show" @close="onClose" width="570px" center>
      <div>
        <el-form :model="form" label-width="90px">
          <el-form-item label="纠错类型：">
            <el-radio-group v-model="form.type">
              <el-radio :label="1">错别字</el-radio>
              <el-radio :label="2">答案解析不匹配</el-radio>
              <el-radio :label="3">解析错误</el-radio>
              <el-radio :label="4">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="纠错内容：" prop="error_msg">
            <el-input
              v-model="form.error_msg"
              type="textarea"
              :rows="6"
              placeholder="请输入题目纠错信息，最多不超过500个字"
              :maxLength="500"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="show = false" size="medium" style="width:100px;">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          style="width:100px;"
          @click="onOkCorrectionModal"
        >提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "correction-dlg",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    quesID: {
      required: true
    }
  },
  data() {
    return {
      show: false,
      form: {
        ques_id: 0,
        type: 1,
        error_msg: "",
        init() {
          this.ques_id = 0;
          this.type = 1;
          this.error_msg = "";
        }
      }
    };
  },
  created() {
    this.show = this.value;
  },
  watch: {
    value() {
      this.show = this.value;
      if (this.value) {
        this.form.init();
      }
    }
  },
  methods: {
    // on event
    onClose() {
      this.$emit("input", this.show);
    },
    onOkCorrectionModal() {
      this.form.ques_id = this.quesID;
      this.$http.post("/api/app/myExam/correction", this.form).then(() => {
        this.show = false;
        this.$message.success("纠错提交成功");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>