<template>
  <div>
    <ul class="clear tabs">
      <li
        class="tab-item"
        :class="{'tab-item-current':current==item.type}"
        @click="changeTab(item.type)"
        v-for="item in types"
        :key="item.type"
      >
        <span>{{item.label}}</span>
        <div class="tab-line"></div>
      </li>
    </ul>
    <div class="profile" v-show="current=='1'">
      <el-form :model="userInfo" :rules="rules" ref="userForm" label-width="100px">
        <el-form-item label="当前头像" prop="avatar">
          <img class="avatar" :src="userInfo.avatar" />
          <el-upload
            :action="uploadUrl"
            :show-file-list="false"
            list-type="picture"
            :on-success="uploadSuccess"
            :headers="headers"
            accept="image/gif, image/jpeg, image/jpg, image/png"
            style="display:inline-block"
          >
            <a class>修改</a>
          </el-upload>
        </el-form-item>
        <el-form-item label="姓名" prop="nickname">
          <span>{{userInfo.nickname}}</span>
          <!-- <el-input v-model="userInfo.nickname" style="width:220px;"></el-input> -->
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <span>{{userInfo.mobile}}</span>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="userInfo.sex">
            <el-radio label="0">男</el-radio>
            <el-radio label="1">女</el-radio>
            <el-radio label="3">保密</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker
            v-model="userInfo.birthday"
            type="date"
            placeholder="选择出生日期"
            :picker-options="options"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所在城市" prop="district_id">
          <AreaSelect
            :provinceId="userInfo.province_id"
            :cityId="userInfo.city_id"
            :districtId="userInfo.district_id"
            @change="areaChange"
          ></AreaSelect>
        </el-form-item>
        <el-form-item label="QQ">
            <el-input v-model="userInfo.qq" style="width:220px;" placeholder="请输入qq号"></el-input>
        </el-form-item>
        <el-form-item v-for="attr in attrs" :label="attr.attr" :key="attr.id" :required="attr.is_required == 1">
          <el-select v-model="userInfo.attr[attr.id]" v-if="attr.type==1">
            <el-option
              v-for="item in attr.value"
              :value="item.id"
              :key="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-checkbox-group v-model="userInfo.attr[attr.id]" v-if="attr.type==2">
            <el-checkbox v-for="item in attr.value" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item style="text-align:center;">
          <el-button type="primary" @click="saveUser">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="profile" v-show="current=='2'">
      <el-form label-width="100px">
        <el-form-item label="密码设置">
          <el-input value="******" readonly style="width:220px;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="showPasswordDialog=true">修改密码</el-button>
        </el-form-item>
      </el-form>
      <el-dialog title="修改密码" :center="true" :visible.sync="showPasswordDialog" width="515px">
        <el-form
          :model="passwordForm"
          :rules="passwordRules"
          ref="passwordForm"
          label-width="100px"
        >
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="passwordForm.mobile" placeholder="请输入手机号">
              <template slot="append">
                <a @click="getSmsCode" v-if="countDown==0" style="cursor:default;">获取验证码</a>
                <span v-else>{{countDown}}秒后重新发送</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="sms_code">
            <el-input v-model="passwordForm.sms_code" placeholder="请输入验证码"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input v-model="passwordForm.password" placeholder="请输入新密码" type="password"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitChangePassword">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import AreaSelect from "@/components/AreaSelect.vue";
const buildConfig = require("@/build/" + process.env.BUILD_ENV + ".js");

export default {
  name: "MemberProfile",
  components: {
    AreaSelect
  },
  data() {
    return {
      current: "1",
      types: [
        {
          type: "1",
          label: "个人信息"
        },
        {
          type: "2",
          label: "账户安全"
        }
      ],
      attrs: [],
      userInfo: {
        avatar: "",
        nickname: "",
        mobile: "",
        sex: "",
        birthday: "",
        province_id: "",
        city_id: "",
        district_id: "",
        qq:'',
        attr: {}
      },
      rules: {
        avatar: [{ required: true, message: "头像不能为空", trigger: "blur" }],
        nickname: [
          { required: true, message: "昵称不能为空", trigger: "blur" }
        ],
        sex: [{ required: true, message: "性别不能为空", trigger: "change" }],
        birthday: [
          { required: true, message: "出生日期不能为空", trigger: "change" }
        ],
        district_id: [
          { required: true, message: "所在城市不能为空", trigger: "change" }
        ]
      },
      showPasswordDialog: false,
      passwordForm: {
        mobile: "",
        password: "",
        sms_code: ""
      },
      passwordRules: {
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "新密码不能为空", trigger: "blur" }
        ],
        sms_code: [
          { required: true, message: "验证码不能为空", trigger: "blur" }
        ]
      },
      countDown: 0,
      uploadUrl: buildConfig.BASE_URL + "/api/app/public/img",
      headers: { Authorization: "Bearer " + localStorage.studentToken },
      options: {
        disabledDate: function(date) {
          return date > new Date();
        }
      }
    };
  },
  async created() {
    let data = await this.$http.get("/api/app/userInfo");
    this.userInfo.avatar = data.avatar;
    this.userInfo.nickname = data.nickname;
    this.userInfo.mobile = data.mobile;
    this.userInfo.qq = data.qq;
    if (data.sex != 0 && data.sex != 1) {
      data.sex = 3;
    }
    this.userInfo.sex = data.sex.toString();
    this.userInfo.birthday = data.birthday == 0 ? "" : data.birthday;
    this.userInfo.province_id = data.province_id || "";
    this.userInfo.city_id = data.city_id || "";
    this.userInfo.district_id = data.district_id || "";
    this.attrs = await this.$http.get("/api/app/attribute/1");
    this.attrs.forEach(a => {
      let attrValues = [];
      data.attr
        .filter(av => a.id == av.attr_id)
        .forEach(av => {
          attrValues.push(av.attr_val_id);
        });
      if (a.type == 1) {
        this.$set(this.userInfo.attr, a.id, attrValues[0] || "");
      } else if (a.type == 2) {
        this.$set(this.userInfo.attr, a.id, attrValues);
      }
    });
    this.passwordForm.mobile = data.mobile;
    this.$refs["userForm"].clearValidate();
  },
  methods: {
    changeTab(tab) {
      this.current = tab;
    },
    areaChange(area) {
      this.userInfo.province_id = area.provinceId;
      this.userInfo.city_id = area.cityId;
      this.userInfo.district_id = area.districtId;
    },
    saveUser() {
      this.$refs["userForm"].validate(async valid => {
        if (!valid) {
          return;
        }
        let data = {};
        data.nickname = this.userInfo.nickname;
        data.avatar = this.userInfo.avatar;
        data.sex = this.userInfo.sex;
        data.birthday = new Date(this.userInfo.birthday).format("yyyy-MM-dd");
        data.province_id = this.userInfo.province_id;
        data.city_id = this.userInfo.city_id;
        data.district_id = this.userInfo.district_id;
        data.qq = this.userInfo.qq;
        let attrVals = [];
        for (let key in this.userInfo.attr) {
          let val = this.userInfo.attr[key];
          if (val instanceof Array) {
            val.forEach(v => {
              attrVals.push({ attr_id: key, attr_val_id: v });
            });
          } else if (val != "") {
            attrVals.push({ attr_id: key, attr_val_id: val });
          }
        }
        data.user_attr = JSON.stringify(attrVals);
        await this.$http.put("/api/app/user", data);
        await this.$success("保存成功");
        this.$store.dispatch("get_user_info");
      });
    },
    async getSmsCode() {
      if (this.passwordForm.mobile == "") {
        await this.$info("请输入手机号");
        return;
      }
      await this.$http.post("/api/app/smsCode", {
        mobile: this.passwordForm.mobile,
        sms_type: "getPassword"
      });
      await this.$success("验证码已经发送，请注意查收");
      this.countDown = 60;
      let timer = setInterval(() => {
        this.countDown--;
        if (this.countDown == 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
    async submitChangePassword() {
      this.$refs["passwordForm"].validate(async valid => {
        if (!valid) {
          return;
        }
        await this.$http.post("/api/app/password", {
          mobile: this.passwordForm.mobile,
          password: this.passwordForm.password,
          sms_code: this.passwordForm.sms_code
        });
        this.showPasswordDialog = false;
        this.passwordForm.sms_code = "";
        this.passwordForm.password = "";
        await this.$success("密码修改成功");
      });
    },
    async uploadSuccess(response) {
      if (response.code != 200) {
        await this.$error(response.msg);
      } else {
        this.userInfo.avatar = response.data.path;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .tab-item span {
  border-right: none;
}

/deep/ .el-form-item__label {
  color: #b7b7b7;
}

.profile {
  padding: 32px;
  background-color: white;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.profile-item {
  font-size: 15px;
  color: rgba(183, 183, 183, 1);
  label {
    display: inline-block;
    width: 80px;
    text-align: right;
  }
}
</style>
