<template>
  <div>
    <!-- 列表 -->
    <div class="question-left f-left">
      <!-- 详情 -->
      <div class="question-detail">
        <div class="question-detail-top">
          <div class="question-right">
            <ExamQuestion v-for="ques in list"
                          :key="ques.id"
                          :ques="ques"
                          :answer="answer"
                          :readonly="true"
                          :hasAnalysis="true"></ExamQuestion>
          </div>
        </div>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
const buildConfig = require("../../../../build/" + process.env.BUILD_ENV + ".js");
import ExamQuestion from "../../components/ExamQuestion.vue";
import { ExamTopicType } from "@/utils/enums";

import PreviewImg from "../../../stu/components/PreviewImg"
export default {
  name: "QuestionExamDetail",
  components: { PreviewImg, ExamQuestion },
  data () {
    return {
      name: this.$route.query.name,
      type: 'jaskDetail',
      teachers: [],
      swiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        loop: true,
        observer: true,
        observeParents: true,
        slidesPerView: 6,
        slidesPerGroup: 1,
        spaceBetween: 16
      },
      url: buildConfig.BASE_URL + '/api/lector/public/img',
      info: {},
      answerList: [],
      page: 1,
      limit: 10,
      childLimit: 5,
      total: 0,
      prviewList: [],
      previewIndex: 0,
      isShowImageDialog: false,
      showPopover: false,
      options: { boundariesElement: '.images' },
      fileList: [],
      fileListEdit: [],
      headers: { Authorization: "Bearer " + localStorage.teacherToken },
      dialogImageUrl: '',
      dialogVisible: false,
      showEdit: false,
      tea_answer: {
        content: '',
        type: 3
      },
      answerNeeds: '',
      questionData: {
        content: '',
        images: ''
      },
      imgIndex: 1,
      chars: { 1: "A", 2: "B", 3: "C", 4: "D", 5: "E", 6: "F" },
      list: [{ id: 1 }],
      answer: {}
    };
  },
  async created () {
    this.type = this.$route.params.type;
    await this.getInfo();
    await this.getAnswer()
  },
  methods: {
    getInfo () {
      let params = { id: this.$route.query.id };
      this.$http.get("/api/app/ask/questionDetail", params).then(res => {
        let question = res;
        let index = 1;
        question.forEach(ques => {
          ques.id = ques.question_id;
          ques.index = index;
          ques.ques_type_name = ExamTopicType.getValue(ques.ques_type);
          if (typeof ques.ques_option === "string") {
            ques.ques_option = JSON.parse(ques.ques_option);
          }
          let answers = [];
          if (ques.user_answer) {
            let user_answer;
            if (typeof ques.user_answer === "string") {
              user_answer = JSON.parse(ques.user_answer);
            } else {
              user_answer = ques.user_answer;
            }
            user_answer.forEach(a => {
              if (ques.ques_type == 4) {
                if (a.answer) {
                  answers.push(a.id + ")" + this.chars[a.answer] + " ");
                }
              } else {
                if (a.id) {
                  answers.push(this.chars[a.id]);
                }
              }
            });
            ques.user_answer = answers.join("");
          }
          if (ques.ques_type == 1 || ques.ques_type == 3) {
            let id = ques.ques_option.filter(o => o.is_right == 1)[0].id;
            this.$set(this.answer, ques.ques_id, id);
            ques.answer = this.chars[id];
          } else if (ques.ques_type == 2) {
            let ids = [];
            let answers = [];
            ques.ques_option.filter(o => o.is_right == 1).forEach(o => {
              ids.push(o.id);
              answers.push(this.chars[o.id]);
            });
            this.$set(this.answer, ques.ques_id, ids);
            ques.answer = answers.join("");
          } else if (ques.ques_type == 4) {
            let answer = {};
            let answers = [];
            ques.ques_option.left.forEach(op => {
              answer[op.id] = op.is_right;
              answers.push(op.id + ")" + this.chars[op.is_right]);
            });
            this.$set(this.answer, ques.ques_id, answer);
            ques.answer = answers.join(" ");
          }
          index++;
        });
        this.list = question;
      });
    },
    getAnswer () {
      if (!this.$route.query.id) return;
      let params = {
        question_id: this.$route.query.id
      };
      this.$http.get("/api/app/ask/zaskList", params).then(data => {
        data.list.forEach(item => {
          item.showBtn = true;
          item.textarea = '';
        })
        this.answerList = data.list;
        this.total = data.total;
      });

    },
    mathJax(){
      let _this = this;
      if (this.MathJax.isMathjaxConfig) { // 判断是否初始配置，若无则配置。
        this.MathJax.initMathjaxConfig()
      }
      setTimeout(function(){
        _this.MathJax.MathQueue()// 传入组件id，让组件被MathJax渲染
      },1000)
    }
  },
  mounted() {
    this.mathJax();
  }
};
</script>

<style lang="scss" scoped>
@import "../../css/question.scss";
.question-left,.question-right {
    width: 100%!important;
    /deep/ {
        .answer-box {
            padding: 0;
        }
              .answer-right {
          width: 675px!important;
      }
    }
}
.answer-top-title {
  margin-bottom: 30px;
}

.images {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
  .el-icon-picture-outline {
    width: 24px;
  }
  .el-icon-picture-outline:before {
    color: rgb(166, 192, 102);
    width: 20px;
    height: 16px;
  }
  .reference {
    cursor: pointer;
  }
}
.answer_btn {
  text-align: right;
  margin-top: 30px;
  button {
    width: 108px;
  }
}
</style>